import React, { useContext, useDebugValue, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import emailValidator from "email-validator";

import { UserContext } from "../../context/UserContext";
import useMediaQueries from "../../services/media-queries";
import { addUser } from "../../api/user";
import Section from "../../components/Section";
import SocialButtons from "../../components/SocialButtons";

import "./styles.scss";

function App() {
  const [isMobileView] = useMediaQueries();
  const { user, updateUserData } = useContext(UserContext);
  const [form, setForm] = useState({ agreement: false });
  const [sent, setSent] = useState(false);

  function handleSignup() {
    updateUserData(form);
    addUser(form);
    setSent(true);
  }

  const canContinue =
    !!form?.fullName &&
    !!form?.organization &&
    !!form?.job &&
    !!form?.email &&
    emailValidator.validate(form.email);

  return (
    <>
      {sent ? (
        <Section className="thankyou-wrapper thankyou-certification">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", marginBottom: "3rem" }}
          >
            <Grid item xs={12} md={12} align="center">
              <Typography
                variant="h1"
                sx={{ marginTop: { xs: "0px", md: "0px" } }}
              >
                Thank you for your interest
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  maxWidth: {xs: "240px", md: "500px"},
                  margin: { xs: "140px 0px 40px", md: "260px auto 40px" },
                  fontSize: {xs: "18px", md: "24px"},
                  position: {xs: 'relative', md: 'static'},
                  left: {xs:'-20px', md: '0px'}
                }}
              >
                We have received your information and will update you on the
                course.
              </Typography>
              <Box
                sx={{
                  display: { md: "block" },
                  margin: { xs: "150px 0 0px", md: "0px" },
                }}
              >
                <Typography variant="h6" sx={{ display: { md: "block" } }}>
                  <p style={{ paddingTop: "20px" }}>Join our social channels</p>
                </Typography>
                <SocialButtons />
              </Box>
            </Grid>
          </Grid>
        </Section>
      ) : (
        <Grid
          container
          spacing={0}
          sx={{
            display: "flex",
            margin: {
              xs: "0px",
              md: "0 0 4rem",
            },
            padding: { xs: "40px 20px", md: "40px" },
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h1"
              align="center"
              sx={{ marginBottom: { xs: "20px", md: "50px" } }}
            >
              Register your interest
            </Typography>

            {!sent && (
              <Typography
                variant="subtitle1"
                align="center"
                sx={{ marginBottom: { xs: "30px", md: "30px" } }}
              >
                Tell us about yourself
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6}>
            <TextField
              onChange={(e) => setForm({ ...form, fullName: e.target.value })}
              id="outlined-required"
              label="Full name"
              sx={{ width: "100%" }}
            />
            <TextField
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              id="outlined-required"
              label="Email"
              type="email"
              error={!!form?.email && !emailValidator.validate(form.email)}
              sx={{ width: "100%", marginTop: "30px" }}
            />
            <TextField
              onChange={(e) =>
                setForm({ ...form, organization: e.target.value })
              }
              id="outlined-required"
              label="Company or organization"
              sx={{ width: "100%", marginTop: "30px" }}
            />
            <TextField
              onChange={(e) => setForm({ ...form, job: e.target.value })}
              id="outlined-required"
              label="Job title"
              sx={{ width: "100%", margin: "30px 0 40px" }}
            />
            <FormControlLabel
              onChange={(e) => setForm({ ...form, agreement: !form.agreement })}
              control={<Checkbox sx={{ marginRight: "20px" }} />}
              label="Sign up to recieve notication of update to data, certifications and articles."
            />
            <Button
              disabled={!canContinue}
              variant="contained"
              size="large"
              style={{ width: "100%", marginTop: "40px" }}
              onClick={() => handleSignup(form)}
            >
              Submit your interest
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default App;
