import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { TextField, Typography, Button } from "@mui/material";

import MainLayout from "../../components/MainLayout";
import { submitReset } from "../../api/auth";
import CardBackground from "../../components/CardBackground";

import "../SignIn/styles.scss";
import { Link } from "react-router-dom";

function SignIn({ signup = false }) {
  const [userData, setUserData] = useState({});
  const [isResetting, setIsResetting] = useState(false);
  const [variant, setVariant] = useState("initial");

  function doPasswordsMatch() {
    const { password, confirmPassword } = userData;

    if (password && confirmPassword) {
      if (password == confirmPassword) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  useEffect(() => {
    async function doReset() {
      const { error = null, data = null } = await submitReset(
        userData.password
      );
      if (error) {
        setVariant("error");
      } else {
        setVariant("success");
      }

      setIsResetting(false);
    }

    if (isResetting) {
      doReset();
    }
  });

  return (
    <MainLayout>
      <div className="center-container">
        <CardBackground />
        <Box className="centerBox">
          {variant === "success" && (
            <>
              <Typography
                variant="h1"
                align="center"
                sx={{ margin: "40px 0 60px" }}
              >
                {signup ? <>Set up successful</> : <>Reset Successful</>}
              </Typography>
              <Link to="/">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: "100%", marginTop: "30px" }}
                >
                  Go to homepage
                </Button>
              </Link>
            </>
          )}
          {variant === "error" && (
            <>
              <Typography
                variant="h1"
                align="center"
                sx={{ margin: "40px 0 60px" }}
              >
                {signup ? <>Set up failed</> : <>Reset Failed</>}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ margin: "40px 0 60px" }}
              >
                Something went wrong.
              </Typography>
              <Button
                variant="contained"
                size="large"
                sx={{ width: "100%", marginTop: "30px" }}
                onClick={() => setVariant("initial")}
              >
                Try again
              </Button>
            </>
          )}
          {variant === "initial" && (
            <Box>
              <Typography
                variant="h1"
                align="center"
                sx={{ margin: "40px 0 60px" }}
              >
                {signup ? <>Set up your password</> : <>Change your password</>}
              </Typography>
              <Box sx={{ margin: "20px 0", width: "100%" }}>
                <TextField
                  type="password"
                  label="Password"
                  error={userData?.password && !doPasswordsMatch()}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ margin: "20px 0", width: "100%" }}>
                <TextField
                  type="password"
                  label="Confirm Password"
                  error={userData?.confirmPassword && !doPasswordsMatch()}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      confirmPassword: e.target.value,
                    })
                  }
                />
              </Box>
              {!doPasswordsMatch() && (
                <Typography variant="body1">Passwords do not match</Typography>
              )}
              <Button
                disabled={
                  !userData?.password ||
                  !userData?.confirmPassword ||
                  !doPasswordsMatch()
                }
                variant="contained"
                size="large"
                sx={{ width: "100%", marginTop: "30px", marginBottom: "80px" }}
                onClick={async () => {
                  setIsResetting(true);
                }}
              >
                Submit
              </Button>
            </Box>
          )}
        </Box>
      </div>
    </MainLayout>
  );
}

export default SignIn;
