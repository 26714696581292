import * as React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import WithTabsLayout from "../../components/WithTabsLayout";
import Section from "../../components/Section";
import SlideUp from "../../components/SlideUp";

import useMediaQueries from "../../services/media-queries";

import ValidateImg1 from "./assets/validateImg-1.png";
import ValidateImg2 from "./assets/validateImg-2.png";

import { QA_behavioural, QA_data } from "./data";
import QA from "../../components/QA";

function App() {
  const [isMobileView] = useMediaQueries();

  return (
    <WithTabsLayout
      bgColor="#A3BA18"
      headerContent={{
        subtitle: "Reduce",
        title: (
          <>
            Calculating the impact <br /> you've made
          </>
        ),
        content:
          "The carbon method allows designers to quantify emissions, identify eco-opportunities and modify the environmental impact of their product/service.",
      }}
    >
      <Section className="understand-section">
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Calculating the CO2 difference
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="main-content">
          <Grid item xs={12} md={5}>
            <Typography variant="body2">
              <p style={{ marginTop: "0px" }}>
                The aim of this step is to study potential solutions which
                provides a superior experience while considering the impact to
                the environment. Leveraging design and testing outputs from the
                design stage we can determine the material usage differences in
                the proposed solutions. Using the carbon cards we can calculate
                how much carbon impact the new solutions have in the operation
                of the proposed service. If the carbon produced from operating
                the service is reduced, this is a great sign, however check you
                have met your OKRs from the prioritisation step. If you have not
                met your OKRs, consider design iterations on your proposed
                solution or leverage options in the carbon reduction library.
              </p>
            </Typography>
          </Grid>
          {!isMobileView && <Grid item md={1} />}
          <Grid item xs={12} md={6}>
            <SlideUp>
              <img src={ValidateImg1} />
            </SlideUp>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {!isMobileView && (
            <>
              <Grid item xs={5} align="center">
                <SlideUp>
                  <img
                    src={ValidateImg2}
                    style={{ maxWidth: { md: "440px" }, marginTop: "40px" }}
                  />
                </SlideUp>
              </Grid>
              <Grid item xs={1} />
            </>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Working with carbon inside a service
            </Typography>
            <Typography variant="body2" style={{ paddingTop: { md: "40px" } }}>
              <p>
                When it is impossible to reduce the amount of carbon inside a
                service due to factors outside of the design and engineering
                team’s control. We can leverage the carbon reduction library to
                consider other ways to reduce carbon. This could be by utilising
                green energy sources, planting trees or even outsourcing your
                Co2 impact to companies like ClimeWorks to physically extract
                the amount of Carbon your service produces to mitigate its
                impact. The carbon reduction can then be budgeted into service
                running and maintenance costings.
              </p>
            </Typography>
          </Grid>
          {isMobileView && (
            <Grid item xs={12}>
              <SlideUp>
                <img src={ValidateImg2} style={{ minWidth: "100%" }} />
              </SlideUp>
            </Grid>
          )}
        </Grid>
      </Section>
      <Section variant="dark">
        <QA behavioural={QA_behavioural} data={QA_data} />
      </Section>
    </WithTabsLayout>
  );
}

export default App;
