import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useMediaQueries from "../../../services/media-queries";
import { carbonFormat } from "../../../services/carbon";

import "../styles.scss";

const EmissionCard = ({ items, sectionTitle, sx, values }) => {
  const [isMobileView] = useMediaQueries();

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={6} lg={4} />
      <Grid item xs={12} md={5} lg={7}>
        {!isMobileView && (
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "22px" }}
            className="emission-box-title"
          >
            {sectionTitle}
          </Typography>
        )}
        <Grid container sx={sx} className="emission-box">
          {isMobileView && (
            <Typography
              variant="h2"
              sx={{ marginBottom: "0px", fontSize: "36px" }}
            >
              {sectionTitle}
            </Typography>
          )}
          {items.map((item, i) =>
            item.override ? (
              isMobileView ? (
                item.overrideMobile
              ) : (
                item.override
              )
            ) : (
              <Grid item xs={4}>
                <Grid xs={2} md={12}>
                  {item.image}
                </Grid>
                <Grid xs={6} md={12}>
                  {item.description}
                </Grid>
                <Grid xs={4} md={12}>
                  {isMobileView && (
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "34px",
                        textAlign: "center",
                        fontWeight: "500",
                      }}
                    >
                      {!isNaN(values[i]) ? (
                        carbonFormat(Math.round(values[i] * 100) / 100, 2)
                      ) : (
                        <CircularProgress thickness={1} />
                      )}
                    </Typography>
                  )}
                  {!isMobileView && (
                    <p className="emission-value">
                      {!isNaN(values[i]) ? (
                        carbonFormat(Math.round(values[i] * 100) / 100, 2)
                      ) : (
                        <CircularProgress thickness={1} />
                      )}
                    </p>
                  )}
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmissionCard;
