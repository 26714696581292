import React from "react";

import { Box } from "@mui/system";
import ErrorIcon from "@mui/icons-material/Error";

function ErroBox({ content }) {
  return (
    <Box
      sx={{
        padding: "15px 10px",
        background: "rgba(233, 48, 48, .1)",
        border: "1px solid #E93030",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        color: "#E93030",
        fontWeight: 500,
        textAlign: "left",
        marginBottom: "20px",
      }}
    >
      <ErrorIcon sx={{ fill: "#E93030", marginRight: "10px" }} />
      <span>{content}</span>
    </Box>
  );
}

export default ErroBox;
