import * as React from "react";

const SvgComponent = (props) => (
    <svg
      fill="none"
      width="100%"
      height="100%"
      viewBox="0 0 49 49"
      xmlns="http://www.w3.org/2000/svg"
      style={{ zIndex: 1000 }}
      {...props}
    >
      <g>
        <path
          fill="#383838"
          d="M15.567 14.19c-.187-.812-.999-1.298-1.802-1.12l-8.536 1.97c-1.944.448-2.375 3.025-.684 4.09l9.608 6.009c1.691 1.06 3.82-.457 3.372-2.403l-1.958-8.546Zm22.68-5.201-9.608 6.002c-1.696 1.056-1.267 3.636.68 4.087l8.533 1.975a1.494 1.494 0 0 0 1.794-1.122l1.964-8.536c.53-1.948-1.59-3.46-3.364-2.406ZM26.761 31.622l-6.2 6.203c-.573.572-.572 1.528 0 2.112l6.201 6.194c1.412 1.41 3.824.41 3.824-1.585V33.211c-.006-2-2.415-2.999-3.825-1.589Z"
        />
        <path
          fill="#383838"
          opacity={0.4}
          d="m36.045 10.412-3.847-6.16c-3.509-5.615-11.69-5.618-15.2-.005l-.691 1.104a2.988 2.988 0 0 0 .942 4.107 2.984 2.984 0 0 0 4.119-.937l.694-1.11c1.19-1.903 3.838-1.833 5.066 0l3.851 6.175 5.066-3.175Zm-23.398 25.48H9.659c-2.347 0-3.778-2.583-2.535-4.574l4.753-7.603-5.065-3.154-4.746 7.61c-3.721 5.97.572 13.706 7.607 13.706h2.977a2.99 2.99 0 0 0 2.986-2.993 2.987 2.987 0 0 0-2.99-2.992Zm34.48-7.722-1.586-2.545a2.983 2.983 0 0 0-4.111-.953 2.992 2.992 0 0 0-.95 4.115l1.585 2.535c1.247 1.992-.185 4.575-2.534 4.575H30.57v5.978h8.95c7.04.002 11.334-7.739 7.609-13.705Z"
        />
      </g>
    </svg>
);

export default SvgComponent;
