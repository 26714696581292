const navItems = [
  {
    name: 'Understand',
    link: '/understand'
  },
  {
    name: 'Evaluate',
    link: '/evaluate'
  },
  {
    name: 'Identify',
    link: '/identify'
  },
  {
    name: 'Prioritise',
    link: '/prioritise'
  },
  {
    name: 'Design',
    link: '/design'
  },
  {
    name: 'Validate',
    link: '/validate'
  },
]

export default navItems