import * as React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import MainLayout from "../components/MainLayout";
import Section from "../components/Section";

function Usage() {
  return (
    <MainLayout>
      <Section>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", marginBottom: "4rem" }}
        >
          <Grid item xs={12} md={5}>
          <Typography variant="h1" sx={{ margin: {md: "0 0 40px", xs: "40px 0 40px"} }}>
              Privacy policy
            </Typography>
            <Typography variant="h6">Last updated: 3 October 2022</Typography>
          </Grid>
          <Grid item  xs={12} md={7} sx={{ textAlign: "justify" }}>
            <Typography variant="body1">
              <p>
                Bros&Co Group Ltd, (“Bros&Co” or “we” in any form) is committed
                to protect the personal data and safeguard the privacy of our
                website visitors and contact persons for our customers,
                suppliers and other business partners. For more information
                about us, see Section 7.
              </p>{" "}
              <p>
                This Privacy Policy describes how Bros&Co, in its capacity as a
                data controller, processes personal data in accordance with the
                General Data Protection Regulation (EU Regulation 2016/679)
                (hereinafter referred to as the “GDPR”).
              </p>{" "}
              <p>
                We use cookies on our website. Insofar as those cookies are not
                strictly necessary for the provision of our website and
                services, we will ask you to consent to our use of cookies when
                you first visit our website.
              </p>
            </Typography>
            <Typography variant="h5">
              1. Categories of personal data, processing purposes and legal
              bases
            </Typography>
            <Typography variant="body1">
              <p>
                In this Section 1 we have set out the categories of personal
                data that we may process, the purposes for which we may process
                personal data, the legal bases of the processing and the
                retention period for the personal data.
              </p>
            </Typography>
            <Typography variant="h5">1.1 Ongoing business partners</Typography>
            <Typography variant="body1">
              <p>
                If you represent an organization that we do business with, we
                may process the following personal data in order to facilitate
                our business relationship with the organization which you
                represent, i.e. name and contact details such as e-mail address,
                full name, title, telephone number, which organization you
                represent as well as correspondence. We may process personal
                data included in your personal profile on our website which may
                include your name, address, telephone number, email address,
                title, which organization you represent as well as
                correspondence. The profile data may be processed for the
                purpose of enabling and monitoring your use of our website and
                services.
              </p>
              <p>
                We may also process correspondence data, i.e. information
                relating to our customer relationships, including information
                contained in or relating to any communication that you send to
                us. The source of the customer relationship data is you or your
                employer. The personal data may be processed for the purposes of
                managing our relationships with customers, communicating with
                customers, keeping records of those communications and promoting
                our products and services to customers. The legal basis for this
                processing is our legitimate interests, namely the proper
                management of our customer relationships.
              </p>
              <p>
                Our legal basis for processing of the personal data is our
                legitimate interests to conduct our business and the proper
                management of our customer relationships.
              </p>
              <p>
                We will retain the personal data for as long as we have a
                business relationship. After that we may retain your personal
                information for a maximum of 5 years, following the end of our
                business relationship.
              </p>
            </Typography>
            <Typography variant="h5">
              1.2 Potential business partners
            </Typography>
            <Typography variant="body1">
              <p>
                If you represent an organization that we consider to do business
                with, we process personal data in order to facilitate our
                potential business relationship with the organization which you
                represent, i.e. name and contact details such as [e-mail
                address, full name, title, telephone number, which organization
                you represent as well as correspondence]. Our legal basis for
                processing of the personal data is our legitimate interests to
                conduct our business.
              </p>
              <p>
                We may also process information contained in any enquiry you
                submit to us regarding goods and/or services for the purpose of
                offering, marketing and selling relevant goods and/or services
                to you based on your enquiry. Our legal basis for processing of
                the personal data is in this case legitimate interest or
                consent.
              </p>
              <p>
                We will retain the data for [a maximum of 5 years], following
                our latest contact. Where the contacts have led to a business
                relationship, the personal data will be processed as described
                in the previous section, Ongoing business partners.
              </p>
            </Typography>
            <Typography variant="h5">1.3 Customer support</Typography>
            <Typography variant="body1">
              <p>
                In order to provide customer support, we process personal data
                regarding organizational belonging, name, email address, phone
                number, location data, correspondence, matter number and
                information about the relevant enquiry. The correspondence data
                may be processed for the purposes of communicating with you and
                record-keeping. Our legal basis for processing personal data is
                our legitimate interests to conduct our business and to
                communicate with the person that has reached out to our customer
                support.
              </p>
              <p>
                We will retain the data for a maximum of 12 months following the
                termination of the relevant service agreement.
              </p>
            </Typography>
            <Typography variant="h5">
              1.4 Correspondence with other individuals
            </Typography>{" "}
            <Typography variant="body1">
              <p>
                Occasionally, we engage in correspondence with individuals in
                other matters than those accounted for above. This may be the
                case where individuals have general questions about our
                services. Such correspondence may include communication content
                and metadata associated with the communication, e.g. metadata
                associated with communication generated using the website
                contact forms. Our legal basis for processing personal data in
                this respect is our legitimate interests to conduct our
                business, including to respond to such queries.
              </p>
              <p>
                We will retain the data for a [maximum of 5 years], following
                the latest contact with you.
              </p>
            </Typography>
            <Typography variant="h5">
              1.5 Newsletters and mailing lists
            </Typography>{" "}
            <Typography variant="body1">
              <p>
                We process your name, contact information, organizational
                belonging and title and email address in order to provide you
                with marketing and product information in email notifications
                and/or newsletters. Our legal basis for doing so is consent or
                legitimate interests. Where the legal basis is consent, this can
                be withdrawn at any time by opting-out by following the
                instructions in the message.
              </p>
              <p>
                We will retain the data and process it for the purposes of
                delivering newsletters until you opt-out or withdraw your
                consent.
              </p>
            </Typography>
            <Typography variant="h5">1.6 Branding</Typography>{" "}
            <Typography variant="body1">
              <p>
                We publish material containing personal data to inform about our
                activities on our website or on social media. Our legal basis
                for doing so is our legitimate interests to inform about our
                business for branding purposes or consent.
              </p>
            </Typography>
            <Typography variant="h5">1.7 Press releases</Typography>
            <Typography variant="body1">
              <p>
                We publish material containing personal data to inform about our
                activities on our website or on social media. Our legal basis
                for doing so is our legitimate interests to inform about our
                business for branding purposes or consent.
              </p>
            </Typography>
            <Typography variant="h5">1.8 Social media</Typography>
            <Typography variant="body1">
              <p>
                We use LinkedIn and Twitter to market and inform about our
                company and our services and products. The legal basis is our
                legitimate interests to use such channels for marketing and
                information. The social media providers each inform about their
                privacy practices and we encourage you to inform yourself
                thereof.
              </p>
              <p>
                The data will be retained as long as it may be relevant to
                fulfil the purpose. In case of any questions, please refer to
                ‘Contact information’ in section 7.
              </p>
            </Typography>
            <Typography variant="h5">
              1.9 Handling data subjects rights
            </Typography>{" "}
            <Typography variant="body1">
              <p>
                We will process email address, name, correspondence and data
                about the request. Our legal basis for processing personal data
                is compliance with a legal obligation. The data will be retained
                as long as it may be relevant and for a maximum of three (5)
                years.
              </p>
            </Typography>
            <Typography variant="h5">1.10 Other processing purposes</Typography>{" "}
            <Typography variant="body1">
              <p>
                In addition to the specific purposes for which we may process
                your personal data set out in this Section 1, we may also
                process any of your personal data where such processing is
                necessary for compliance with a legal obligation to which we are
                subject, or in order to protect your vital interests or the
                vital interests of another natural person. Furthermore, we may
                process any of your personal data identified in this policy
                where necessary for the purposes of obtaining or maintaining
                insurance coverage, managing risks, or obtaining professional
                advice. The legal basis for this processing is our legitimate
                interests, namely the proper protection of our business against
                risks.
              </p>
              <p>
                We may also process any of your personal data identified in this
                policy where necessary for the establishment, exercise or
                defence of legal claims, whether in court proceedings or in an
                administrative or out-of-court procedure. The legal basis for
                this processing is our legitimate interests, namely the
                protection and assertion of our legal rights, your legal rights
                and the legal rights of others.
              </p>
              <p>
                We will retain personal data for these purposes in accordance
                with the second paragraph of Section 2. below.
              </p>
            </Typography>
            <Typography variant="h5">2. Retention</Typography>{" "}
            <Typography variant="body1">
              <p>
                Your personal data will be retained for the different purposes
                as specified for each section above. In addition to retention
                for the foregoing purposes, we may also retain your personal
                data in order to comply with applicable laws, such as
                bookkeeping laws, or if we need your personal data to establish,
                exercise or defend legal claims.
              </p>
              <p>
                In some cases it is not possible for us to specify in advance
                the periods for which your personal data will be retained. In
                such cases, the period of retention of all personal data
                categories will be determined based on the necessity of storing
                that data for longer than necessary.
              </p>
              <p>
                Where the data is only stored, it will be archived with
                appropriately limited access rights.
              </p>
            </Typography>
            <Typography variant="h5">
              3. Who do we share your personal data with
            </Typography>{" "}
            <Typography variant="body1">
              <p>
                Bros&Co may engage external service providers, who act as data
                processors of Bros&Co, to provide certain services to Bros&Co,
                such as website service providers, marketing service providers
                or IT support service providers. When providing such services,
                the external service providers may have access to and/or may
                process your personal data. We request those external service
                providers to implement and apply security safeguards to ensure
                the privacy and security of your personal data. We may also
                disclose your personal data to any member of our group of
                companies (this means our subsidiaries, our ultimate holding
                company and all its subsidiaries) insofar as reasonably
                necessary for the purposes, and the legal bases, set out in this
                policy. Your personal data may also be shared with our business
                partners for marketing purposes, to the extent you have provided
                your consent.
              </p>
              <p>
                In addition to the foregoing, Bros&Co may transfer – in
                compliance with applicable data protection law – personal data
                to law enforcement agencies, governmental authorities, legal
                counsels, insurers, external consultants, or business partners.
                In case of a corporate merger or acquisition, personal data may
                be transferred to the third parties involved in the merger or
                acquisition, including potential buyers, investors and
                professional advisers.
              </p>
            </Typography>
            <Typography variant="h5">
              4. International transfers of Personal Data
            </Typography>{" "}
            <Typography variant="body1">
              <p>
                Personal Data may be transferred to and processed by recipients
                which are located inside or outside the United Kingdom. Other
                recipients might be located in other countries which do not
                adduce an adequate level of protection from a UK data protection
                law perspective. Bros&Co will take all necessary measures to
                ensure that transfers out of the UK are adequately protected as
                required by applicable data protection law. With respect to
                transfers to countries not providing an adequate level of data
                protection, we base the transfer on appropriate safeguards, such
                as standard data protection clauses adopted by the UK
                Information Commissioner's Office or by a supervisory authority,
                approved code of conducts together with binding and enforceable
                commitments of the recipient, or approved certification
                mechanisms together with binding and enforceable commitments of
                the recipient.
              </p>
            </Typography>
            <Typography variant="h5">5. Data subjects rights</Typography>{" "}
            <Typography variant="body1">
              <p>
                You have a number of rights in connection with the processing of
                your personal data, subject to certain conditions set out in the
                GDPR and local data protection laws, including the right to:
              </p>
              <p>
                1. Request access to your personal data (“data subject access
                request”). This enables you to receive a copy of the personal
                data we hold about you and to check that we are lawfully
                processing it.
              </p>
              <p>
                2. Request the rectification of the personal data that we
                process about you. This enables you to have incomplete or
                inaccurate data we hold about you corrected.
              </p>
              <p>
                3. Request the deletion of your personal data. This enables you
                to ask us to delete or remove personal data where there is no
                overriding reason for us to retain it.
              </p>
              <p>
                {" "}
                4. Ask us to stop processing personal data where we are relying
                on a legitimate interest and there is something about your
                particular situation which makes you want to object to
                processing on this ground.
              </p>
              <p>
                5. Request the restriction of processing of your personal data.
                This enables you to ask us to suspend the processing of personal
                data about you, for example if you want us to establish its
                accuracy or the reason for processing it.
              </p>
              <p>
                6. Request the transfer of your personal data to another party.
                If you have given your consent for processing of your personal
                data and that consent has served as a legal basis for
                processing, you can withdraw this consent at any time with
                future effect by contacting us as stated in the ‘Contact’ page.
              </p>
              <p>
                To exercise your rights please contact us as stated below in
                Section 7.
              </p>
              <p>
                In case of complaints you may contact our Data Protection
                Officer at contact@brosand.co and you also have the right to
                lodge a complaint with the competent data protection supervisory
                authority in particular in the UK of your habitual residence,
                place of work or of an alleged infringement of the GDPR.
              </p>
            </Typography>
            <Typography variant="h5">
              6. Changes to the Privacy Policy
            </Typography>{" "}
            <Typography variant="body1">
              <p>
                Bros&Co reserves the right to change this Privacy Policy at any
                time. We will give you reasonable notice of any significant
                changes to the Privacy Policy, where appropriate. If so, we will
                notify you by a message or by email. You will also find the date
                of the latest change to the Privacy Policy on this website.
              </p>
            </Typography>
            <Typography variant="h5">7. Contact information</Typography>
            <Typography variant="body1">
              <p>
                This website is owned and operated by the company Bros&Co Group
                Ltd.
              </p>
              <p>
                We are registered in UK. If you have concerns or questions
                regarding this Privacy Policy, please contact us by email, using
                the email address contact@brosand.co
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Section>
    </MainLayout>
  );
}

export default Usage;
