import find from 'lodash/find';

const emissionsTable = [
  {
    id: "kmdriven",
    name: "Km driven",
    coefficient:  (2.5)*0.621371,
  },
  {
    id: "barrelsconsumed",
    name: "Barrels of oil consumed",
    coefficient: .002
  },
  {
    id: "kgcoalburned",
    name: "Kg of coal burned",
    coefficient: 0.498866
  },
  {
    id: "yearhomeelectricity",
    name: "Year of avg home electricity",
    coefficient: 0.0002
  },
  {
    id: "windturbinesyear",
    name: "Wind turbines running for a year",
    coefficient: 0.0003
  },
  {
    id: "tonneswasterecycled",
    name: "Tonnes of waste recycled",
    coefficient: 0.0003
  },
  {
    id: "lightsswitchedtoled",
    name: "Lights switched to LED",
    coefficient: 0.038
  },
  {
    id: "treegrowntenyears",
    name: "Tree seedlings grown for 10 years",
    coefficient: 0.017
  },
  {
    id: "acresofforestoneyear",
    name: "Acres of forest extraction in 1 year",
    coefficient: 0.001
  },
]

function getCoefficient(name) {
  return find(emissionsTable, {id: name}).coefficient
}

export default getCoefficient