import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.78 11.953c0 5.663-4.577 10.25-10.218 10.25S1.344 17.616 1.344 11.953 5.92 1.703 11.562 1.703 21.78 6.29 21.78 11.953Z"
      fill={isHover ? color : "#fff"}
      stroke="#04A12B"
      strokeWidth={1.5}
    />
    <path
      d="M9.31 9.65a.376.376 0 0 0-.45-.279l-2.13.492a.56.56 0 0 0-.17 1.02l2.397 1.5a.56.56 0 0 0 .842-.6L9.31 9.652Zm5.66-1.298-2.397 1.499a.56.56 0 0 0 .17 1.02l2.13.492a.373.373 0 0 0 .447-.28l.49-2.13c.132-.486-.397-.864-.84-.6Zm-2.866 5.65-1.547 1.547a.378.378 0 0 0 0 .527l1.548 1.546a.56.56 0 0 0 .954-.395v-2.83a.56.56 0 0 0-.955-.396Z"
      fill={isHover ? color : "#fff"}
    />
    <path
      opacity={0.4}
      d="m14.416 8.708-.96-1.537c-.876-1.402-2.917-1.403-3.794-.002l-.172.276a.746.746 0 0 0 1.263.791l.174-.277c.297-.475.957-.458 1.264 0l.96 1.541 1.265-.792Zm-5.84 6.36h-.745a.746.746 0 0 1-.633-1.142l1.186-1.898-1.264-.787-1.184 1.899c-.929 1.49.142 3.42 1.898 3.42h.743a.746.746 0 0 0 0-1.493Zm8.606-1.928-.396-.635a.745.745 0 1 0-1.263.789l.396.633a.746.746 0 0 1-.633 1.142H13.05v1.491h2.233c1.757 0 2.829-1.931 1.9-3.42Z"
      fill={isHover ? color : "#fff"}
    />
    <path
      d="M9.31 9.65a.376.376 0 0 0-.45-.279l-2.13.492a.56.56 0 0 0-.17 1.02l2.397 1.5a.56.56 0 0 0 .842-.6L9.31 9.652Zm5.66-1.298-2.397 1.499a.56.56 0 0 0 .17 1.02l2.13.492a.373.373 0 0 0 .447-.28l.49-2.13c.132-.486-.397-.864-.84-.6Zm-2.866 5.65-1.547 1.547a.378.378 0 0 0 0 .527l1.548 1.546a.56.56 0 0 0 .954-.395v-2.83a.56.56 0 0 0-.955-.396Z"
      fill={isHover ? "#fff" : color}
    />
    <path
      opacity={0.4}
      d="m14.416 8.708-.96-1.537c-.876-1.402-2.917-1.403-3.794-.002l-.172.276a.746.746 0 0 0 1.263.791l.174-.277c.297-.475.957-.458 1.264 0l.96 1.541 1.265-.792Zm-5.84 6.36h-.745a.746.746 0 0 1-.633-1.142l1.186-1.898-1.264-.787-1.184 1.899c-.929 1.49.142 3.42 1.898 3.42h.743a.746.746 0 0 0 0-1.493Zm8.606-1.928-.396-.635a.745.745 0 1 0-1.263.789l.396.633a.746.746 0 0 1-.633 1.142H13.05v1.491h2.233c1.757 0 2.829-1.931 1.9-3.42Z"
      fill={isHover ? "#fff" : color}
    />
  </svg>
);

export default SvgComponent;
