import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={33}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.334 1.323a1.313 1.313 0 0 0-1.881-.103c-2.12 1.847-4.07 3.797-6.02 5.815a160.713 160.713 0 0 0-5.61 6.191 143.873 143.873 0 0 0-7.628 9.92l-8.688-9.339c-.41-.444-1.094-.581-1.641-.24a1.342 1.342 0 0 0-.48 1.848s8.278 14.264 8.62 14.88c.343.58 1.095 1.504 2.634 1.504 1.505 0 2.326-.889 2.805-1.813.513-.957 5.097-9.44 8.073-13.921 1.47-2.258 3.078-4.447 4.686-6.602a115.764 115.764 0 0 1 5.096-6.26l.035-.033c.444-.582.444-1.334 0-1.847Z"
      fill="#718219"
    />
  </svg>
)

export default SvgComponent
