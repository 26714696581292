import * as React from "react";
import md5 from "js-md5";
import { useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { jsonToLocalStorage } from "../../services/sessionStorage";
import { passHash } from '../../const/auth'

function Download() {
  const [pass, setPass] = React.useState("");
  const navigate = useNavigate()

  return (
    <div>
      <div style={{ width: "560px", margin: "30% auto 0" }}>
        <TextField
          label="Password"
          type="password"
          sx={{ width: "100%", margin: "30px 0 30px" }}
          onChange={(e) => setPass(e.target.value)}
        />
        <Button
          variant="contained"
          size="large"
          style={{ width: "100%" }}
          onClick={(e, value) => {
            if (passHash === md5(pass)) {
              jsonToLocalStorage("password", pass);
              navigate('/')
            }
          }}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
}

export default Download;
