import React from "react";

import ReducersIcon from "../../icons/Reducers";
import DigitalIcon from "../../icons/Digital";
import MovementIcon from "../../icons/Movement";
import EnergyIcon from "../../icons/Energy";
import RawMaterialsIcon from "../../icons/RawMaterials";
import ProcessingIcon from "../../icons/Processing";
import EquipmentIcon from "../../icons/Equipment";

export default [
  {
    color: "#0284A3",
    icon: <DigitalIcon />,
    title: "Digital",
    link: "/cardexplorer/digital",
  },
  {
    color: "#03060F",
    icon: <EquipmentIcon />,
    title: "Equipment",
    link: "/cardexplorer/equipment",
  },
  {
    color: "#CC3B08",
    icon: <MovementIcon />,
    title: "Movement",
    link: "/cardexplorer/movement",
  },
  {
    color: "#6A4D3C",
    icon: <RawMaterialsIcon />,
    title: "Raw Materials",
    link: "/cardexplorer/raw-materials",
  },
  {
    color: "#E6BF44",
    icon: <EnergyIcon />,
    title: "Energy",
    link: "/cardexplorer/energy",
  },
  {
    color: "#184718",
    icon: <ReducersIcon />,
    title: "Reducers",
    link: "/cardexplorer/reducers",
  },
  {
    color: "#00517C",
    icon: <ProcessingIcon />,
    title: "Processing",
    link: "/cardexplorer/processing",
  },
];
