import * as React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Section from "../../components/Section";
import WithTabsLayout from "../../components/WithTabsLayout";
import SlideUp from "../../components/SlideUp";

import MeasureImg1 from "../../assets/Measure/understand-1.png";
import MeasureImg2 from "../../assets/Measure/understand-2.png";
import useMediaQueries from "../../services/media-queries";

import { QA_behavioural, QA_data } from "./data";
import QA from "../../components/QA";

import "./styles.scss";

function Understand() {
  const [isMobileView, isTabletOrLess] = useMediaQueries();

  return (
    <WithTabsLayout
      bgColor="#5A8BCB"
      headerContent={{
        subtitle: "Measure",
        title: "Empathising with users and the environment",
        content:
          "The carbon method allows designers to quantify emissions, identify eco-opportunities and modify the environmental impact of their product/service.",
      }}
    >
      <Section
        className="understand-section"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Capture the eco-sensitivity of your product or service
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="main-content">
          <Grid item xs={12} lg={5}>
            <Typography variant="body2" sx={{ maxWidth: { lg: "470px" } }}>
              <p style={{ marginTop: "0px" }}>
                The capturing of eco-sensitivity within a product or service is
                best obtained at the inception of a project. As designers begin
                to understand the problem space, and define which problems to
                tackle first, they have an opportunity to understand how the
                problems are currently being addressed and the impact of them.
                These can be quantified at the initial stages of research by
                asking the right questions.
              </p>
              <p>
                Empathising for users and their contextual environment to a
                product and service has been heavily documented and material is
                readily available on this subject from the design thinking
                framework. However for the purpose of the carbon measurement
                methodology we will assume you have had working knowledge of the
                design thinking framework.
              </p>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7}>
            <SlideUp>
              <img src={MeasureImg1} />
            </SlideUp>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {!isTabletOrLess && <Grid item md={7} />}
          <Grid item xs={12} lg={5}>
            <Typography
              variant={isMobileView ? "h4xs" : "h4"}
              sx={{ marginBottom: "20px", width: { lg: "420px" } }}
            >
              Taking a holistic approach to empathy
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {!isTabletOrLess && (
            <>
              <Grid item xs={12} lg={6}>
                <SlideUp>
                  <img src={MeasureImg2} />
                </SlideUp>
              </Grid>
              <Grid item xs={2} md={1} />
            </>
          )}
          <Grid item xs={12} lg={5}>
            <Typography variant="body2" sx={{ maxWidth: { lg: "480px" } }}>
              <p>
                To take a holistic approach to ‘empathy’ which falls outside of
                the remit of capturing user needs, wants and desires, but as
                well as the impact user needs are having on the environment, we
                need to expand the remit of the data we capture through user
                research and change how we collect insights.
              </p>
              <p>
                Ideally you are already collecting meaningful data about your
                products and services. If not, then this is an immediate first
                step, to implement measurement tools like Google Analytics to
                help you decide what to do next and track its effectiveness.
              </p>
              <p>
                However data alone will not give you all the information you
                need. Continuous engagement with users is crucial and to build a
                culture of continuous user feedback loops to ensure the team
                generate the empathy required to make a product that will works
                first time. Ensure user insights are shared across the
                organisation so everyone can improve their service offerings.
              </p>
            </Typography>
          </Grid>
          {isTabletOrLess && (
            <Grid item xs={12}>
              <SlideUp>
                <img src={MeasureImg2} />
              </SlideUp>
            </Grid>
          )}
        </Grid>
      </Section>
      <Section
        variant="dark"
        sx={{ padding: isMobileView ? "0px 30px" : "80px 100px 40px" }}
      >
        <QA behavioural={QA_behavioural} data={QA_data} />
      </Section>
    </WithTabsLayout>
  );
}

export default Understand;
