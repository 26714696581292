import * as React from "react"

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.46 11.178c0 5.663-4.577 10.25-10.218 10.25-5.642 0-10.219-4.587-10.219-10.25S5.6.928 11.242.928c5.64 0 10.218 4.587 10.218 10.25Z"
      fill={isHover ? color : "#fff"}
      stroke={color}
      strokeWidth={1.5}
    />
    <g clipPath="url(#a)">
      <path
        d="M14.254 12.171h-2.09a.69.69 0 0 0-.696.68v2.044c0 .374.314.68.697.68h2.089a.69.69 0 0 0 .696-.68v-2.043a.69.69 0 0 0-.696-.681Zm1.393-6.128h-3.482a.689.689 0 0 0-.697.68v3.405c0 .376.312.681.697.681h3.482a.689.689 0 0 0 .696-.68V6.723a.688.688 0 0 0-.696-.681Zm-6.26 6.356-.704.753V6.725a.689.689 0 0 0-.697-.682.689.689 0 0 0-.696.681v6.426l-.705-.751a.702.702 0 0 0-.514-.221.703.703 0 0 0-.47.179.672.672 0 0 0-.043.963l1.895 2.045a.729.729 0 0 0 1.027 0l1.896-2.045a.672.672 0 0 0-.043-.963c-.244-.254-.684-.235-.945.042Z"
        fill={isHover ? "#fff" : color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
        fill={isHover ? "#fff" : color}
          transform="translate(5.375 5.31)"
          d="M0 0h10.968v11H0z"
        />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
