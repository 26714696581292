import React, { createContext, useState, useEffect } from "react";
import find from 'lodash/find'
import orderBy from "lodash/orderBy";

import {
  jsonFromLocalStorage,
  jsonToLocalStorage,
} from "../services/sessionStorage";
import { getAllCountries } from "../api/country_energy";

export const CountryContext = createContext();

export const CountryContextProvider = ({ children }) => {
  const [countries, setCountries] = useState(
    jsonFromLocalStorage("countries") || null
  );
  const [selectedCountry, setSelectedCountry] = useState(
    jsonFromLocalStorage("country") || "United Kingdom"
  );

  const updateCountries = (updatedCountries) => setCountries(updatedCountries);
  const updateSelectedCountry = (updatedSelectedCountry) => {
    jsonToLocalStorage("country", updatedSelectedCountry);
    setSelectedCountry(updatedSelectedCountry);
  }
  const selectedCountryData = find(countries, {Country: selectedCountry})

  useEffect(() => {
    if (!countries) {
      getAllCountries().then((data) => {
        const orderedCountries = orderBy(data, ['Country'], 'asc')
        jsonToLocalStorage("countries", orderedCountries);
        updateCountries(orderedCountries);
      });
    }
  }, []);

  return (
    <CountryContext.Provider
      value={{
        countries,
        updateCountries,
        selectedCountry,
        updateSelectedCountry,
        selectedCountryData
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};
