import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1.32812"
      y="1.03809"
      width="23.5"
      height="23.5"
      rx="4.25"
      fill="white"
      stroke="#B2B2B5"
      stroke-width="1.5"
    />
  </svg>
);

export default SvgComponent;
