import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CarbonCardsImg from "./assets/download-kit-1.png";
import MethodBlueprintImg from "./assets/download-kit-2.png";
import OkrTemplates from "./assets/download-kit-3.png";

import { AuthContext } from "../../context/AuthContext";

import "./styles.scss";

function DownloadKit() {
  const { authData, handleSignOut } = useContext(AuthContext);

  return (
    <div className="center-container downloadKit">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1" align="center" className="downloadKit-title">
            Download the Carbon Kit
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Grid item xs={12} md={6} align="center">
            <Typography variant="body1" className="downloadKit-desc">
              Utilitse the Carbon cards and Method Blueprint for use in
              workshops, research and design activities.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            align="center"
            className="downloadKit-subtitle"
          >
            What’s included
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: { lg: "0 0 60px", md: "0 0 0" },
        }}
      >
        <Grid item xs={12} md={4} className="downloadKit-material">
          <img src={CarbonCardsImg} style={{ width: "115px" }} />
          <Typography variant="body1" align="center">
            Carbon Cards
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className="downloadKit-material">
          <img src={MethodBlueprintImg} style={{ width: "282px" }} />
          <Typography variant="body1" align="center">
            Method Blueprint
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className="downloadKit-material">
          <img src={OkrTemplates} style={{ width: "194px" }} />
          <Typography variant="body1" align="center">
            OKR templates and more...
          </Typography>
        </Grid>
        <Grid item xs={12} className="downloadKit-btn">
          {authData?.user ? (
            <>
              <Link to="/download">
                <Button variant="contained" size="large">
                  Download the&nbsp;<strong>Carbon Kit</strong>
                </Button>
              </Link>
            </>
          ) : (
            <Link to="/connect">
              <Button variant="contained" size="large">
                Book a demo
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DownloadKit;
