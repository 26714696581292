import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { v4 } from "uuid";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { getCurrentSection } from "../../services/navigation";

import RoundDesignIcon from "../../icons/RoundDesign";
import RoundDigitalIcon from "../../icons/RoundDigital";
import RoundEnergyIcon from "../../icons/RoundEnergy";
import RoundEquipmentIcon from "../../icons/RoundEquipment";
import RoundEvaluateIcon from "../../icons/RoundEvaluate";
import RoundIdentifyIcon from "../../icons/RoundIdentify";
import RoundMovementIcon from "../../icons/RoundMovement";
import RoundPrioritiseIcon from "../../icons/RoundPrioritise";
import RoundProcessingIcon from "../../icons/RoundProcessing";
import RoundRawMaterialsIcon from "../../icons/RoundRawMaterials";
import RoundReducersIcon from "../../icons/RoundReducers";
import RoundUnderstandIcon from "../../icons/RoundUnderstand";
import RoundValidateIcon from "../../icons/RoundValidate";

import CarbonCardsImg from "../../assets/Navigation/CarbonCards.png";
import TheZeroImg from "../../assets/Navigation/TheZero.png";
import IconLink from "../../components/IconLink";

import { AuthContext } from "../../context/AuthContext";

import BrosnCo from "./assets/BrosnCo.png";
import DrawerContent from "./Drawer";

import "./styles.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Navigation(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [highlighted, setHighlighted] = useState(getCurrentSection());
  const [submenuOpen, setSubmenuOpen] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = useState();
  const { authData, handleSignOut } = useContext(AuthContext);

  const handleDrawerToggle = (e) => {
    setMobileOpen(!mobileOpen);
  };

  const drawerWidth = 540;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { pathname } = useLocation();
  const desktopMenu = [
    {
      link: "/method",
      text: "Method",
      onMouseEnter: () => setSubmenuOpen("method"),
    },
    {
      link: "/cardlanding",
      text: "Cards",
      onMouseEnter: () => setSubmenuOpen("cards"),
    },
    {
      link: "/certification",
      text: "Training",
      onMouseEnter: () => setSubmenuOpen(""),
    },
    {
      link: authData?.user ? null : "/signin",
      text: authData?.user ? "Sign Out" : "Sign In",
      onClick: () => {
        handleSignOut();
        setSnackbarOpen(authData?.user);
      },
      onMouseEnter: () => setSubmenuOpen(""),
    },
  ];

  function handleClose() {
    setSnackbarOpen(false);
  }

  function signOutSnackBar() {
    return (
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          position: "fixed",
          top: "100px",
          "& .MuiButtonBase-root": {
            marginLeft: "20px",
          },
        }}
      >
        <Alert
          onClose={handleClose}
          severity="info"
          sx={{
            width: "100%",
            padding: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          You are now signed out
        </Alert>
      </Snackbar>
    );
  }

  return (
    <nav>
      <Box
        sx={{
          flexGrow: 1,
          height: "85px",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          background: "#f8f8f8",
        }}
      >
        <AppBar
          component="nav"
          color="transparent"
          sx={{ position: "relative" }}
        >
          {signOutSnackBar()}
          <Toolbar>
            <div
              className="center-container"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Link to="/">
                <Typography
                  variant="subtitle1"
                  sx={{ lineHeight: "14px", marginTop: "2px" }}
                >
                  Carbon Method&#8482;
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: "black",
                    fontSize: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                >
                  powered by <img src={BrosnCo} style={{ width: "50px" }} />
                </Typography>
              </Link>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <div className="navLinks">
                  {desktopMenu.map((item) => {
                    return (
                      <Link
                        key={v4()}
                        to={item?.link}
                        className={
                          item?.link === pathname ||
                          highlighted == item?.link?.replace("/", "") ||
                          (highlighted === "cards" &&
                            item?.link === "/cardlanding")
                            ? "selected textLink"
                            : "textLink"
                        }
                        onMouseEnter={() => item.onMouseEnter()}
                        onClick={
                          item?.onClick ? () => item?.onClick() : () => {}
                        }
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                  {authData?.user ? (
                    <Link to="/download">
                      <Button
                        variant="contained"
                        style={{ padding: "6px 25px" }}
                      >
                        Download
                      </Button>
                    </Link>
                  ) : (
                    <Link to="/connect">
                      <Button
                        variant="contained"
                        style={{ padding: "6px 25px" }}
                      >
                        Book a demo
                      </Button>
                    </Link>
                  )}
                </div>
              </Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 0, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
          <div
            className={`subLinks subLinksMethod ${
              submenuOpen === "method" ? "open" : "closed"
            }`}
            onMouseLeave={() => setSubmenuOpen("")}
          >
            <div className="center-container" style={{ padding: "0 100px" }}>
              <Grid container spacing="2">
                <Grid item xs={5} sx={{ display: "flex" }}>
                  <img src={TheZeroImg} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 0 0 50px",
                      width: "180px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        paddingTop: "25px",
                        fontSize: "22px",
                        lineHeight: "30px",
                      }}
                    >
                      About the
                      <br />
                      Carbon Method
                    </Typography>
                    <a href="/method">
                      <Button
                        variant="contained"
                        style={{
                          padding: "8px 25px",
                          lineHeight: "26px",
                        }}
                      >
                        See more
                      </Button>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={2} className="linkSublist">
                  <Typography
                    variant="subtitle1"
                    style={{
                      paddingTop: "15px",
                      fontSize: "24px",
                      lineHeight: "30px",
                      fontWeight: 500,
                    }}
                  >
                    Measure
                  </Typography>
                  <List>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundUnderstandIcon}
                        link="/understand"
                        text="Understand"
                      />
                    </ListItem>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundEvaluateIcon}
                        link="/evaluate"
                        text="Evaluate"
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={2} className="linkSublist">
                  <Typography
                    variant="subtitle1"
                    style={{
                      paddingTop: "15px",
                      fontSize: "24px",
                      lineHeight: "30px",
                      fontWeight: 500,
                    }}
                  >
                    Track
                  </Typography>
                  <List>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundIdentifyIcon}
                        link="/identify"
                        text="Identify"
                      />
                    </ListItem>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundPrioritiseIcon}
                        link="/prioritise"
                        text="Prioritise"
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={2} className="linkSublist">
                  <Typography
                    variant="subtitle1"
                    style={{
                      paddingTop: "15px",
                      fontSize: "24px",
                      lineHeight: "30px",
                      fontWeight: 500,
                    }}
                  >
                    Reduce
                  </Typography>
                  <List>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundDesignIcon}
                        link="/design"
                        text="Design"
                      />
                    </ListItem>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundValidateIcon}
                        link="/validate"
                        text="Validate"
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            className={`subLinks subLinksCards ${
              submenuOpen === "cards" ? "open" : "closed"
            }`}
            onMouseLeave={() => setSubmenuOpen("")}
          >
            <div className="center-container" style={{ padding: "0 100px" }}>
              <Grid container spacing="2" sx={{ alignItems: "start" }}>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={CarbonCardsImg} className="carbon-cards-cta-img" />
                  <div className="carbon-cards-cta-title">
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: "24px",
                        lineHeight: "30px",
                      }}
                    >
                      About the
                      <br />
                      Carbon Cards
                    </Typography>
                    <a href="/cardlanding">
                      <Button
                        variant="contained"
                        style={{
                          padding: "6px 25px",
                          lineHeight: "26px",
                          marginTop: "15px",
                        }}
                      >
                        See more
                      </Button>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={3} className="linkSublist">
                  <List>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundDigitalIcon}
                        link="/cardexplorer/digital"
                        text="Digital"
                      />
                    </ListItem>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundEquipmentIcon}
                        link="/cardexplorer/equipment"
                        text="Equipment"
                      />
                    </ListItem>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundMovementIcon}
                        link="/cardexplorer/movement"
                        text="Movement"
                      />
                    </ListItem>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundRawMaterialsIcon}
                        link="/cardexplorer/raw-materials"
                        text="Raw materials"
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={3} className="linkSublist">
                  <List>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundEnergyIcon}
                        link="/cardexplorer/energy"
                        text="Energy"
                      />
                    </ListItem>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundReducersIcon}
                        link="/cardexplorer/reducers"
                        text="Reducers"
                      />
                    </ListItem>
                    <ListItem>
                      <IconLink
                        linkIcon={RoundProcessingIcon}
                        link="/cardexplorer/processing"
                        text="Processing"
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </div>
          </div>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            anchor="right"
            sx={{
              display: { md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                maxWidth: "100%",
              },
            }}
          >
            <>
              <DrawerContent
                handleClose={handleDrawerToggle}
                authData={authData}
                handleSignOut={() => {
                  handleSignOut();
                  setSnackbarOpen(authData?.user);
                }}
              />
              {signOutSnackBar()}
            </>
          </Drawer>
        </Box>
      </Box>
    </nav>
  );
}

export default Navigation;
