export const links = [
  {
    platform: "twitter",
    link: "https://twitter.com/carbonmethod",
  },
  {
    platform: "linkedin",
    link: "https://www.linkedin.com/company/brosandco/",
  },
];

export default links;
