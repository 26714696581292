import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={22}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.75 46.27 1.922 24.989a1 1 0 0 1 0-1.285L19.75 2.472"
      stroke="#787878"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
)

export default SvgComponent
