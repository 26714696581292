import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="30"
    height="29"
    viewBox="0 0 30 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.8323 9.16769C12.8323 10.8544 11.4638 12.2229 9.77707 12.2229H3.66657C1.97919 12.2229 0.611328 10.8544 0.611328 9.16769V3.0572C0.611328 1.36981 1.97919 0.00195312 3.66657 0.00195312H9.77707C11.4638 0.00195312 12.8323 1.36981 12.8323 3.0572V9.16769ZM12.8323 25.4623C12.8323 27.1491 11.4638 28.5176 9.77707 28.5176H3.66657C1.97919 28.5176 0.611328 27.1491 0.611328 25.4623V19.3518C0.611328 17.6651 1.97919 16.2966 3.66657 16.2966H9.77707C11.4638 16.2966 12.8323 17.6651 12.8323 19.3518V25.4623ZM16.906 3.0572C16.906 1.36981 18.2745 0.00195312 19.9612 0.00195312H26.0717C27.7585 0.00195312 29.127 1.36981 29.127 3.0572V9.16769C29.127 10.8544 27.7585 12.2229 26.0717 12.2229H19.9612C18.2745 12.2229 16.906 10.8544 16.906 9.16769V3.0572ZM29.127 25.4623C29.127 27.1491 27.7585 28.5176 26.0717 28.5176H19.9612C18.2745 28.5176 16.906 27.1491 16.906 25.4623V19.3518C16.906 17.6651 18.2745 16.2966 19.9612 16.2966H26.0717C27.7585 16.2966 29.127 17.6651 29.127 19.3518V25.4623Z"
      fill="#2D2E32"
    />
  </svg>
);

export default SvgComponent;
