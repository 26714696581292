import find from "lodash/find";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import classnames from "classnames";

import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tabs from "@mui/material/Tabs";

import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import GridIcon from "../../assets/icons/Grid";
import InfoIcon from "../../assets/icons/Info";
import ListIcon from "../../assets/icons/List";
import CardCategories from "../../components/CardCategories";
import CardTile from "../../components/CardTile";
import DownloadKit from "../../components/DownloadKit";
import HeaderLarge from "../../components/HeaderLarge";
import MainLayout from "../../components/MainLayout";
import Section from "../../components/Section";
import EnergyMixDialog from "../../components/EnergyMixOverlay";

import { checkEnergyMix } from "../../const/cards";
import { CountryContext } from "../../context/CountryContext";
import Checked from "../../icons/Checked";
import ChevronDownImg from "../../icons/ChevronDown";
import UnChecked from "../../icons/UnChecked";
import { getCarbonValue } from "../../services/carbon";
import useMediaQueries from "../../services/media-queries";
import { slugify, unslugify } from "../../utils/slugify";

import { getCardsByLibrary } from "../../api/card";
import { specialRoundTo } from "../../utils/math";
import Pagination from "./Pagination";
import Paywall from "../../components/Paywall";
import { AuthContext } from "../../context/AuthContext";

import CardTable from "./Table";

import "./styles.scss";

const initialSortValue = {};

const PAGE_SIZE = 24;

function Cards() {
  let { library, page = 0 } = useParams();
  const { authData } = useContext(AuthContext);
  const [layout, setLayout] = useState("grid");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSortDialogOpen, setSortDialogOpen] = useState(false);
  const [isSortSectionOpen, setSortSectionOpen] = useState(false);
  const [isFilterSectionOpen, setFilterSectionOpen] = useState(false);
  const [isFilterDialogOpen, setFilterDialogOpen] = useState(false);
  const [isSearchSectionOpen, setSearchSectionOpen] = useState(false);
  const [sortValue, setSortValue] = useState(initialSortValue);
  const [filterValue, setFilterValue] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [isEnergyMixDialogOpen, setEnergyMixDialogOpen] = useState(false);

  const [isMobileView, isTabletOrLess, is1400OrLess] = useMediaQueries();
  // const [library, setLibrary] = useState("Digital");
  const [cardList, setCardList] = useState(null);
  const [filteredCardList, setFilteredCardList] = useState(null);
  library = library ? unslugify(library) : "Equipment";

  const {
    countries,
    selectedCountry,
    updateSelectedCountry,
    selectedCountryData,
  } = useContext(CountryContext);
  const countryData = find(countries, { Country: selectedCountry });

  const handleOpenSortSection = () => {
    if (!isSortSectionOpen) {
      setFilterSectionOpen(false);
      setSearchSectionOpen(false);
    }
    setSortSectionOpen(!isSortSectionOpen);
  };

  const handleOpenSortDialog = (event) => {
    setAnchorEl(event.currentTarget);
    setSortDialogOpen(true);
  };

  const handleSortButtonClick = (event) =>
    isMobileView ? handleOpenSortSection() : handleOpenSortDialog(event);

  const handleOpenFilterSection = () => {
    if (!isFilterSectionOpen) {
      setSortSectionOpen(false);
      setSearchSectionOpen(false);
    }
    setFilterSectionOpen(!isFilterSectionOpen);
  };

  const handleOpenFilterDialog = (event) => {
    setAnchorEl(event.currentTarget);
    setFilterDialogOpen(true);
  };

  const handleFilterButtonClick = (event) =>
    isMobileView ? handleOpenFilterSection() : handleOpenFilterDialog(event);

  const handleCloseSortDialog = () => setSortDialogOpen(false);

  const handleCloseFilterDialog = () => setFilterDialogOpen(false);

  const handleChangeSortValue = ({ target }) =>
    setSortValue({
      // ...sortValue,
      [target.name]: target.value,
    });

  const handleClickInfoIcon = () => setEnergyMixDialogOpen(true);

  useEffect(() => {
    const azSort = sortValue?.alphabetical
      ? sortValue.alphabetical === "A - Z"
        ? "asc"
        : "desc"
      : "";
    const kgco2Sort = sortValue?.kgCo2ePerHour
      ? sortValue?.kgCo2ePerHour === "High to low"
        ? "desc"
        : "asc"
      : "";

    let newSortedData = filteredCardList;

    if (azSort) {
      newSortedData = orderBy(
        filteredCardList,
        [(data) => data.Inventory.toLowerCase()],
        [azSort]
      );
    }

    if (kgco2Sort) {
      newSortedData = orderBy(
        newSortedData,
        [(data) => getCarbonValue(data, selectedCountryData)],
        [kgco2Sort]
      );
    }

    setFilteredCardList(newSortedData);
  }, [sortValue]);

  const handleChangeFilterValue = (selection) => {
    if (filterValue.includes(selection)) {
      setFilterValue(filterValue.filter((value) => value !== selection));
    } else {
      setFilterValue([...filterValue, selection]);
    }
  };

  useEffect(() => {
    const subcategories = uniqBy(cardList, "Subcategory").map(
      (item) => item.Subcategory
    );

    setSubcategories(subcategories);
    setFilterValue([]);
  }, [cardList]);

  useEffect(() => {
    let newFilteredData = cardList;
    if (filterValue.length !== subcategories.length && filterValue.length > 0) {
      newFilteredData = newFilteredData.filter(
        (item) => filterValue.indexOf(item.Subcategory) > -1
      );
    }

    setFilteredCardList(newFilteredData);
  }, [filterValue]);

  const handleSearchButtonClick = () => {
    if (!isSearchSectionOpen) {
      setFilterSectionOpen(false);
      setSortSectionOpen(false);
    }
    setSearchSectionOpen(!isSearchSectionOpen);
  };

  useEffect(() => {
    setCardList(null);
    getCardsByLibrary(library).then((data) => {
      if (data) {
        setCardList(data);
        setFilteredCardList(data);
      }
    });
  }, [library, selectedCountry]);

  function handleSearchQuery(e) {
    const searchQuery = e.target.value;
    if (cardList && searchQuery.length > 2) {
      const filteredData = cardList.filter((card) => {
        return (
          card.Inventory.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
        );
      });

      setFilteredCardList(filteredData);
    } else {
      setFilteredCardList(cardList);
    }
  }

  const hasEnergyMix = checkEnergyMix({ Library: library });

  function getPaginatedCards() {
    return filteredCardList.slice(
      parseInt(page) * PAGE_SIZE,
      (parseInt(page) + 1) * PAGE_SIZE
    );
  }

  return (
    <MainLayout>
      <Paywall />
      <HeaderLarge
        style={{ background: "transparent", paddingBottom: "75px" }}
        leftColumn={
          <>
            <Typography
              variant="h1"
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                marginBottom: "40px",
              }}
            >
              Card explorer
            </Typography>
            <Typography variant="subtitle1">
              <p style={{ marginBottom: "0px" }}>
                The carbon method allows designers to quantify emissions,
                identify eco-opportunities, and modify the environmental impact
                of their product or service.
              </p>
            </Typography>
          </>
        }
        rightColumn={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", md: "auto" },
            }}
          >
            <Link to="/cards">
              <Button
                variant="outlined"
                size="large"
                sx={{ marginTop: { xs: "20px", md: "40px" } }}
              >
                See our research method
              </Button>
            </Link>
          </Box>
        }
        leftColumnStyles={{
          paddingLeft: "0px",
        }}
        rightColumnStyles={{
          justifyContent: { xs: "center", md: "flex-end" },
        }}
      />
      {!isMobileView ? (
        <Box sx={{ margin: { xs: "0px 30px", md: "0 100px" } }}>
          <div className="center-container">
            <div container spacing={2} className="filtersWrapper">
              <Grid container>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    {hasEnergyMix === "MIX" && (
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Which country do you need data for?"
                            notched={false}
                            value={
                              hasEnergyMix ? selectedCountry : "Global Average"
                            }
                            disabled={!hasEnergyMix}
                            onChange={(e) => {
                              updateSelectedCountry(e.target.value);
                            }}
                            inputProps={{
                              MenuProps: {
                                style: { zIndex: 10000 },
                                disableScrollLock: true,
                              },
                            }}
                          >
                            {countries &&
                              countries.map((country) => (
                                <MenuItem value={country.Country}>
                                  {country.Country}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {hasEnergyMix === "GLOBAL_AVERAGE" && (
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                          <span style={{ fontSize: "18px" }}>
                            Global Average
                          </span>
                          <IconButton onClick={handleClickInfoIcon}>
                            <InfoIcon style={{ width: "30px" }} />
                          </IconButton>
                        </Box>
                      </Grid>
                    )}
                    {hasEnergyMix === "MIX" && (
                      <Grid item xs={6} className="filterParams">
                        <Box
                          display="flex"
                          alignItems="center"
                          className="energyMixBox"
                          onClick={handleClickInfoIcon}
                          sx={{
                            cursor: "point",
                          }}
                        >
                          <span>Energy mix</span>
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Box>
                        <span>
                          {countryData.Countrycode}:{" "}
                          {specialRoundTo(countryData.Carbonfootprint, 4)}{" "}
                          <span style={{ color: "#787878" }}>kgCo2e/kWh</span>
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="searchAllCardsInputContainer">
                      <TextField
                        className="searchAllCardsInput"
                        placeholder="Search all cards"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleSearchQuery}
                      />
                      <Button
                        onClick={() => setLayout("grid")}
                        className={classnames("layoutSwitch", {
                          active: layout == "grid",
                        })}
                        sx={{
                          paddingLeft: { xs: "10px", sm: "0px", lg: "10px" },
                          marginLeft: { xs: "10px", sm: "0px", lg: "10px" },
                        }}
                      >
                        <GridIcon />
                      </Button>
                      <Button
                        onClick={() => setLayout("list")}
                        className={classnames("layoutSwitch", {
                          active: layout == "list",
                        })}
                      >
                        <ListIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      ) : (
        <Box sx={{ margin: { xs: "0px 30px", md: "0 100px" } }}>
          <div className="center-container">
            {(hasEnergyMix === "GLOBAL_AVERAGE" || hasEnergyMix === "MIX") && (
              <div container spacing={2} className="filtersWrapper">
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="filterParams">
                      <div style={{ display: "flex" }}>
                        <Typography variant="subtitle1">Energy mix</Typography>
                        <IconButton onClick={handleClickInfoIcon} style={{paddingLeft: '10px'}}>
                          <InfoIcon className="infoXsIcon" />
                        </IconButton>
                      </div>
                      {hasEnergyMix === "GLOBAL_AVERAGE" && (
                        <Typography variant="subtitle1">
                          Global Average
                        </Typography>
                      )}
                    </Grid>
                    {hasEnergyMix === "MIX" && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Which country do you need data for?"
                              notched={false}
                              value={selectedCountry}
                              onChange={(e) => {
                                updateSelectedCountry(e.target.value);
                              }}
                              inputProps={{
                                MenuProps: {
                                  style: { zIndex: 10000 },
                                  disableScrollLock: true,
                                },
                              }}
                            >
                              {countries &&
                                countries.map((country) => (
                                  <MenuItem value={country.Country}>
                                    {country.Country}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <span>
                            {countryData.Countrycode}:{" "}
                            {specialRoundTo(countryData.Carbonfootprint, 4)}{" "}
                            kgCo2e/kWh
                          </span>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </Box>
      )}
      <Box sx={{ margin: { md: "0 100px" } }}>
        <div className="center-container">
          <Box className="cardSelector">
            {is1400OrLess ? (
              <Tabs
                aria-label="icon position tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {CardCategories.map((card) => (
                  <Paper
                    sx={{ background: card.color }}
                    className={classnames(`category-${card.title}`, {
                      active: card.title == library,
                    })}
                  >
                    <Link to={`/cardexplorer/${slugify(card.title)}`}>
                      <ListItemButton
                        sx={{ pl: 2 }}
                        disableRipple
                        disableTouchRipple
                      >
                        <div className="iconWrapper">
                          <ListItemIcon>{card.icon}</ListItemIcon>
                        </div>
                        <ListItemText primary={card.title} />
                      </ListItemButton>
                    </Link>
                    <div className="caret" style={{ background: card.color }} />
                  </Paper>
                ))}
              </Tabs>
            ) : (
              CardCategories.map((card) => (
                <Paper
                  sx={{ background: card.color }}
                  className={classnames(`category-${card.title}`, {
                    active: card.title == library,
                  })}
                >
                  <Link to={`/cardexplorer/${slugify(card.title)}`}>
                    <ListItemButton
                      sx={{ pl: 2 }}
                      disableRipple
                      disableTouchRipple
                    >
                      <div className="iconWrapper">
                        <ListItemIcon>{card.icon}</ListItemIcon>
                      </div>
                      <ListItemText primary={card.title} />
                    </ListItemButton>
                  </Link>
                  <div className="caret" style={{ background: card.color }} />
                </Paper>
              ))
            )}
          </Box>
        </div>
      </Box>
      <Box
        className="sortSelector"
        sx={{ margin: { xs: "0px 30px", md: "0px 100px" } }}
      >
        <div className="center-container">
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Typography variant="subtitle1">
                <b>Showing:</b>&nbsp;&nbsp;
              </Typography>
              {filterValue.length === 0 ||
              filterValue.lenght == subcategories.length ? (
                <Typography variant="body1">All reducers</Typography>
              ) : (
                filterValue.map((item) => (
                  <Chip
                    label={item}
                    onDelete={() => handleChangeFilterValue(item)}
                    sx={{
                      margin: "0 5px",
                      height: "36px",
                      background: "#2D2E32",
                      color: "#fff",
                    }}
                  />
                ))
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              sx={{ display: { xs: "none", md: "block" } }}
            />

            <Grid
              item
              xs={3}
              sm={2}
              md={5}
              sx={{
                display: "flex",
                justifyContent: { sx: "left", md: "right" },
              }}
            >
              {!isMobileView && (
                <>
                  <Button
                    onClick={(e) => handleSortButtonClick(e)}
                    className="selector-button"
                    endIcon={<ChevronDownImg />}
                    sx={{ marginRight: "20px" }}
                  >
                    {sortValue?.alphabetical}
                    {sortValue?.kgCo2ePerHour}
                    {!sortValue?.alphabetical &&
                      !sortValue?.kgCo2ePerHour &&
                      "Sort by"}
                  </Button>
                  <Popover
                    open={isSortDialogOpen && authData?.user}
                    anchorEl={anchorEl}
                    onClose={handleCloseSortDialog}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    className="selector-popup"
                  >
                    <p className="selector-title">Sort by</p>
                    <div>
                      <FormControl>
                        <FormLabel className="selector-subtitle">
                          Alphabetical
                        </FormLabel>
                        <RadioGroup
                          value={
                            sortValue?.alphabetical
                              ? sortValue.alphabetical
                              : null
                          }
                          className="selector-radio-group"
                          name="alphabetical"
                          onChange={handleChangeSortValue}
                        >
                          <FormControlLabel
                            value="A - Z"
                            control={<Radio />}
                            label="A - Z"
                          />
                          <FormControlLabel
                            value="Z - A"
                            control={<Radio />}
                            label="Z - A"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl>
                        <FormLabel className="selector-subtitle">
                          kgCo2e per hour
                        </FormLabel>
                        <RadioGroup
                          value={
                            sortValue?.kgCo2ePerHour
                              ? sortValue.kgCo2ePerHour
                              : null
                          }
                          className="selector-radio-group"
                          name="kgCo2ePerHour"
                          onChange={handleChangeSortValue}
                        >
                          <FormControlLabel
                            value="Low to high"
                            control={<Radio />}
                            label="Low to high"
                          />
                          <FormControlLabel
                            value="High to low"
                            control={<Radio />}
                            label="High to low"
                          />
                        </RadioGroup>
                      </FormControl>{" "}
                    </div>
                    <Button
                      variant="contained"
                      size="large"
                      className="selector-approve-button"
                      onClick={() => setSortDialogOpen(false)}
                      sx={{ marginTop: "42px" }}
                    >
                      Done
                    </Button>
                  </Popover>
                </>
              )}
              <Button
                onClick={(e) => handleFilterButtonClick(e)}
                className="selector-button"
                endIcon={<ChevronDownImg />}
              >
                Filter {!isMobileView && "by"}
              </Button>
              <Popover
                open={isFilterDialogOpen  && authData?.user}
                anchorEl={anchorEl}
                onClose={handleCloseFilterDialog}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                className="selector-popup"
              >
                <p className="selector-title">Filter by</p>
                <div style={{ paddingTop: "20px" }} className="filter-selector">
                  <FormGroup className="filter-selector">
                    {subcategories.map((subcategory) => {
                      return (
                        <FormControlLabel
                          label={subcategory}
                          onChange={(e) =>
                            handleChangeFilterValue(e.target.value)
                          }
                          control={
                            <Checkbox
                              checked={filterValue.indexOf(subcategory) > -1}
                              icon={<UnChecked />}
                              checkedIcon={<Checked />}
                              value={subcategory}
                            />
                          }
                        />
                      );
                    })}
                  </FormGroup>
                </div>
                <Button
                  variant="contained"
                  size="large"
                  className="selector-approve-button"
                  onClick={handleCloseFilterDialog}
                  sx={{ marginTop: "32px" }}
                >
                  Done
                </Button>
              </Popover>
            </Grid>
            {isMobileView && (
              <Grid
                item
                xs={3}
                sm={2}
                sx={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  justifyContent: { sx: "left" },
                }}
              >
                <Button
                  onClick={(e) => handleSortButtonClick(e)}
                  className="selector-button"
                  endIcon={<ChevronDownImg />}
                >
                  {sortValue?.alphabetical}
                  {sortValue?.kgCo2ePerHour}
                  {!sortValue?.alphabetical &&
                    !sortValue?.kgCo2ePerHour &&
                    "Sort"}
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={6}
              sm={8}
              md={4}
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: { xs: "right" },
              }}
            >
              <Button
                startIcon={<SearchIcon />}
                className="search-button"
                onClick={handleSearchButtonClick}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
      {isMobileView && (
        <div className="slide-container">
          <Slide
            direction="up"
            in={isSortSectionOpen && authData?.user}
            mountOnEnter
            unmountOnExit
            appear
            timeout={0}
          >
            <Box>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4xs">Sort by</Typography>
                <div
                  onClick={() => setSortSectionOpen(false)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CloseIcon />
                </div>
              </div>
              <FormControl>
                <FormLabel className="selector-subtitle">
                  Alphabetical
                </FormLabel>
                <RadioGroup
                  value={
                    sortValue?.alphabetical ? sortValue.alphabetical : null
                  }
                  className="selector-radio-group"
                  name="alphabetical"
                  onChange={handleChangeSortValue}
                >
                  <FormControlLabel
                    value="A - Z"
                    control={<Radio />}
                    label="A - Z"
                  />
                  <FormControlLabel
                    value="Z - A"
                    control={<Radio />}
                    label="Z - A"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel className="selector-subtitle">
                  kgCo2e per hour
                </FormLabel>
                <RadioGroup
                  value={
                    sortValue?.kgCo2ePerHour ? sortValue.kgCo2ePerHour : null
                  }
                  className="selector-radio-group"
                  name="kgCo2ePerHour"
                  onChange={handleChangeSortValue}
                >
                  <FormControlLabel
                    value="Low to high"
                    control={<Radio />}
                    label="Low to high"
                  />
                  <FormControlLabel
                    value="High to low"
                    control={<Radio />}
                    label="High to low"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Slide>
          <Slide
            direction="up"
            in={isFilterSectionOpen && authData?.user}
            mountOnEnter
            unmountOnExit
            appear
            timeout={0}
          >
            <Box>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="h4xs">Filter by</Typography>
                </div>
                <div
                  onClick={() => setFilterSectionOpen(false)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CloseIcon />
                </div>
              </div>
              <ul className="xs-filter-list">
                {subcategories.map((subcategory) => {
                  return (
                    <li>
                      <FormControlLabel
                        label={subcategory}
                        onChange={(e) =>
                          handleChangeFilterValue(e.target.value)
                        }
                        control={
                          <Checkbox
                            checked={filterValue.indexOf(subcategory) > -1}
                            icon={<UnChecked />}
                            checkedIcon={<Checked />}
                            value={subcategory}
                          />
                        }
                      />
                    </li>
                  );
                })}
              </ul>
            </Box>
          </Slide>
          <Slide
            direction="up"
            in={isSearchSectionOpen  && authData?.user}
            mountOnEnter
            unmountOnExit
            appear
            timeout={0}
          >
            <Box>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography variant="h4xs">Search</Typography>
                </div>
                <div
                  onClick={() => setSearchSectionOpen(false)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CloseIcon />
                </div>
              </div>
              <div style={{ marginTop: "16px" }}>
                <TextField
                  className="searchAllCardsInput"
                  placeholder="Search all cards"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearchQuery}
                />
              </div>
            </Box>
          </Slide>
        </div>
      )}
      <Box sx={{ margin: { xs: "0 30px", md: "0 100px", minHeight: "20vh" } }}>
        <div className="center-container">
          {layout === "grid" &&
            (filteredCardList && selectedCountryData ? (
              <>
                <Box className="tileContainer">
                  {getPaginatedCards().map((card) => (
                    <CardTile
                      cardData={card}
                      selectedCountryData={selectedCountryData}
                    />
                  ))}
                </Box>
                <Pagination
                  filteredCardList={filteredCardList}
                  pageSize={PAGE_SIZE}
                />
              </>
            ) : (
              <LinearProgress style={{ marginTop: "20px" }} />
            ))}
          {layout === "list" && filteredCardList && (
            <Box sx={{ marginTop: "30px" }}>
              <CardTable
                filteredCardList={filteredCardList}
                selectedCountryData={selectedCountryData}
              />
            </Box>
          )}
        </div>
      </Box>
      <Section variant="dark">
        <DownloadKit />
      </Section>
      <EnergyMixDialog
        open={isEnergyMixDialogOpen}
        setEnergyMixDialogOpen={setEnergyMixDialogOpen}
      />
    </MainLayout>
  );
}

export default Cards;
