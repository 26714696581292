export const QA_behavioural = [
  {
    title:
      "What carbon generating materials or activities apply to your product and service?",
    content:
      "Use the carbon cards to determine which materials or activities apply to the operation of your service. Make sure to consider both the usage of your product (front stage) and the operation of the service (back stage).",
  },
  {
    title: "What mitigating factors apply to your product or service?",
    content:
      "Does your organisation already have carbon mitigating factors e.g. off-setting carbon production? If so, does it apply to your product or service and at which stage. Add this information to your service blueprint to capture a real-world view of your current state.",
  },
  {
    title: "What if you can’t find the right data?",
    content:
      "If you are unable to find the right data for the material or activity, you can reach out to Bros&Co (brosand.co) to support with data gaps. We also recommend conducting your own research to find data that supports your specific use case.",
  },
];

export const QA_data = [
  {
    title: "What materials are used and how frequently?",
    content: (
      <>
        Use the carbon cards to determine how many types of materials are used
        in the operation of your product or service. <br />
        <br />
        <strong>For example: </strong>
        <br />
        600 staff members operating the service in the back-stage use a computer
        for 8 hours a day = 4,800 hours of computer usage per day. 4,800 x 5
        working days = 24,000 hours of computer usage per week.
      </>
    ),
  },
  {
    title: "What behaviours are producing carbon impact and how frequently?",
    content: (
      <>
        Use the carbon cards to determine how many types of activities occur in
        the operation of your product or service.
        <br />
        <br />
        <strong>For example:</strong> <br />
        600 staff members travelling to and from a call centre per day by train
        at approximately 5 miles each way. 600 miles x 10 miles total travel per
        person = 6000 miles of train travel per day. 6000 miles x 46 working
        weeks (excluding holidays) = 276,000 miles per year.
      </>
    ),
  },
  {
    title: "Calculate the total impact",
    content:
      "You need to create two levels of totals. One total is for each card, where you will multiply the material or activity against the total number of units e.g. Delivering cargo x 1000 miles. The second calculation is all the cards added together to give you a total carbon impact of the usage of your product and operation of your service.",
  },
];
