import React, { useState } from "react";

import emailValidator from "email-validator";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import SocialButtons from "../../components/SocialButtons";
import MainLayout from "../../components/MainLayout";
import Section from "../../components/Section";

import { addUser } from "../../api/user";

// import "./styles.scss";

function Newsletter({ variant = "initial" }) {
  const [form, setForm] = useState({ newsletter: true });
  const [status, setStatus] = useState("initial");
  const [sent, setSent] = useState(false);

  function handleSignup() {
    addUser(form, (data, error) => {
      if (300 > data.status && data.status > 199) {
        setSent(true);
        setStatus("success");
        window.scrollTo(0, 0);
      } else {
        setSent(true);
        setStatus("error");
      }
    });
  }

  const canContinue =
    !!form?.email && emailValidator.validate(form.email) && !!form?.fullName;

  return (
    <MainLayout>
      {!sent && (
        <Section>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", marginBottom: "3rem" }}
          >
            <Grid item xs={12} md={5}>
              <Typography
                variant="h1"
                sx={{ margin: { xs: "40px 0 0", md: "80px 0 40px" } }}
              >
                Join our
                <br />
                mailing list
              </Typography>
              <Typography variant="body1">
                <p>
                  Keep in touch via our quarterly newsletter packed with carbon
                  data information, comparisons and sustainable design news.
                </p>
                <p>
                  Alternatively you can email:{" "}
                  <a href="mailto:contact@brosand.co">contact@brosand.co</a>
                </p>
              </Typography>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="h6" sx={{ marginTop: "100px" }}>
                  Join our social channels
                </Typography>
                <SocialButtons />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: { xs: "none", md: "block" } }}
            ></Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: "40px",
                  boxShadow: "0px 0px 15px 5px rgba(36, 41, 45, 0.4)",
                  background: "#F8F8F8",
                  marginTop: { xs: "30px", md: "150px" },
                }}
              >
                <TextField
                  onChange={(e) =>
                    setForm({ ...form, fullName: e.target.value })
                  }
                  id="outlined-required"
                  label="Full name"
                  sx={{ width: "100%", marginTop: { xs: "0px", md: "0px" } }}
                />
                <TextField
                  error={!!form?.email && !emailValidator.validate(form.email)}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  id="outlined-required"
                  label="Email"
                  sx={{ width: "100%", marginTop: "30px" }}
                />
                <Button
                  disabled={!canContinue}
                  onClick={() => handleSignup()}
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    padding: "2px 35px",
                    width: "100%",
                    margin: "40px 0 0",
                  }}
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Section>
      )}
      {sent && status === "error" && (
        <Section className="thankyou-wrapper">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", marginBottom: "3rem" }}
          >
            <Grid item xs={12} md={12} align="center">
              <Typography
                variant="h1"
                sx={{ marginTop: { xs: "40px", md: "0px" } }}
              >
                Error
              </Typography>
              <Typography variant="body1" sx={{ marginTop: "200px" }}>
                <p>Something went wrong. Please go back and try again.</p>
              </Typography>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Typography
                  variant="body1"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <p style={{ paddingTop: "20px" }}>
                    Or email us at:{" "}
                    <a href="mailto:contact@brosand.co">contact@brosand.co</a>
                  </p>
                </Typography>
                <SocialButtons />
              </Box>
            </Grid>
          </Grid>
        </Section>
      )}
      {sent && status === "success" && (
        <Section className="thankyou-wrapper">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", marginBottom: "3rem" }}
          >
            <Grid item xs={12} md={12} align="center">
              <Typography
                variant="h1"
                sx={{ marginTop: { xs: "40px", md: "0px" } }}
              >
                Thank you
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  maxWidth: "500px",
                  margin: "200px auto 40px",
                  fontSize: "24px",
                }}
              >
                <p>
                  We have received your information and will add you to our
                  mailing list.
                </p>
              </Typography>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Typography
                  variant="h6"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <p style={{ paddingTop: "20px" }}>Join our social channels</p>
                </Typography>
                <SocialButtons />
              </Box>
            </Grid>
          </Grid>
        </Section>
      )}
    </MainLayout>
  );
}

export default Newsletter;
