import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="15"
      cy="15"
      r="12"
      fill="white"
      fill-opacity="0.25"
    />
    <circle
      cx="15"
      cy="15"
      r="10"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="1.5"
    />
    <circle cx="15" cy="15" r="15" fill="white" fill-opacity="0.15" />
    <circle
      cx="15"
      cy="15"
      r="14"
      stroke="white"
      strokeOpacity="0.25"
      strokeWidth="1.5"
    />
    <circle cx="15" cy="15" r="7.5" fill="white" />
  </svg>
);

export default SvgComponent;
