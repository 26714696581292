export const ROUTE_KEYS = {
  root: "root",
  data: "data",
  method: "method",
  understand: "understand",
  evaluate: "evaluate",
  identify: "identify",
  prioritise: "prioritise",
  design: "design",
  validate: "validate",
  certification: "certification",
  usage: "usage",
  terms: "terms",
  connect: "connect",
  download: "download",
  login: "login",
  cardexplorer: "cardexplorer",
  carddetails: "carddetails",
  cardlanding: "cardlanding",
  complete: 'complete',
  signin: 'signin',
  signup: 'signup',
  signout: 'signout'
};
