function specialRoundTo(value, decimalpoints) {
  let maxDecimal = parseInt(value) > 0 ? 3 : decimalpoints
  maxDecimal = parseInt(value) > 9 ? 2 : maxDecimal
  maxDecimal = parseInt(value) > 99 ? 0 : maxDecimal
  return (
    Math.round(value * Math.pow(10, maxDecimal)) /  Math.pow(10, maxDecimal)
  );
}

function roundTo(value, decimalpoints) {
  return (
    Math.round(value * Math.pow(10, decimalpoints)) /  Math.pow(10, decimalpoints)
  );
}

export { roundTo, specialRoundTo };
