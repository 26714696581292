import React, { createContext, useState, useEffect, useContext } from "react";
import orderBy from "lodash/orderBy";

import { getCardsByLibrary } from "../api/card";

import useMediaQueries from "../services/media-queries";
import { getCarbonValue, carbonFormat } from "../services/carbon";
import { CountryContext } from "../context/CountryContext";
import {
  streamingPreselect,
  communicationPreselect,
  devicePreselect,
} from "./card/card_const";

import {
  jsonFromLocalStorage,
  jsonToLocalStorage,
} from "../services/sessionStorage";

export const CardContext = createContext();

export const CardContextProvider = ({ children }) => {
  const [isMobileView] = useMediaQueries();
  const [cardRanking, setRankingCardData] = useState(
    jsonFromLocalStorage("ranking") || null
  );
  const [rankingFetch, setRankingFetch] = useState(false);

  const { selectedCountryData, selectedCountry } = useContext(CountryContext);

  function callFetchRanking(data) {
    setRankingFetch(data);
  }
  const updateRankingData = (data) => setRankingCardData(data);

  useEffect(() => {
    async function fetchData() {
      const digitalCards = await getCardsByLibrary("Digital");

      function getTop3(data) {
        return orderBy(
          data,
          (o) => parseFloat(o.NoncountryspecificconsumptionKWH),
          "asc"
        ).slice(0, 3);
      }

      function getBottom3(data) {
        return orderBy(
          data,
          (o) => parseFloat(o.NoncountryspecificconsumptionKWH),
          "desc"
        )
          .slice(0, 3)
          .sort(
            (a, b) =>
              a.NoncountryspecificconsumptionKWH >
              b.NoncountryspecificconsumptionKWH
          );
      }

      function pickByIntro(item) {
        const picked = digitalCards.filter(
          (o) => o.Inventory.indexOf(item.name) > -1
        )[0];

        return { ...picked, ...item };
      }

      const streaming = streamingPreselect.map(pickByIntro);
      const communication = communicationPreselect.map(pickByIntro);
      const devices = devicePreselect.map(pickByIntro);

      function calculateCarbonFootprint(card) {
        card.CarbonFootprint = carbonFormat(
          getCarbonValue(card, selectedCountryData)
        );
        return card;
      }

      const topDevices = getTop3(devices).map(calculateCarbonFootprint);
      const bottomDevices = getBottom3(devices).map(calculateCarbonFootprint);
      const topStreaming = getTop3(streaming).map(calculateCarbonFootprint);
      const bottomStreaming = getBottom3(streaming).map(
        calculateCarbonFootprint
      );
      const topCommunication = getTop3(communication).map(
        calculateCarbonFootprint
      );
      const bottomCommunication = getBottom3(communication).map(
        calculateCarbonFootprint
      );

      const newRankingData = [
        {
          category: () => <>Streaming {isMobileView && <br/>}services</>,
          mostEfficient: topStreaming,
          leastEfficient: bottomStreaming,
        },
        {
          category: () => <>Communication {isMobileView && <br/>}services</>,
          mostEfficient: topCommunication,
          leastEfficient: bottomCommunication,
        },
        {
          category: () => <>Devices</>,
          mostEfficient: topDevices,
          leastEfficient: bottomDevices,
        },
      ];

      setRankingCardData(newRankingData);
      jsonToLocalStorage("ranking", newRankingData);
    }

    fetchData();
  }, [rankingFetch, selectedCountry]);

  return (
    <CardContext.Provider
      value={{
        cardRanking,
        updateRankingData,
        callFetchRanking,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};
