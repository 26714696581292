import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import BarrelImg from "./assets/barrel.png";
import CarImg from "./assets/car.png";
import HomeElectricityImg from "./assets/home-electricity.png";
import LightBulbImg from "./assets/light-bulb.png";
import TurbineImg from "./assets/turbine.png";
import WasteImg from "./assets/waste.png";
import ForestImg from "./assets/forest.png";
import SeedlingImg from "./assets/seedling.png";
import ReductionCoverImg from "./assets/reduction-cover.png";
import RightChevron from "../../icons/rightChevron";
import { Link } from "react-router-dom";

export const equivalentEmissionsItems = [
  {
    image: <img src={CarImg} />,
    description: (
      <p className="emission-desc">
        Km driven by
        <br />
        average petrol car
      </p>
    ),
    value: 65,
  },
  {
    image: <img src={BarrelImg} />,
    description: (
      <p className="emission-desc">
        Barrels of
        <br />
        oil consumed
      </p>
    ),
    value: 240,
  },
  {
    image: <img src={HomeElectricityImg} />,
    description: (
      <p className="emission-desc">
        Average year worth
        <br />
        of home electricity
      </p>
    ),
    value: 65,
  },
];

export const avoidedEmissionsItems = [
  {
    image: <img src={LightBulbImg} />,
    description: (
      <p className="emission-desc">
        Lamps
        <br />
        switched to LED
      </p>
    ),
    value: 65,
  },
  {
    image: <img src={WasteImg} />,
    description: (
      <p className="emission-desc">
        Tonnes of
        <br />
        waste recycled
      </p>
    ),
    value: 240,
  },
  {
    image: <img src={TurbineImg} />,
    description: (
      <p className="emission-desc">
        Wind turbines
        <br />
        running for a year
      </p>
    ),
    value: 65,
  },
];

export const offsetEmissionsItems = [
  {
    image: <img src={SeedlingImg} />,
    description: (
      <p className="emission-desc">
        Tree seedlings grown
        <br />
        for 10 years
      </p>
    ),
    value: 65,
  },
  {
    image: <img src={ForestImg} />,
    description: (
      <p className="emission-desc">
        Acres of forest removing
        <br /> carbon per year
      </p>
    ),
    value: 240,
  },
  {
    override: (
      <div className="learn-more-box">
        <Typography variant="body1">
          See carbon
          <br />
          reduction and
          <br />
          offset cards
        </Typography>
        <Link to="/cardexplorer/reducers" className="emission-link">
          Learn more &rsaquo;
        </Link>
      </div>
    ),
    overrideMobile: (
      <div className="learn-more-box">
        <Link to="/cardexplorer/reducers">
          <Grid container>
            <Grid item xs={3}>
              <Box component="img" src={ReductionCoverImg} />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" style={{ marginBottom: 0 }}>
                See carbon reduction and offset cards
              </Typography>
            </Grid>
            <Grid item xs={1} align="right">
              <RightChevron width={20} height={20} viewBox="0 0 22 48" />
            </Grid>
          </Grid>
        </Link>
      </div>
    ),
  },
];
