import React from "react";

import Button from "@mui/material/Button";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import SocialLinks from "../SocialLinks";

import "./styles.scss";

function SocialButtons() {
  return (
    <>
      <SocialLinks platform="twitter">
        <Button variant="outlined" className="socialMediaButton">
          <TwitterIcon />
        </Button>
      </SocialLinks>
      <SocialLinks platform="linkedin">
        <Button variant="outlined" className="socialMediaButton">
          <LinkedInIcon />
        </Button>
      </SocialLinks>
    </>
  );
}

export default SocialButtons;
