import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/system";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import MainLayout from "../../components/MainLayout";
import { readUserByEmail } from "../../api/user";
import { passHash } from "../../const/auth";

import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

function SignIn() {
  const { authData, handleAuth, handleSignOut } = useContext(AuthContext);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    handleSignOut()
  }, [authData])
  

  return (
    <MainLayout>
      <div className="center-container">
        <Grid container sx={{ margin: "300px 0 300px" }}>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            <Typography variant="h1">You are now signed out.</Typography>
            <Typography variant="h6" sx={{marginTop: '50px'}}>
              <Link to="/">Go back to homepage</Link>
            </Typography>
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>
      </div>
    </MainLayout>
  );
}

export default SignIn;
