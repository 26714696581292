import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 62 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.314 26.835v-6.29L35.547 8.841a2.668 2.668 0 0 1 3.674 1.012c.741 1.308.292 2.98-1 3.72L15.314 26.834Zm21.57 0v-6.29L57.117 8.841a2.668 2.668 0 0 1 3.674 1.012c.741 1.308.292 2.98-1 3.72L36.884 26.834ZM18.01 32.136c.989 0 1.798.819 1.798 1.82v5.46c0 1-.81 1.82-1.798 1.82h-5.392c-.989 0-1.798-.82-1.798-1.82v-5.46c0-1.001.81-1.82 1.798-1.82h5.392Zm14.38 0c.989 0 1.798.819 1.798 1.82v5.46c0 1-.81 1.82-1.798 1.82h-5.392c-.989 0-1.798-.82-1.798-1.82v-5.46c0-1.001.81-1.82 1.798-1.82h5.392Zm7.19 1.82c0-1.001.81-1.82 1.798-1.82h5.392c.989 0 1.798.819 1.798 1.82v5.46c0 1-.81 1.82-1.798 1.82h-5.392c-.989 0-1.798-.82-1.798-1.82v-5.46Z"
      fill="#383838"
    />
    <path
      opacity={0.4}
      d="M.938 3.927c0-2.01 1.61-3.64 3.594-3.64h7.19c1.989 0 3.595 1.63 3.595 3.64v22.909l21.57-12.49v12.49l21.57-12.49v31.44c0 3.014-2.415 5.46-5.392 5.46H6.33c-2.978 0-5.393-2.446-5.393-5.46V3.927Zm11.683 28.21c-.988 0-1.797.818-1.797 1.82v5.46c0 1 .809 1.82 1.797 1.82h5.393c.988 0 1.797-.82 1.797-1.82v-5.46c0-1.002-.809-1.82-1.797-1.82H12.62Zm14.38 0c-.989 0-1.797.818-1.797 1.82v5.46c0 1 .808 1.82 1.797 1.82h5.393c.988 0 1.797-.82 1.797-1.82v-5.46c0-1.002-.809-1.82-1.797-1.82H27Zm12.583 7.28c0 1 .808 1.82 1.797 1.82h5.392c.99 0 1.798-.82 1.798-1.82v-5.46c0-1.002-.809-1.82-1.797-1.82H41.38c-.989 0-1.797.818-1.797 1.82v5.46Z"
      fill="#383838"
    />
  </svg>
)

export default SvgComponent
