import React, { useEffect, useState, useContext, useRef } from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import CardsLayout from "../../components/CardsLayout";
import Section from "../../components/Section";

import Calculation1 from "./assets/calculation1.png";
import Calculation1xs from "./assets/calculation1xs.png";
import Calculation2 from "./assets/calculation2.png";
import Calculation2xs from "./assets/calculation2xs.png";
import Calculation3 from "./assets/calculation3.png";
import Calculation4 from "./assets/calculation4.png";
import Calculation5 from "./assets/calculation5.png";
import Calculation5xs from "./assets/calculation5xs.png";

import useMediaQueries from "../../services/media-queries";
import CarbonReducers from "./assets/CarbonReducers.png";
import DigitalConsumption from "./assets/DigitalConsumption.png";
import EnergyProduction from "./assets/EnergyProduction.png";
import EquiptmentLibrary from "./assets/EquiptmentLibrary.png";
import MaterialProcessing from "./assets/MaterialProcessing.png";
import MovementLibrary from "./assets/MovementLibrary.png";
import RawMaterials from "./assets/RawMaterials.png";
import Scopes from "./assets/Scopes.png";

import "./styles.scss";

function Cards() {
  gsap.registerPlugin(ScrollTrigger);
  const [isMobileView, isTabletOrLess] = useMediaQueries();

  const ref = useRef(null);

  useEffect(() => {
    if (!isMobileView) {
      const element = ref.current;

      gsap.fromTo(
        element.querySelectorAll(".link-sidebar"),
        {
          position: "static",
          top: "110px",
        },
        {
          position: "fixed",
          top: "110px",
          scrollTrigger: {
            scrub: true,
            trigger: element.querySelector(".link-sidebar"),
            start: "-110px top",
            end: "-110px",
          },
        }
      );
    }
  }, [null]);

  return (
    <div ref={ref}>
      <CardsLayout
        headerContent={{
          subtitle: "Measure",
          title: "Uncovering materials in your product or service",
          content:
            "The carbon method allows designers to quantify emissions, identify eco-opportunities and modify the environmental impact of their product/service.",
        }}
      >
        <Section sx={{ padding: { xs: "40px 0px", md: "0 40px" } }}>
          <Grid
            className="cardsContent"
            container
            spacing={2}
            sx={{
              display: "flex",
              marginBottom: "4rem",
            }}
          >
            <Grid
              item
              sm={4}
              className="metrics-container"
              sx={{ margin: { xs: "0 30px", md: "60px 0 0" } }}
            >
              <Typography variant="body1">
                <ul className="link-sidebar">
                  <Link href="#selectedMetrics">
                    <li>Selected metrics</li>
                  </Link>
                  <Link href="#researchScope">
                    <li>Research scope</li>
                  </Link>
                  <Link href="#limitations">
                    <li>Limitations & constraints</li>
                  </Link>
                  <Link href="#scope">
                    <li>Scope 1, 2 and 3 emissions</li>
                  </Link>
                  <Link href="#academic">
                    <li>Academic peer-review</li>
                  </Link>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid item xs={12} className="xs-container xs-container-bg">
                <Typography
                  variant="subtitle2"
                  id="selectedMetrics"
                  className="section-title"
                  sx={{ marginTop: {xs: "0px", md: '60px' } }}
                >
                  Selected metrics
                </Typography>
                <Typography variant="body1">
                  <p>
                    We have adopted the Average-data method and Spend-based
                    method from the{" "}
                    <a
                      href="https://ghgprotocol.org/"
                      target="_blank"
                      style={{ color: "#0284A3" }}
                    >
                      Greenhouse Gas Protocol (GHG Protocol)
                    </a>{" "}
                    and applied it at an industry level to obtain averages to
                    provide an indicative metrics.
                  </p>
                  <p>
                    We have collected CO2 values from Carbon research boards and
                    academic institutions based on the cradle-to-phase of the
                    Lifecycle Assessment model (LCA). The phases in the
                    lifecycle which have been taken into account are:
                  </p>

                  <div className="dataCollectionCalc">
                    <img src={isTabletOrLess ? Calculation2xs : Calculation2} />
                    <img src={Calculation3} />
                    <img src={isTabletOrLess ? Calculation1xs : Calculation1} />
                    <img src={Calculation4} />
                    <img src={isTabletOrLess ? Calculation5xs : Calculation5} />
                  </div>
                  {!isMobileView && (
                    <p>
                      The Cradle-to-gate is an assessment of a partial product
                      life cycle from resource extraction (cradle) to the
                      factory gate (ie, before it is transported to the
                      consumer) in-line with the GHG protocol. Phases not
                      included in this research: gate-to-gate’’ and
                      ‘‘gate-to-grave’ measurements. However where possible we
                      provide measurements for utilising recycled materials.
                    </p>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className="xs-container"
                sx={{ paddingTop: "50px" }}
              >
                <Typography
                  variant="subtitle2"
                  id="researchScope"
                  className="section-title"
                >
                  Research scope
                </Typography>
                <Typography variant="body1">
                  <p>
                    <b>Metric 1: </b>
                    <span className="lg-bold">
                      Embodied energy is the energy associated with the
                      manufacturing of a product or services.
                    </span>{" "}
                    This includes energy used for extracting and processing of
                    raw materials, refining and manufacturing of materials,
                    transportation and distribution, and assembly and
                    construction.
                  </p>
                  <p>
                    <b>Metric 2:</b>{" "}
                    <span className="lg-bold">
                      Embodied carbon means all the CO2 emitted in producing
                      materials.
                    </span>{" "}
                    This is estimated from the energy used to extract and
                    transport raw materials as well as emissions from
                    manufacturing processes. It can be used to design policies
                    that reduce the CO2 emissions from the construction sector.
                  </p>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className="xs-container xs-container-bg"
                sx={{ paddingTop: "50px" }}
              >
                <Typography
                  variant="subtitle2"
                  id="limitations"
                  className="section-title"
                >
                  Limitations &amp; constraints
                </Typography>
                <Typography variant="body1">
                  <p>
                    The measurement of carbon at a global scale is a recent
                    planetary ambition, which will facilitate decision making,
                    prioritisation and optimisation at all levels. However as
                    metrics have not been recorded, past trends and data parity
                    is not readily available. Instead governments are at the
                    inception point of requesting standardised measurements from
                    public and private sector organisations.
                  </p>
                </Typography>
              </Grid>
              <Grid item xs={12} className="bg-section">
                <p className="ref-title">Ref: Gov.uk, 09/01/2022</p>
                <p className="ref-desc">
                  The lack of available data is a commonly reported pitfall when
                  schemes seek to calculate the TCFD’s (Task force on
                  climate-related financial disclosures) recommended metrics.
                  Few, if any, trustees will be able to obtain full underlying
                  data to inform the calculation of metrics or scenario analysis
                  across their entire portfolio in the first instance. Where
                  trustees are able to obtain data but only at a cost they
                  believe to be disproportionately high or only in a format that
                  will require significant further work before it is usable,
                  they may make the decision to treat this data as unobtainable.
                </p>
                <a
                  href="https://www.gov.uk/government/publications/aligning-your-pension-scheme-with-the-taskforce-on-climate-related-financial-disclosures-recommendations/part-4-setting-metrics-and-targets-to-measure-and-manage-climate-related-risk#availability-of-data"
                  target="_blank"
                  className="section-link"
                >
                  View reference materials &rsaquo;
                </a>
              </Grid>
              <Grid item xs={12} className="bg-section">
                <p className="ref-title">Ref: Gov.uk, 09/01/2022</p>
                <p className="ref-desc">
                  The Task Force recognizes that some asset owners may be able
                  to report weighted average carbon intensity for only a portion
                  of their investments given data availability and
                  methodological issues.
                </p>
                <a
                  href="https://www.tcfdhub.org/metrics-and-targets/"
                  target="_blank"
                  className="section-link"
                >
                  View reference materials &rsaquo;
                </a>
              </Grid>
              <Grid
                item
                xs={12}
                className="xs-container"
                sx={{ paddingTop: "50px" }}
              >
                <Typography
                  variant="subtitle2"
                  id="scope"
                  className="section-title"
                >
                  Scope 1, 2 and 3 emissions
                </Typography>
                <Typography variant="body1">
                  <p>
                    Product and Service design is inherently embedded into the
                    circular economy. The circular economy is a model of the
                    production and consumption of a product or service,
                    including the provision refinement, usage, recycling and
                    disposal of existing materials. The circular economy model
                    is closely linked to the GHG Protocol for greenhouse gas
                    emissions across the entire value chain.
                  </p>
                  <p>
                    The Carbon Method has libraries which allows for the
                    measurement of Carbon across all scopes of the GHG Protocol.
                  </p>
                </Typography>
                <img
                  src={Scopes}
                  style={{ width: "100%", marginTop: "20px 0px" }}
                />
              </Grid>
              <Grid item xs={12} className="xs-container bg-section">
                <Typography variant="subtitle3" className="section-title">
                  Scope 1: Direct
                </Typography>
                <Typography variant="body1">
                  <p style={{ marginBottom: "35px" }}>
                    Direct operation of a product or service that the emissions
                    come from, for example: the running of servers,
                    transportation of goods, movement of people.
                  </p>
                </Typography>
                <Typography variant="subtitle3" sx={{ fontWeight: 400 }}>
                  <p>These libraries can help... </p>
                </Typography>
                <div className="libraries">
                  <img src={DigitalConsumption} />
                  <img src={MovementLibrary} />
                  <img src={RawMaterials} />
                  <img src={MaterialProcessing} />
                  <img src={EquiptmentLibrary} />
                </div>
              </Grid>
              <Grid item xs={12} className="xs-container bg-section">
                <Typography variant="subtitle3" className="section-title">
                  Scope 2: Indirect
                </Typography>
                <Typography variant="body1">
                  <p style={{ marginBottom: "35px" }}>
                    Indirect emissions generated by the purchase of electricity,
                    or off-set through the production of green renewable energy.
                  </p>
                </Typography>
                <Typography variant="subtitle3" sx={{ fontWeight: 400 }}>
                  <p>These libraries can help... </p>
                </Typography>
                <div className="libraries">
                  <img src={CarbonReducers} />
                  <img src={EnergyProduction} />
                </div>
              </Grid>
              <Grid item xs={12} className="xs-container bg-section">
                <Typography variant="subtitle3" className="section-title">
                  Scope 3: Indirect
                </Typography>
                <Typography variant="body1">
                  <p style={{ marginBottom: "35px" }}>
                    Scope 3 covers the entire upstream and down-stream of the
                    supply chain, from the goods the service uses to operate and
                    the disposal of the products it sells.
                  </p>
                </Typography>
                <Typography variant="subtitle3" sx={{ fontWeight: 400 }}>
                  <p>These libraries can help... </p>
                </Typography>
                <div className="libraries">
                  <img src={CarbonReducers} />
                  <img src={DigitalConsumption} />
                  <img src={MovementLibrary} />
                  <img src={EnergyProduction} />
                  <img src={EquiptmentLibrary} />
                  <img src={RawMaterials} />
                  <img src={MaterialProcessing} />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                className="xs-container"
                sx={{ paddingTop: "50px" }}
              >
                <Typography
                  variant="subtitle2"
                  id="academic"
                  className="section-title"
                >
                  Academic peer-review
                </Typography>
                <Typography variant="body1" sx={{ marginTop: "25px" }}>
                  The Carbon Method has been academically peer-reviewed by Prof
                  David Harrison and Dr Fabrizio Ceschin from Brunel University
                  London to assess the viability of the design methodology and
                  the carbon data. Brunel University have deemed this initiative
                  as ‘welcome and innovative’. Improvements to the method and
                  data have been provided which will be added into future
                  releases.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Section>
      </CardsLayout>
    </div>
  );
}

export default Cards;
