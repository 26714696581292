import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.25977 0.490234C2.95142 0.490234 0.259766 3.18189 0.259766 6.49023C0.259766 9.79858 2.95142 12.4902 6.25977 12.4902C9.56811 12.4902 12.2598 9.79858 12.2598 6.49023C12.2598 3.18189 9.56811 0.490234 6.25977 0.490234ZM6.25977 11.8962C3.27892 11.8962 0.853828 9.47098 0.853828 6.49023C0.853828 3.50949 3.27881 1.0843 6.25977 1.0843C9.24072 1.0843 11.6657 3.50949 11.6657 6.49023C11.6657 9.47098 9.24051 11.8962 6.25977 11.8962ZM7.96046 5.22088C7.96046 6.23125 7.4838 6.54974 7.10084 6.8058C6.71024 7.06697 6.37305 7.29241 6.37305 8.32275C6.37305 8.48683 6.2401 8.61978 6.07602 8.61978C5.91194 8.61978 5.77899 8.48684 5.77899 8.32275C5.77899 6.975 6.32897 6.6074 6.77077 6.31196C7.12945 6.07228 7.36643 5.91373 7.36643 5.22088C7.36643 4.61067 6.87007 4.11422 6.25977 4.11422C5.64946 4.11422 5.1531 4.61057 5.1531 5.22088C5.1531 5.38496 5.02016 5.51791 4.85607 5.51791C4.692 5.51791 4.55904 5.38496 4.55904 5.22088C4.55904 4.28313 5.32199 3.52018 6.25974 3.52018C7.19749 3.52018 7.96044 4.28313 7.96044 5.22088H7.96046ZM6.3683 9.12585V9.16291C6.3683 9.32698 6.23535 9.45994 6.07126 9.45994C5.90719 9.45994 5.77423 9.32699 5.77423 9.16291V9.12585C5.77423 8.96178 5.90718 8.82882 6.07126 8.82882C6.23534 8.82882 6.3683 8.96187 6.3683 9.12585Z"
      fill="#0284A3"
    />
  </svg>
);

export default SvgComponent;
