import  React, {useContext} from "react";

import Footer from "../Footer";
import Navigation from '../Navigation';

import "./styles.scss";

function MainLayout({ children, ...props }) {
  return (
    <div className="main-layout" {...props}>
      <Navigation />
      <section style={{paddingTop: '85px'}}>{children}</section>
      <Footer />
    </div>
  );
}

export default MainLayout;
