import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1.4375"
      y="1.13867"
      width="23.5"
      height="23.5"
      rx="4.25"
      fill="white"
      stroke="#04A12B"
      stroke-width="1.5"
    />
    <path
      d="M10.1821 18.1164C10.353 18.286 10.5856 18.3833 10.8293 18.3867C11.0676 18.4003 11.3011 18.319 11.4765 18.1613L20.3994 9.91842C20.7648 9.58063 20.7853 9.01964 20.4456 8.6572C20.0736 8.31633 19.5 8.29724 19.105 8.61224L10.8762 16.2245L7.27034 12.5311H7.27003C7.10379 12.3544 6.86898 12.2537 6.62279 12.2537C6.37692 12.2537 6.14211 12.3544 5.97556 12.5311C5.61023 12.8689 5.58969 13.4296 5.92942 13.7923L10.1821 18.1164Z"
      fill="#04A12B"
    />
  </svg>
);

export default SvgComponent;
