import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import SocialLinks from "../SocialLinks";

import useMediaQueries from "../../services/media-queries";

import "./styles.scss";

function Footer() {
  const [isMobileView] = useMediaQueries();

  return (
    <Box className="footer-container">
      <div className="center-container">
      <Grid container>
          <Grid item xs={12} lg={7} sx={{ flexGrow: 1, textAlign: "left" }} className="footer-links">
            <a href="https://thecarbonmethod.com">Carbon Method</a> © 2022 by{" "}
            <a href="https://thecarbonmethod.com" target="_blank">
              Bros&Co Group
            </a>{" "}
            Ltd is licensed under{" "}
            <a
              href="https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode"
              target="_blank"
            >
              CC BY-NC-ND 4.0
            </a>
          </Grid>
          <Grid item xs={12} lg={4} align="center" className="footer-link">
            {isMobileView ? (
              <>
                <Link to="/newsletter">Newsletter</Link>
                <Link to="/connect">Contact Us</Link>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/terms">Terms and Conditions</Link>
              </>
            ) : (
              <>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/terms">Terms and Conditions</Link>
                <Link to="/newsletter">Newsletter</Link>
                <Link to="/connect">Contact Us</Link>
              </>
            )}
          </Grid>
          <Grid item xs={12} lg={1} align="center" className="social-links">
            <SocialLinks platform="twitter">
              <TwitterIcon sx={{ marginRight: "20px" }} />
            </SocialLinks>
            <SocialLinks platform="linkedin">
              <LinkedInIcon />
            </SocialLinks>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Footer;
