import supabase from "../services/db_client";

async function getDownloadAsset({format, licence}) {
  return await supabase
    .from("downloads")
    .select()
    .filter("format", "in", `(${format})`)
    .then((item) => {
      if (item.data && item.data.length) {
        return item.data[0];
      }
    });
}

export { getDownloadAsset };
