import React, { useState, useContext } from "react";
import orderBy from "lodash/orderBy";
import ReactCountryFlag from "react-country-flag";
import getCountryISO2 from "country-iso-3-to-2";
import { Link } from "react-router-dom";
import { v4 } from "uuid";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { specialRoundTo } from "../../../utils/math";
import Section from "../../../components/Section";

import { CountryContext } from "../../../context/CountryContext";

import "./styles.scss";

function LeastMost() {
  const { countries } = useContext(CountryContext);

  return (
    <Grid container spacing={2} sx={{ margin: "0" }}>
      <Grid item xs={12}>
        <Typography variant="h1" style={{ marginBottom: "40px" }}>
          Energy production efficiency
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: { xs: "30px", md: "78px" },
            fontSize: { xs: "16px", md: "18px" },
          }}
        >
          Below illustrates the effciency of countries in terms of how much
          Carbon is created per kWh of energy generated
        </Typography>
        <Box className="production-efficiency-box">
          <Typography
            sx={{
              margin: "30px 0px 20px",
              display: "inline-block",
            }}
            variant="subtitle5"
          >
            Most efficient
          </Typography>
          <Divider className="list-divider" />
          {countries &&
            orderBy(countries, ["Carbonfootprint"], "asc")
              .splice(0, 3)
              .map((country) => (
                <>
                  <Grid
                    container
                    spacing={0}
                    className="countryListItem"
                    key={v4()}
                  >
                    <Grid item xs={3}>
                      <ReactCountryFlag
                        className="list-flag"
                        countryCode={getCountryISO2(country.Countrycode)}
                        svg
                        style={{
                          width: "90px",
                          height: "auto",
                          maxWidth: "80%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} className="countryName">
                      <Typography variant="subtitle5">
                        {country.Country}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#898989",
                          fontSize: "16px",
                        }}
                      >
                        {specialRoundTo(country.Carbonfootprint, 4)} kgCo2e per
                        hour
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className="list-divider" />
                </>
              ))}
          <Typography
            sx={{
              margin: "45px 0px 20px",
              display: "inline-block",
            }}
            variant="subtitle5"
          >
            Least efficient
          </Typography>
          <Divider className="list-divider" />

          {countries &&
            orderBy(countries, ["Carbonfootprint"], "desc")
              .splice(0, 3)
              .map((country, i) => (
                <>
                  <Grid
                    container
                    spacing={0}
                    className="countryListItem"
                    key={v4()}
                  >
                    <Grid item xs={3}>
                      <ReactCountryFlag
                        className="list-flag"
                        countryCode={getCountryISO2(country.Countrycode)}
                        svg
                        style={{
                          width: "90px",
                          height: "auto",
                          maxWidth: "80%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} className="countryName">
                      <Typography variant="subtitle5">
                        {country.Country}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#898989",
                          fontSize: "16px",
                        }}
                      >
                        {specialRoundTo(country.Carbonfootprint, 4)} kgCo2e per
                        hour
                      </Typography>
                    </Grid>
                  </Grid>
                  {i !== 2 && <Divider className="list-divider" />}
                </>
              ))}
        </Box>
        <Link to="/cardexplorer/energy">
          <Button
            variant="contained"
            size="large"
            sx={{ marginTop: "30px" }}
            className="carbon-cards-btn"
          >
            Learn more
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}

export default LeastMost;
