import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.085 11.861c0 5.663-4.577 10.25-10.218 10.25-5.642 0-10.219-4.587-10.219-10.25s4.577-10.25 10.219-10.25c5.64 0 10.218 4.587 10.218 10.25Z"
      fill={isHover ? color : "#fff"}
      stroke="#04A12B"
      strokeWidth={1.5}
    />
    <path
      d="m13.182 5.488-1.23 5.335h3.259c.136 0 .214.154.136.27l-4.74 7.21c-.04.077-.156.02-.137-.058l1.17-5.586H8.52c-.137 0-.215-.155-.137-.27l4.682-6.978c.02-.058.136-.02.117.077Z"
      fill={isHover ? "#fff" : color}
    />
  </svg>
);

export default SvgComponent;
