import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import emailValidator from "email-validator";

import { Box } from "@mui/system";
import { Grid, TextField, Typography, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import ErroBox from "../../components/ErrorBox";
import MainLayout from "../../components/MainLayout";
import { readUserByEmail } from "../../api/user";
import { reset } from "../../api/auth";
import CardBackground from "../../components/CardBackground";
import useMediaQueries from "../../services/media-queries";
import { AuthContext } from "../../context/AuthContext";

import "./styles.scss";

function SignIn() {
  const navigate = useNavigate();
  const [isMobileView] = useMediaQueries();
  const { authData, handleAuth, handleSignOut } = useContext(AuthContext);
  const [variant, setVariant] = useState("signin");
  const [userData, setUserData] = useState({});

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  useEffect(() => {
    window.scrollTo(0,0)
  }, [variant]);

  useEffect(() => {
    if (!!authData?.user) {
      navigate("/method");
    }
  }, [authData?.user]);
  const hasError = authData?.error?.length > 0;

  return (
    <MainLayout>
      <div className="center-container">
        {!isMobileView && <CardBackground />}
        {!authData?.user && variant === "signin" && (
          <Box className="centerBox">
            {isMobileView && (
              <CardBackground
                width={270}
                height={160}
                viewBox="0 0 1200 750"
                style={{ margin: "0 auto" }}
              />
            )}
            <Typography
              variant="h1"
              align="center"
              sx={{ margin: { xs: "10px 0 60px", md: "40px 0 60px" } }}
            >
              Sign In
            </Typography>
            {hasError && (
              <ErroBox content="Login failed. Please review highlighted errors below" />
            )}
            <Box>
              <TextField
                label="Email"
                type="email"
                sx={{ width: "100%" }}
                error={hasError}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </Box>
            <Box sx={{ margin: "20px 0", width: "100%" }}>
              <TextField
                type="password"
                label="Password"
                error={hasError}
                sx={{ width: "100%" }}
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
              />
            </Box>
            <Button
              variant="contained"
              size="large"
              sx={{ width: "100%", marginTop: "30px" }}
              onClick={() => {
                handleAuth(userData);
              }}
            >
              Sign In
            </Button>
            <Typography
              variant="body1"
              align="center"
              sx={{ margin: "20px 0 100px" }}
            >
              Having trouble{" "}
              <Link onClick={() => setVariant("reset")}>signing in</Link>?
            </Typography>
            <Typography variant="body1" align="center">
              Don’t have an account, {isMobileView && <br />}
              <Link to="/connect">book a demo</Link>.
            </Typography>
          </Box>
        )}
        {!authData?.user && variant === "reset" && (
          <Box className="centerBox" sx={{ height: "600px" }}>
            {isMobileView && (
              <CardBackground
                width={270}
                height={160}
                viewBox="0 0 1200 750"
                style={{ margin: "0 auto" }}
              />
            )}
            <Typography
              variant="h1"
              align="center"
              sx={{ margin: "40px 0 60px" }}
            >
              Reset password
            </Typography>
            <Box>
              <TextField
                label="Email"
                type="email"
                sx={{ width: "100%" }}
                error={
                  userData?.email && !emailValidator.validate(userData.email)
                }
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </Box>
            <Button
              variant="contained"
              size="large"
              type="submit"
              sx={{ width: "100%", marginTop: "30px" }}
              disabled={
                userData?.email && !emailValidator.validate(userData.email)
              }
              onClick={() => {
                if (
                  userData?.email &&
                  emailValidator.validate(userData.email)
                ) {
                  reset(userData.email);
                  setVariant("reset-success");
                }
              }}
            >
              Submit
            </Button>
            <Typography
              variant="body1"
              align="center"
              sx={{ margin: "20px 0 100px" }}
            >
              Or you can{" "}
              <Link onClick={() => setVariant("signin")}>sign in</Link>.
            </Typography>
            <Typography variant="body1" align="center">
              Don’t have an account, <Link to="/connect">book a demo</Link>.
            </Typography>
          </Box>
        )}
        {variant === "reset-success" && (
          <Box className="centerBox" sx={{ height: "600px" }}>
            <Typography
              variant="h1"
              align="center"
              sx={{ margin: "140px 0 60px" }}
            >
              Reset password
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ margin: "40px 0 60px" }}
            >
              Please check you e-mail.
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ margin: "40px 0 30px" }}
            >
              If there is an account associated with this e-mail address, you
              will receive a password reset link.
            </Typography>
          </Box>
        )}
        {authData?.user && (
          <Box className="centerBox" style={{ marginTop: "240px" }}>
            <Typography
              variant="h1"
              align="center"
              sx={{ margin: "40px 0 60px" }}
            >
              Success
            </Typography>
            <Typography variant="body1" align="center">
              You are logged in now.
            </Typography>
            <Link to="/">
              <Button
                variant="contained"
                size="large"
                sx={{ width: "100%", marginTop: "30px" }}
              >
                Go to homepage
              </Button>
            </Link>
          </Box>
        )}
      </div>
    </MainLayout>
  );
}

export default SignIn;
