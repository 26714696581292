import * as React from "react";
import { Link } from "react-router-dom";

import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import RoundValidateIcon from "../../icons/RoundValidate";

function IconLink({
  linkIcon = (props) => <RoundValidateIcon {...props} />,
  link = "/",
  text = "icon link",
}) {
  const [isHover, setHover] = React.useState();

  return (
    <Link
      to={link}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <ListItemIcon sx={{ display: "flex", alignItems: "center" }}>
        {linkIcon({
          style: { width: "30px", marginRight: "15px" },
          isHover: isHover,
        })}
      </ListItemIcon>
      <ListItemText primary={text} />
    </Link>
  );
}

export default IconLink;
