import React, { useContext, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

function EnergyMixDialog({ open, setEnergyMixDialogOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setEnergyMixDialogOpen(false)}
      maxWidth="md"
      sx={{
        zIndex: 10000,
      }}
    >
      <IconButton
        onClick={() => setEnergyMixDialogOpen(false)}
        className="energyMixDialogIcon"
      >
        <CloseIcon />
      </IconButton>
      <div className="energyMixDialog">
        <p className="energyMixDialogTitle">Energy mix</p>
        <p className="energyMixDialogDesc">
          This is the basic amount of energy required to make the material. This
          energy value is based on a country or region energy mix. As each
          country has a different mix of energy supplies e.g. oil and gas,
          nuclear, green renewable, their energy consumption to make the same
          material can vary significantly therefore each country will have a
          different value. This variation has already been calculated into the
          cards.
        </p>
      </div>
    </Dialog>
  );
}

export default EnergyMixDialog;
