import React, {useContext} from "react";
import { useNavigate, Link } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

import { UserContext } from "../../context/UserContext";
import { hasAccess } from "../../api/auth";

import "./styles.scss";

function CookieDisclaimer() {
  const {cookieAgreement, updateCookieAgreement} = useContext(UserContext)

  const navigate = useNavigate();

  if (hasAccess()) {
    setTimeout(() => navigate("/login"), 1);
    return null;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    updateCookieAgreement(true);
  };

  return (
    <Snackbar
      open={!cookieAgreement}
      onClose={handleClose}
      message={
        <p>
          We’ve updated our <Link to="/privacy">privacy policy</Link> to explain how we
          process and store your data and use cookies.{" "}
          <span>
            By continuing to browse the site, you’re agreeing to our use of
            cookies.
          </span>
        </p>
      }
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CancelIcon />
        </IconButton>
      }
      className="cookie-snackbar"
    />
  );
}

export default CookieDisclaimer;
