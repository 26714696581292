export const LIBRARIES_NO_ENERGYMIX = ["Energy", "Reducers"];

export const GLOBAL_AVERAGE = [
  "Processing",
  "Raw Materials",
  "Equipment",
  "Reducers",
];

export function checkEnergyMix(cardData) {
  if (
    GLOBAL_AVERAGE.indexOf(cardData.Library) > -1 &&
    cardData.hasOwnProperty("Library")
  ) {
    return "GLOBAL_AVERAGE";
  } else if (
    LIBRARIES_NO_ENERGYMIX.indexOf(cardData.Library) > -1 &&
    cardData.hasOwnProperty("Library")
  ) {
    return "NO_MIX";
  } else {
    return "MIX";
  }
}

export const ENERGY_MIX_COUNTRIES_SELECT = ["Digital", "Movement"];

export const ENERGY_MIX_DESCRIPTION = ["Energy"];

export const GLOBAL_AVERAGE_DESCRIPTION = [
  "Raw Materials",
  "Processing",
  "Equipment",
  "Reducers",
];

export const hasCountriesSelect = (library) =>
  ENERGY_MIX_COUNTRIES_SELECT.includes(library);

export const hasEnergyMixDescription = (library) =>
  ENERGY_MIX_DESCRIPTION.includes(library);

export const hasGlobalAverageDescription = (library) =>
  GLOBAL_AVERAGE_DESCRIPTION.includes(library);
