import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={35}
    height={35}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={35}
      height={35}
    >
      <rect x={0.992} y={0.016} width={34} height={34} rx={5} fill="#D9D9D9" />
    </mask>
    <g filter="url(#b)" mask="url(#a)">
      <path
        d="M30.036.016H5.948A4.956 4.956 0 0 0 .992 4.972v24.087a4.956 4.956 0 0 0 4.956 4.957h24.088a4.956 4.956 0 0 0 4.956-4.957V4.972A4.956 4.956 0 0 0 30.036.016Z"
        fill="#222223"
      />
      <path
        d="M13.753 29.73a4.239 4.239 0 0 0 4.238-4.239v-4.237h-4.238a4.236 4.236 0 1 0 0 8.475Z"
        fill="#17A874"
      />
      <path
        d="M9.516 17.015a4.238 4.238 0 0 1 4.237-4.238h4.238v8.476h-4.238a4.238 4.238 0 0 1-4.237-4.238Z"
        fill="#2443A1"
      />
      <path
        d="M9.516 8.54a4.238 4.238 0 0 1 4.237-4.237h4.238v8.475h-4.238A4.238 4.238 0 0 1 9.516 8.54ZM17.992 4.328h4.238a4.238 4.238 0 0 1 0 8.475h-4.238V4.328Z"
        fill="#F24E1E"
      />
      <path
        d="M26.467 17.015a4.237 4.237 0 1 1-8.473 0 4.237 4.237 0 0 1 8.473 0Z"
        fill="#0C9BEC"
      />
    </g>
    <defs>
      <filter
        id="b"
        x={-9.008}
        y={-8.984}
        width={54}
        height={54}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2315_29414"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2315_29414"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgComponent;
