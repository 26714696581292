import supabase from "../services/db_client";

async function getCardReferences(ID) {
  return await supabase
    .from("references")
    .select()
    .filter("CardID", "in", `(${ID})`)
    .then((item) => {
      if (item.data && item.data.length) {
        return item.data[0];
      }
    });
}

export { getCardReferences };
