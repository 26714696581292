import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";

import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Slide from "@mui/material/Slide";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import useMediaQueries from "../../services/media-queries";
import { getCarbonValue, carbonFormat } from "../../services/carbon";

import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import Section from "../../components/Section";

import CardBox from "../../components/CardBox";
import CardCategories from "../../components/CardCategories";
import CardCategory from "../../components/CardCategory";
import DownloadKit from "../../components/DownloadKit";
import HeaderLarge from "../../components/HeaderLarge";

import { CardContext } from "../../context/CardContext";
import { CountryContext } from "../../context/CountryContext";

import LeastMost from "./LeastMost";
import Comparison from "./Comparison";
import Map from "./Map";

import "./styles.scss";

function CardLanding() {
  const [isMobileView, isTabletOrLess] = useMediaQueries();
  const [cardAnatomySection, setCardAnatomySection] = useState(null);
  const [units, setUnits] = useState(40);
  const { selectedCountryData } = useContext(CountryContext);
  const { cardRanking } = useContext(CardContext);

  const isCardAnatomySectionActive = (id) => id === cardAnatomySection;

  function updateUnits(value) {
    setUnits(value > 0 ? Math.min(value, 9999) : 0)
  }

  const carbonValue = carbonFormat(
    getCarbonValue(
      { NoncountryspecificconsumptionKWH: 0.195, Unitnumber: parseInt(units) },
      selectedCountryData
    )
  );

  const Indicator = ({ reverse }) => (
    <div className="left-indicator-container" style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: reverse ? "row-reverse" : "row",
          width: reverse ? "calc(100% - 5px)" : "100%",
          alignItems: "center",
          height: "30px",
          marginTop: "-8px",
        }}
      >
        <div
          style={{
            height: "5px",
            width: "5px",
            borderRadius: "5px",
            background: "#222223",
            opacity: 0.5,
          }}
        />
        <div
          style={{
            height: "1px",
            background: "#222223",
            width: "90%",
            opacity: 0.5,
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="main-layout">
      <Navigation />
      <div style={{ paddingTop: "85px" }}>
        <HeaderLarge
          style={{
            background: "transparent",
            paddingTop: "35px",
            paddingBottom: "60px",
          }}
          leftColumn={
            <>
              <Typography
                variant="h1"
                sx={{
                  marginTop: { xs: "33px", md: "40px" },
                  marginBottom: { xs: "32px", md: "40px" },
                }}
              >
                Carbon cards
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  maxWidth: "420px",
                }}
              >
                <p>
                  Collection of over 450 cards that allows designers to
                  calculate and understand the carbon impact of each ingredient
                  that goes in to the end product
                </p>
              </Typography>
            </>
          }
          rightColumn={
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column-reverse",
                  md: "column",
                  alignSelf: "flex-start",
                },
              }}
            >
              <Link to="/cards">
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    marginTop: { xs: "20px", md: "60px" },
                    fontWeight: 400,
                    width: "100%",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}
                >
                  See our research method
                </Button>
              </Link>
            </Box>
          }
          rightColumnStyles={{
            justifyContent: isMobileView ? "center" : "flex-end",
          }}
        />
      </div>
      <Section
        align="center"
        variant="dark"
        className="card-anatomy-section card-landing-xs-container"
      >
        <Typography variant="h1" sx={{ marginBottom: "30px" }}>
          Card anatomy
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: { xs: "30px", md: "75px" },
            fontSize: { xs: "16px", md: "18px" },
          }}
        >
          Each card is designed to be easily digestable and help you be more
          <br />
          sustainable in your design process
        </Typography>
        <Grid container sx={{ justifyContent: "center" }}>
          {!isMobileView && (
            <Grid item xs={12} md={3} lg={4} className="card-anatomy-container">
              <Box sx={{ textAlign: "left" }}>
                <div className="card-anatomy-title-container">
                  <Typography
                    variant="subtitle4"
                    className="card-anatomy-title"
                  >
                    Card library
                  </Typography>
                  {!isTabletOrLess && <Indicator />}
                </div>
                <Typography
                  variant="body1"
                  className="card-anatomy-desc"
                  sx={{ maxWidth: "340px" }}
                >
                  The source library where this material or activity is
                  associated with, learn more by visiting the library.
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <div className="card-anatomy-title-container">
                  <Typography
                    variant="subtitle4"
                    className="card-anatomy-title"
                  >
                    Carbon impact
                  </Typography>
                  {!isTabletOrLess && <Indicator />}
                </div>
                <Typography
                  variant="body1"
                  className="card-anatomy-desc"
                  sx={{ maxWidth: "280px" }}
                >
                  Average carbon produced from this material or activity.
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <div className="card-anatomy-title-container">
                  <Typography
                    variant="subtitle4"
                    className="card-anatomy-title"
                  >
                    Statistics
                  </Typography>
                  {!isTabletOrLess && <Indicator />}
                </div>
                <Typography
                  variant="body1"
                  className="card-anatomy-desc"
                  sx={{ maxWidth: "330px" }}
                >
                  Where the energy is sourced, how much is consumed to create
                  this material or complete the activity.
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid
            item
            className="cardBoxContainer"
            sx={{ paddingBottom: { xs: "20px" }, height: "390px" }}
          >
            <CardBox
              titleActivity="Running a"
              title="Computer with LCD monitor"
              value={carbonValue}
              hours={+units}
              energyConsumed="14,352"
              onChange={(e) => updateUnits(e.target.value)}
              onHeaderClick={() => setCardAnatomySection("cardLibrary")}
              onValueClick={() => setCardAnatomySection("carbonImpact")}
              onTitleClick={() => setCardAnatomySection("material")}
              onHoursClick={() => setCardAnatomySection("units")}
              onFooterClick={() => setCardAnatomySection("statistics")}
            />
          </Grid>
          {!isMobileView && (
            <Grid
              item
              xs={12}
              md={3}
              lg={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: { xs: "left", md: "right" },
                  marginTop: "10px",
                }}
              >
                <div className="card-anatomy-title-container">
                  {!isTabletOrLess && <Indicator reverse />}
                  <Typography
                    variant="subtitle4"
                    className="card-anatomy-title"
                  >
                    Material
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  className="card-anatomy-desc"
                  sx={{ marginLeft: { md: "auto" }, maxWidth: "300px" }}
                >
                  The material or activity which is causing carbon impact to the
                  planet.
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: { xs: "left", md: "right" },
                  marginTop: { sx: "0x", md: "20px" },
                }}
              >
                <div className="card-anatomy-title-container">
                  {!isTabletOrLess && <Indicator reverse />}
                  <Typography
                    variant="subtitle4"
                    className="card-anatomy-title"
                  >
                    Units
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  className="card-anatomy-desc"
                  sx={{ marginLeft: { md: "auto" }, maxWidth: "330px" }}
                >
                  The number of times the carbon impact is multiplied. This can
                  be changed for your specific usage scenario.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        {isMobileView && (
          <div className="card-anatomy-slide-container">
            <Slide
              direction="up"
              in={cardAnatomySection !== null}
              mountOnEnter
              unmountOnExit
              appear
            >
              <Box>
                {isCardAnatomySectionActive("cardLibrary") && (
                  <>
                    <IconButton onClick={() => setCardAnatomySection(null)}>
                      <CancelIcon />
                    </IconButton>
                    <p className="card-anatomy-slide-title">Card library</p>
                    <Typography variant="body1">
                      The source library where this material or activity is
                      associated with, learn more by visiting the library.
                    </Typography>
                  </>
                )}
                {isCardAnatomySectionActive("carbonImpact") && (
                  <>
                    <IconButton onClick={() => setCardAnatomySection(null)}>
                      <CancelIcon />
                    </IconButton>
                    <p className="card-anatomy-slide-title">Carbon impact</p>
                    <Typography variant="body1">
                      Average carbon produced from this material or activity.
                    </Typography>
                  </>
                )}
                {isCardAnatomySectionActive("material") && (
                  <>
                    <IconButton onClick={() => setCardAnatomySection(null)}>
                      <CancelIcon />
                    </IconButton>
                    <p className="card-anatomy-slide-title">Material</p>
                    <Typography variant="body1">
                      The material or activity which is causing carbon impact to
                      the planet.
                    </Typography>
                  </>
                )}
                {isCardAnatomySectionActive("units") && (
                  <>
                    <IconButton onClick={() => setCardAnatomySection(null)}>
                      <CancelIcon />
                    </IconButton>
                    <p className="card-anatomy-slide-title">Units</p>
                    <Typography variant="body1">
                      The number of times the carbon impact is multiplied. This
                      can be changed for your specific usage scenario.
                    </Typography>
                  </>
                )}
                {isCardAnatomySectionActive("statistics") && (
                  <>
                    <IconButton onClick={() => setCardAnatomySection(null)}>
                      <CancelIcon />
                    </IconButton>
                    <p className="card-anatomy-slide-title">Statistics</p>
                    <Typography variant="body1">
                      Where the energy is sourced, how much is consumed to
                      create this material or complete the activity.
                    </Typography>
                  </>
                )}
              </Box>
            </Slide>
          </div>
        )}
      </Section>
      <Section align="center" className="card-landing-xs-container">
        <Typography variant="h1">Carbon categories</Typography>
        <Typography
          variant="subtitle1"
          sx={{
            margin: { xs: "20px 0px", md: "20px 0px 80px" },
            fontSize: { xs: "16px", md: "18px" },
          }}
        >
          Our cards are organised into 7 categories
        </Typography>
        <List className="carbonCategories">
          {isMobileView ? (
            <Tabs
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
              key={v4()}
            >
              {CardCategories.map((card) => (
                <CardCategory card={card} />
              ))}
            </Tabs>
          ) : (
            CardCategories.map((card) => (
              <CardCategory card={card} key={v4()} />
            ))
          )}
        </List>
        <Link to="/cardexplorer">
          <Button
            variant="contained"
            size="large"
            sx={{ marginTop: "60px" }}
            className="carbon-cards-btn"
          >
            Explore cards
          </Button>
        </Link>
      </Section>
      <Section
        align="center"
        variant="dark"
        className="card-landing-xs-container carbon-comparison-container-md"
      >
        <Comparison />
      </Section>
      <Section align="center" className="card-landing-xs-container ">
        <Grid container spacing={2}>
          {isMobileView ? <LeastMost /> : <Map />}
        </Grid>
      </Section>
      <Section
        align="center"
        variant="dark"
        className="card-landing-xs-container"
      >
        <DownloadKit />
      </Section>
      <Footer />
    </div>
  );
}

export default CardLanding;
