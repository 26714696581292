import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 48 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.762 25.48c-2.115-2.114-5.257-2.517-7.798-1.269l-9.817-9.743V8.804L6.54.039.695 5.801l8.767 11.607h5.665l9.744 9.735c-1.248 2.54-.845 5.683 1.27 7.798l10.685 10.694a3.413 3.413 0 0 0 4.813 0l4.817-4.818a3.413 3.413 0 0 0 0-4.813L35.762 25.481Z"
      fill="#383838"
    />
    <path
      opacity={0.4}
      d="m21.505 28.07-5.406-5.397L2.5 36.27a6.162 6.162 0 1 0 8.714 8.714l10.876-10.876c-.805-1.901-1.07-3.993-.586-6.038ZM6.538 43.138a2.192 2.192 0 1 1 0-4.385 2.192 2.192 0 0 1 0 4.385Zm40.518-33.122a1.097 1.097 0 0 0-1.84-.504l-6.798 6.803-6.206-1.033-1.034-6.211 6.799-6.799A1.097 1.097 0 0 0 37.459.43c-4.482-1.116-9.14.2-12.483 3.468-3.626 3.62-4.63 8.877-3.115 13.452l-.374.374 6.479 6.481a6.78 6.78 0 0 1 7.798 1.27l.686.682a12.958 12.958 0 0 0 7.143-3.646c3.271-3.27 4.504-8.01 3.463-12.494Z"
      fill="#383838"
    />
  </svg>
);

export default SvgComponent;
