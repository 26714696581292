import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 31 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.537.484 16.303 23.2h13.873c.582 0 .914.659.582 1.153L10.57 55.05c-.166.33-.664.082-.581-.247l4.984-23.785H1.682c-.581 0-.913-.658-.581-1.152L21.038.156c.084-.248.582-.084.499.328Z"
      fill="#383838"
    />
  </svg>
);

export default SvgComponent;
