import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.07 11.88c0 5.664-4.578 10.25-10.219 10.25-5.641 0-10.218-4.586-10.218-10.25 0-5.662 4.577-10.25 10.218-10.25s10.218 4.588 10.218 10.25Z"
      fill={isHover ? color : "#fff"}
      stroke="#04A12B"
      strokeWidth={1.5}
    />
    <path
      d="M8.11 16.974a1.198 1.198 0 0 0 1.198 1.198H14.4a1.199 1.199 0 0 0 1.198-1.198V6.788A1.198 1.198 0 0 0 14.4 5.59H9.308a1.198 1.198 0 0 0-1.199 1.198v10.186Zm.598-10.186a.6.6 0 0 1 .6-.599H14.4a.599.599 0 0 1 .599.6v10.185a.6.6 0 0 1-.6.6H9.309a.599.599 0 0 1-.6-.6V6.788Zm2.547 9.587a.6.6 0 1 1 1.2 0 .6.6 0 0 1-1.2 0Z"
      fill={isHover ? "#fff" : color}
    />
  </svg>
);

export default SvgComponent;
