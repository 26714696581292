import React, { useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { v4 } from "uuid";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import CloseIcon from "@mui/icons-material/Close";
import ListItem from "@mui/material/ListItem";

import SmallZeroIcon from "../../icons/SmallZero";
import RoundUnderstandIcon from "../../icons/RoundUnderstand";
import RoundEvaluateIcon from "../../icons/RoundEvaluate";
import RoundIdentifyIcon from "../../icons/RoundIdentify";
import RoundPrioritiseIcon from "../../icons/RoundPrioritise";
import RoundValidateIcon from "../../icons/RoundValidate";
import RoundDesignIcon from "../../icons/RoundDesign";
import { AuthContext } from "../../context/AuthContext";

import CardLandingIcon from "./assets/carbon-cards.png";

import { getCurrentSection } from "../../services/navigation";

import CardCategories from "../CardCategories";

import "./styles.scss";

function Drawer(props) {
  const { handleClose, handleSignOut, authData } = props;
  const [open, setOpen] = React.useState(getCurrentSection() || true);
  const navigate = useNavigate();

  function btnHandler(path) {
    navigate(`/${path}`);
  }

  const handleClick = (openMenu) => {
    if (open === openMenu) {
      setOpen(null);
    } else {
      setOpen(openMenu);
    }
  };

  return (
    <Box className="drawerMenu">
      <Grid container spacing="2" sx={{ height: "80px" }}>
        <Grid item xs={10} sx={{ display: "flex" }}>
          <Link to="/">
            <Typography
              variant="subtitle1"
              sx={{
                padding: "25px 30px ",
                display: "block",
              }}
            >
              Carbon Method&#8482;
            </Typography>
          </Link>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ListItemButton
            onClick={() => handleClose()}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CloseIcon />
          </ListItemButton>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: "rgba(0,0,0,.25)" }} />
      <List
        sx={{
          padding: "0 0px 20px",
        }}
      >
        <ListItemButton
          onClick={() => handleClick("method")}
          sx={{ padding: "25px 30px" }}
        >
          <ListItemText primary="Method" />
          {open === "method" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Divider />
        <Collapse
          in={open === "method"}
          timeout="auto"
          className="collapseDrawerMenu drawerMethod"
          unmountOnExit
        >
          <List sx={{ padding: "0px", margin: "30px 0" }}>
            <ListItem>
              <Paper sx={{ width: "100%" }}>
                <Link to="/method">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon className="aboutCarbon">
                      <SmallZeroIcon />
                    </ListItemIcon>
                    <ListItemText primary="About Carbon Method" />
                  </ListItemButton>
                </Link>
              </Paper>
            </ListItem>
            <ListItem>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "20px", margin: "12px 0" }}
              >
                Measure
              </Typography>
            </ListItem>
            <ListItem>
              <Paper sx={{ width: "100%" }}>
                <Link to="/understand">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <RoundUnderstandIcon />
                    </ListItemIcon>
                    <ListItemText primary="Understand" />
                  </ListItemButton>
                </Link>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper sx={{ width: "100%" }}>
                <Link to="/evaluate">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <RoundEvaluateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Evaluate" />
                  </ListItemButton>
                </Link>
              </Paper>
            </ListItem>
            <ListItem>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "20px", margin: "12px 0" }}
              >
                Track
              </Typography>
            </ListItem>
            <ListItem>
              <Paper sx={{ width: "100%" }}>
                <Link to="/identify">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <RoundIdentifyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Identify" />
                  </ListItemButton>
                </Link>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper sx={{ width: "100%" }}>
                <Link to="/prioritise">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <RoundPrioritiseIcon />
                    </ListItemIcon>
                    <ListItemText primary="Prioritise" />
                  </ListItemButton>
                </Link>
              </Paper>
            </ListItem>
            <ListItem>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "20px", margin: "12px 0" }}
              >
                Reduce
              </Typography>
            </ListItem>
            <ListItem>
              <Paper sx={{ width: "100%" }}>
                <Link to="/design">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <RoundDesignIcon />
                    </ListItemIcon>
                    <ListItemText primary="Design" />
                  </ListItemButton>
                </Link>
              </Paper>
            </ListItem>
            <ListItem>
              <Paper sx={{ width: "100%" }}>
                <Link to="/validate">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <RoundValidateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Validate" />
                  </ListItemButton>
                </Link>
              </Paper>
            </ListItem>
          </List>
          <Divider />
        </Collapse>

        <ListItemButton
          onClick={() => handleClick("cards")}
          sx={{ padding: "25px 30px" }}
        >
          <ListItemText primary="Cards" />
          {open === "cards" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={open === "cards"}
          timeout="auto"
          className="collapseDrawerMenu collapseCards"
          unmountOnExit
        >
          <List sx={{ padding: "0px", marginBottom: "30px" }}>
            <ListItem>
              <Paper sx={{ width: "100%" }}>
                <Link to="/cardlanding">
                  <ListItemButton sx={{ pl: 2 }}>
                    <ListItemIcon>
                      <img
                        src={CardLandingIcon}
                        alt=""
                        style={{ width: "34px" }}
                      />
                    </ListItemIcon>
                    <ListItemText>About Carbon cards</ListItemText>
                  </ListItemButton>
                </Link>
              </Paper>
            </ListItem>
            <Typography variant="subtitle1" sx={{ margin: "15px 30px" }}>
              Libraries
            </Typography>
            {CardCategories.map((card) => (
              <ListItem key={v4()}>
                <Paper
                  sx={{ width: "100%", borderLeft: `5px solid ${card.color}` }}
                >
                  <a href={card.link}>
                    <ListItemButton sx={{ pl: 2 }}>
                      <ListItemIcon>{card.icon}</ListItemIcon>
                      <ListItemText primary={card.title} />
                    </ListItemButton>
                  </a>
                </Paper>
              </ListItem>
            ))}
          </List>
        </Collapse>

        <Divider />
        <Link to="/certification">
          <ListItemButton sx={{ padding: "25px 30px" }}>
            <ListItemText primary="Training" />
          </ListItemButton>
        </Link>
        <Divider />
        {authData?.user ? (
          <ListItemButton
            sx={{ padding: "25px 30px" }}
            onClick={() => handleSignOut()}
          >
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        ) : (
          <Link to="/signin">
            <ListItemButton sx={{ padding: "25px 30px" }}>
              <ListItemText primary="Sign In" />
            </ListItemButton>
          </Link>
        )}

        <Divider />

        <Box sx={{ textAlign: "center", paddingTop: "40px" }}>
          {authData?.user ? (
            <Link to="/download">
              <Button
                variant="contained"
                size="large"
                style={{ padding: "6px 25px", width: "50%" }}
              >
                Download
              </Button>
            </Link>
          ) : (
            <Link to="/connect">
              <Button
                variant="contained"
                size="large"
                style={{ padding: "6px 25px", width: "50%" }}
              >
                Book a demo
              </Button>
            </Link>
          )}
        </Box>
      </List>
    </Box>
  );
}

export default Drawer;
