import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import HeaderLarge from "../../components/HeaderLarge";
import MainLayout from "../../components/MainLayout";
import Section from "../../components/Section";
import useMediaQueries from "../../services/media-queries";
import Stepper from "./Stepper";

import HeaderImg from "./CertificationImage.png";
import HeaderImgMobile from "./CertificationImageMobile.png";

import Register from "./Register";

import "./styles.scss";

function App() {
  const [isMobileView] = useMediaQueries();

  return (
    <MainLayout>
      <HeaderLarge
        style={{
          paddingRight: isMobileView ? "20px" : "100px",
          paddingBottom: "0px",
          minHeight: "765px",
          display: "flex",
        }}
        leftColumnStyles={{
          alignItems: "center",
          display: "flex",
          paddingLeft: "0px",
        }}
        leftColumn={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h1"
              sx={{
                margin: {
                  md: "100px 0 40px",
                  xs: "20px 0 20px",
                  width: "100%",
                },
              }}
            >
              Sustainable design training & certification
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                maxWidth: "500px",
                fontSize: { xs: "16px", md: "18px" },
              }}
            >
              <p>
                Learn about sustainable design, the reason why the design
                community needs to evolve and why climate change is only a
                design decision away. Get tools, methods and processes that will
                help you bring the sustainable narrative into your organisation
                and learn how to bridge the gap between sustainable design and
                business.
              </p>
            </Typography>
            {isMobileView && (
              <Box align="right">
                <Box
                  component="img"
                  sx={{ margin: "20px -20px 20px 0px", maxWidth: "110%" }}
                  src={HeaderImgMobile}
                />
              </Box>
            )}
            <div>
              <a href="#register">
                <Button
                  variant="contained"
                  size="large"
                  sx={{ marginTop: "20px", padding: "0 60px" }}
                >
                  Register your interest
                </Button>
              </a>
            </div>
          </div>
        }
        rightColumn={
          <>
            {!isMobileView && (
              <img src={HeaderImg} className="certification-header-img" />
            )}
          </>
        }
        rightColumnStyles={
          isMobileView
            ? {
                paddingBottom: "40px",
              }
            : {
                justifyContent: "flex-end",
                position: "absolute",
                paddingRight: "0px",
                right: 0,
              }
        }
      />
      <Section>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} md={5}>
            <Typography
              variant="h1"
              sx={{
                textAlign: { xs: "center", md: "left" },
                marginTop: { xs: "40px", md: "0px" },
              }}
            >
              Unpacking
              <br />
              the course
            </Typography>
          </Grid>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={6} align="center">
            <Stepper />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Section>
      <Section variant="dark" id="register">
        <Register />
      </Section>
    </MainLayout>
  );
}

export default App;
