import { ROUTE_KEYS } from "./const/route-keys";
import Homepage from "./pages/Home";

import Design from "./pages/Design";
import Evaluate from "./pages/Evaluate";
import Identify from "./pages/Identify";
import Prioritise from "./pages/Prioritise";
import Understand from "./pages/Understand";
import Validate from "./pages/Validate";

import Certification from "./pages/Certification";

import CardDetails from "./pages/CardDetails";
import CardExplorer from "./pages/CardExplorer";
import Cards from "./pages/Cards";
import Connect from "./pages/Connect";
import Newsletter from './pages/Newsletter'
import DownloadSignedIn from "./pages/DownloadSignedIn";
import Login from "./pages/Login";
import Method from "./pages/Method";
import Terms from "./pages/Terms";
import Usage from "./pages/Usage";
import CardLanding from "./pages/CardLanding";
import DownloadComplete from './pages/DownloadComplete'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import SignOut from './pages/SignOut'
import Password from './pages/Password'

const tabRoutes = [
  {
    // path: `/${ROUTE_KEYS.singleSource}/${ROUTE_KEYS.top}`,
    // component: <SingleSource />,
    // key: ROUTE_KEYS.top,
  },
];

export const menuRoutes = [
  {
    path: "/",
    component: <Homepage />,
    key: ROUTE_KEYS.root,
  },
  {
    path: "/login",
    component: <Login />,
    key: ROUTE_KEYS.login,
  },
  {
    path: "/method",
    component: <Method />,
    key: ROUTE_KEYS.method,
  },
  {
    path: "/understand",
    component: <Understand />,
    key: ROUTE_KEYS.understand,
  },
  {
    path: "/evaluate",
    component: <Evaluate />,
    key: ROUTE_KEYS.evaluate,
  },
  {
    path: "/identify",
    component: <Identify />,
    key: ROUTE_KEYS.identify,
  },
  {
    path: "/prioritise",
    component: <Prioritise />,
    key: ROUTE_KEYS.prioritise,
  },
  {
    path: "/design",
    component: <Design />,
    key: ROUTE_KEYS.design,
  },
  {
    path: "/validate",
    component: <Validate />,
    key: ROUTE_KEYS.validate,
  },
  {
    path: "/usage",
    component: <Usage />,
    key: ROUTE_KEYS.usage,
  },
  {
    path: "/cards",
    component: <Cards />,
    key: ROUTE_KEYS.cards,
  },
  {
    path: "/terms",
    component: <Terms />,
    key: ROUTE_KEYS.terms,
  },
  {
    path: "/privacy",
    component: <Usage />,
    key: ROUTE_KEYS.terms,
  },
  {
    path: "/connect",
    component: <Connect />,
    key: ROUTE_KEYS.connect,
  },
  {
    path: "/newsletter",
    component: <Newsletter />,
    key: ROUTE_KEYS.connect,
  },
  {
    path: "/download",
    component: <DownloadSignedIn />,
    key: ROUTE_KEYS.download,
    protectedRoute: true
  },
  {
    path: "/complete",
    component: <DownloadComplete />,
    key: ROUTE_KEYS.complete,
  },
  {
    path: "/complete/:journey",
    component: <DownloadComplete />,
    key: ROUTE_KEYS.complete,
  },
  {
    path: "/certification",
    component: <Certification />,
    key: ROUTE_KEYS.certification,
  },
  {
    path: "/cardexplorer",
    component: <CardExplorer />,
    key: ROUTE_KEYS.cardexplorer,
  },
  {
    path: "/cardexplorer/:library",
    component: <CardExplorer />,
    key: ROUTE_KEYS.cardexplorer,
  },
  {
    path: "/cardexplorer/:library/:page",
    component: <CardExplorer />,
    key: ROUTE_KEYS.cardexplorer,
  },
  {
    path: "/carddetails",
    component: <CardDetails />,
    key: ROUTE_KEYS.carddetails,
    protectedRoute: true
  },
  {
    path: "/carddetails/:id",
    component: <CardDetails />,
    key: ROUTE_KEYS.carddetails,
    protectedRoute: true
  },
  {
    path: "/cardlanding",
    component: <CardLanding />,
    key: ROUTE_KEYS.cardlanding,
  },
  {
    path: '/signin',
    component: <SignIn />,
    key: ROUTE_KEYS.signin
  },
  {
    path: '/password',
    component: <Password />,
    key: ROUTE_KEYS.password
  },
  {
    path: '/set-password',
    component: <Password signup={true} />,
    key: ROUTE_KEYS.password
  },
  {
    path: '/signup',
    component: <SignUp />,
    key: ROUTE_KEYS.signup
  },
  {
    path: '/signout',
    component: <SignOut />,
    key: ROUTE_KEYS.signout
  },
  ...tabRoutes,
];
