import * as React from "react";

import RoundUnderstandIcon from "../../icons/RoundUnderstand";
import RoundEvaluateIcon from "../../icons/RoundEvaluate";
import RoundIdentifyIcon from "../../icons/RoundIdentify";
import RoundPrioritiseIcon from "../../icons/RoundPrioritise";
import RoundValidateIcon from "../../icons/RoundValidate";
import FooterNav from "../../components/FooterNav";
import RoundDesignIcon from "../../icons/RoundDesign";

import Paywall from '../../components/Paywall'

import Navigation from "../Navigation";
import Footer from "../Footer";
import Header from "./Header";

import "./styles.scss";

function WithTabsLayout({ children, headerContent, bgColor }) {
  return (
    <div className="main-layout">
      <Navigation />
      <div style={{ paddingTop: "85px" }}>
        <Header
          headerContent={headerContent}
          bgColor={bgColor}
          tabs={[
            {
              icon: (props) => <RoundUnderstandIcon {...props} />,
              text: "Understand",
              link: "understand",
            },
            {
              icon: (props) => <RoundEvaluateIcon {...props} />,
              text: "Evaluate",
              link: "evaluate",
            },
            {
              icon: (props) => <RoundIdentifyIcon {...props} />,
              text: "Identify",
              link: "identify",
            },
            {
              icon: (props) => <RoundPrioritiseIcon {...props} />,
              text: "Prioritise",
              link: "prioritise",
            },
            {
              icon: (props) => <RoundDesignIcon {...props} />,
              text: "Design",
              link: "design",
            },
            {
              icon: (props) => <RoundValidateIcon {...props} />,
              text: "Validate",
              link: "validate",
            },
          ]}
        />
      </div>
      <Paywall />
      <section>{children}</section>
      <FooterNav />
      <Footer />
    </div>
  );
}

export default WithTabsLayout;
