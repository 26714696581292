import React, { useContext } from "react";
import { v4 } from "uuid";
import { format } from "d3-format";
import { Link } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Carousel from "react-material-ui-carousel";

import useMediaQueries from "../../../services/media-queries";

import { CardContext } from "../../../context/CardContext";

import LeftArrowXsImg from "../assets/leftarrow-xs.png";
import LeftArrowImg from "../assets/leftarrow.png";
import RightArrowXsImg from "../assets/rightarrow-xs.png";
import RightArrowImg from "../assets/rightarrow.png";

import { LinearProgress } from "@mui/material";

import "../styles.scss";

function CardCarousel() {
  const [isMobileView] = useMediaQueries();
  const { cardRanking, callFetchRanking } = useContext(CardContext);
  const unitFormatter = format(",.1r");

  if (!cardRanking || cardRanking?.length === 0) {
    callFetchRanking(true);
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={2} sx={{ justifyContent: "center" }}>
      <Grid item xs={12} md={8}>
        <Typography
          variant="h1"
          sx={{ marginBottom: { xs: "28px", md: "40px" } }}
        >
          Carbon output comparison
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: { xs: "16px", md: "18px" } }}
        >
          With Carbon cards, designers can easily compare the carbon produced
          for<br/>any material, activity or tool they are looking to use in their
          solutions.
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={10}>
        <Carousel
          animation="slide"
          height={isMobileView ? "940px" : "540px"}
          autoPlay={false}
          navButtonsAlwaysVisible
          className="carousel-wrapper"
          sx={{
            padding: { xs: "40px 0px 0px", md: "40px 60px 0px" },
            overflow: "visible",
          }}
          NextIcon={
            <img
              src={isMobileView ? RightArrowXsImg : RightArrowImg}
              className="arrow-btn"
              alt="next"
            />
          }
          PrevIcon={
            <img
              src={isMobileView ? LeftArrowXsImg : LeftArrowImg}
              className="arrow-btn"
              alt="previous"
            />
          }
          navButtonsWrapperProps={{
            style: {
              top: isMobileView ? "-430px" : 0,
              display: isMobileView ? "none" : "block",
            },
          }}
          navButtonsProps={{
            style: {
              backgroundColor: "transparent",
              margin: isMobileView ? "30px 0px 0px" : "0px 10px",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              color: "#101010",
              display: isMobileView ? "none" : "inline-block",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#04A12B",
            },
          }}
        >
          {(() => {
            return cardRanking.map((item, i) => (
              <Grid container key={v4()} className="carousel-box">
                <Grid
                  item
                  xs={12}
                  sx={{ paddingBottom: { xs: "30px", md: 0 } }}
                >
                  <Typography
                    variant={isMobileView ? "h4xs" : "h4"}
                    sx={{
                      margin: "12px 0 40px",
                      fontWeight: 400,
                      lineHeight: "35px",
                    }}
                  >
                    {item.category && item?.category()}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    backgroundColor: { xs: "#F8F8F8", md: "#FFFFFF" },
                    borderTop: { xs: "1px solid #DEDEDE", md: "none" },
                  }}
                >
                  <Typography
                    sx={{
                      margin: { xs: "40px 0px 20px", md: "0px 0px 14px" },
                      display: "block",
                    }}
                    variant={isMobileView ? "subtitle5" : "subtitle1"}
                  >
                    Most efficient
                  </Typography>
                  <Divider
                    className="list-divider"
                    sx={{ margin: "0px 10px" }}
                  />
                  <div className="productColumn productLeftColumn">
                    {item.mostEfficient.map((product) => (
                      <div key={v4()}>
                        <Link to={`/carddetails/${product.ID}`}>
                          <Grid
                            container
                            spacing={0}
                            className="productListItem"
                          >
                            <Grid item xs={2} md={3}>
                              <img src={product.icon} />
                            </Grid>
                            <Grid item xs={10} md={9} className="productName">
                              <Typography
                                variant={
                                  isMobileView ? "subtitle5" : "subtitle1"
                                }
                                sx={{
                                  textDecoration: {
                                    xs: "none",
                                    md: "underline",
                                  },
                                }}
                              >
                                {product.Inventory}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: { xs: "#898989", md: "#383838" },
                                  fontSize: { xs: "16px", md: "18px" },
                                }}
                              >
                                {product.CarbonFootprint} kgCo2e per{" "}
                                {unitFormatter(parseFloat(product.Unitnumber))}{" "}
                                {product.Unit}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider
                            className="list-divider"
                            sx={{ marginBottom: "16px" }}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className="least-efficient-container">
                  <Typography
                    variant={isMobileView ? "subtitle5" : "subtitle1"}
                    sx={{
                      margin: { xs: "25px 0px 0px", md: "0px 0px 14px" },
                      display: "block",
                    }}
                  >
                    Least efficient
                  </Typography>
                  <div className="productColumn productRightColumn">
                    {item.leastEfficient.map((product) => (
                      <div key={v4()}>
                        <Link to={`/carddetails/${product.ID}`}>
                          <Divider
                            className="list-divider"
                            sx={{ marginBottom: "16px" }}
                          />
                          <Grid
                            container
                            spacing={0}
                            className="productListItem"
                          >
                            <Grid item xs={2} md={3}>
                              <img src={product.icon} />
                            </Grid>
                            <Grid item xs={10} md={9} className="productName">
                              <Typography
                                variant={
                                  isMobileView ? "subtitle5" : "subtitle1"
                                }
                                sx={{
                                  textDecoration: {
                                    xs: "none",
                                    md: "underline",
                                  },
                                }}
                              >
                                {product.Inventory}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: { xs: "#898989", md: "#383838" },
                                  fontSize: { xs: "16px", md: "18px" },
                                }}
                              >
                                {product.CarbonFootprint} kgCo2e per{" "}
                                {unitFormatter(parseFloat(product.Unitnumber))}{" "}
                                {product.Unit}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            ));
          })()}
        </Carousel>
      </Grid>
    </Grid>
  );
}

export default CardCarousel;
