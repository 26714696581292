import * as React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import DesignImg1 from "./assets/designImg-1.png";
import DesignImg2 from "./assets/designImg-2.png";

import WithTabsLayout from "../../components/WithTabsLayout";
import Section from "../../components/Section";
import SlideUp from "../../components/SlideUp";

import useMediaQueries from "../../services/media-queries";

import { QA_behavioural, QA_data } from "./data";
import QA from "../../components/QA";

import "./styles.scss";

function App() {
  const [isMobileView] = useMediaQueries();
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <WithTabsLayout
      bgColor="#A3BA18"
      headerContent={{
        subtitle: "Reduce",
        title: "Designing with eco-sensitivity in front of mind",
        content:
          "The carbon method allows designers to quantify emissions, identify eco-opportunities and modify the environmental impact of their product/service.",
      }}
    >
      <Section className="understand-section">
        <Grid container spacing={2} className="main-content">
          <Grid item xs={12} md={5}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Experimenting with concepts
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: { xs: "30px", md: "50px" } }}
            >
                The aim of this step is to study potential solutions which
                provides a superior experience to the targeted audience while
                considering the impact the environment. During this design phase
                a number of proof-of-concepts will be created and tested to
                ensure the product is fit for purpose. Using the carbon cards we
                can re-measure the carbon impact of our new designs with the aim
                to reduce the amount of carbon the product or service will
                produce at all stages of its lifecycle.
            </Typography>
          </Grid>
          {!isMobileView && <Grid item md={1} />}
          <Grid item xs={12} md={6}>
            <SlideUp>
              <img src={DesignImg1} />
            </SlideUp>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {!isMobileView && (
            <>
              <Grid item xs={5} align="center">
                <SlideUp>
                  <img src={DesignImg2} style={{ maxWidth: { md: "440px" } }} />
                </SlideUp>
              </Grid>
              <Grid item xs={1} />
            </>
          )}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Users, business, data and planet
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: { xs: "30px", lg: "50px" } }}
            >
                Each hypothesis is a shared concept between design, business and
                technology. These hypothesises need to be assessed from multiple
                dimensions to ensure they align with the original business
                objectives. These can be broken down into 4 key areas: business
                requirements, ecological requirements, evidence from usage data,
                and evidence from user behaviour. To ensure all of these
                dimensions are address in hypothesis testing a structured study
                to your testing needs to be carefully crafted.
            </Typography>
          </Grid>
          {isMobileView && (
            <Grid item xs={12}>
              <SlideUp>
                <img src={DesignImg2} />
              </SlideUp>
            </Grid>
          )}
        </Grid>
      </Section>
      <Section variant="dark">
        <QA behavioural={QA_behavioural} data={QA_data} />
      </Section>
    </WithTabsLayout>
  );
}

export default App;
