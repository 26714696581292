import * as React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import WithTabsAccordion from "../WithTabsAccordion";

import useMediaQueries from "../../services/media-queries";

import "./styles.scss";

function QA({ behavioural, data }) {
  const [isMobileView] = useMediaQueries();

  return (
    <Grid container spacing={2} className="questions-section">
      <Grid item sm={12} md={4} lg={3}>
        <Typography variant="h2" className="tabs-title">
          Questions to ask at this stage
        </Typography>
        {!isMobileView && (
          <Typography variant="body1" sx={{ color: "#222223" }}>
            <p>
              See how much carbon your service is using by calculating the
              impact across your entire service.
            </p>
          </Typography>
        )}
      </Grid>
      {!isMobileView && <Grid item sm={2} lg={3} />}
      <Grid item sm={12} md={6}>
        <WithTabsAccordion behavioural={behavioural} data={data} />
      </Grid>
    </Grid>
  );
}

export default QA;
