import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.077 11.957c0 5.662-4.577 10.25-10.218 10.25S1.64 17.618 1.64 11.956c0-5.663 4.577-10.25 10.218-10.25s10.218 4.587 10.218 10.25Z"
      fill={isHover ? color : "#fff"}
      stroke="#04A12B"
      strokeWidth={1.5}
    />
    <path
      d="M15.498 9.959c.293 0 .57.133.758.365l1.01 1.283a.658.658 0 0 1 .172.452v1.61c0 .158-.062.3-.164.404a1.67 1.67 0 0 0-1.51-.974c-.73 0-1.35.476-1.58 1.142h-1.866a1.676 1.676 0 0 0-1.579-1.142c-.582 0-1.095.303-1.395.765a1.662 1.662 0 0 0-1.396-.765c-.666 0-1.242.397-1.511.974a.576.576 0 0 1-.164-.403v-.286c0-.316.25-.57.559-.57h6.978v-2.427c0-.238.186-.428.418-.428h1.27Zm.109.908a.14.14 0 0 0-.109-.052h-.85v1.142h1.815l-.856-1.09Z"
      fill={isHover ? "#fff" : color}
    />
    <path
      opacity={0.4}
      d="M12.414 7.96c.462 0 .838.383.838.856v2.57a.847.847 0 0 1-.838.856H7.111a.847.847 0 0 1-.838-.856v-2.57c0-.473.375-.856.838-.856h5.303Zm-4.745.714a.282.282 0 0 0-.28.285v2.284c0 .157.126.285.28.285.154 0 .28-.128.28-.285V8.959a.282.282 0 0 0-.28-.285Zm2.512 2.569c0 .157.126.285.28.285.153 0 .278-.128.278-.285V8.959a.283.283 0 0 0-.279-.285.283.283 0 0 0-.279.285v2.284Zm-1.116-2.57a.283.283 0 0 0-.28.286v2.284c0 .157.126.285.28.285.153 0 .279-.128.279-.285V8.959a.283.283 0 0 0-.28-.285Zm2.512 2.57c0 .157.125.285.279.285.153 0 .279-.128.279-.285V8.959a.283.283 0 0 0-.28-.285.283.283 0 0 0-.278.285v2.284ZM6.832 14.81c0-.63.5-1.142 1.116-1.142a1.13 1.13 0 0 1 1.117 1.142c0 .63-.501 1.142-1.117 1.142a1.13 1.13 0 0 1-1.116-1.142Zm2.79 0c0-.63.502-1.142 1.117-1.142a1.13 1.13 0 0 1 1.117 1.142c0 .63-.5 1.142-1.117 1.142a1.13 1.13 0 0 1-1.116-1.142Zm5.025 0c0-.63.5-1.142 1.117-1.142a1.13 1.13 0 0 1 1.116 1.142c0 .63-.5 1.142-1.116 1.142a1.13 1.13 0 0 1-1.117-1.142Z"
      fill={isHover ? "#fff" : color}
    />
  </svg>
);

export default SvgComponent;
