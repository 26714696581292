import queryString from "query-string";
import md5 from "js-md5";

import supabase from "../services/db_client";
import { isOpen } from "../services/client";
import { jsonFromLocalStorage } from "../services/sessionStorage";
import { passHash } from "../const/auth";

export function hasAccess() {
  const userPass = jsonFromLocalStorage("password") || '';
  return (
    !isOpen && window.location.pathname !== "/login" && md5(userPass) !== passHash
  );
}

export async function signIn({ email, password }) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    return {error: error.toString()};
  } else {
    return data;
  }
}

export async function getSession() {
  const { data, error } = await supabase.auth.getSession();
  return { data, error };
}

export async function signOut() {
  const { data, error } = await supabase.auth.signOut();
  return { data, error };
}

export async function signUp(signUpData) {
  const { data, error } = await supabase.auth.signUp(signUpData);
  return { data, error };
}

export async function reset(email) {
  try {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "http://thecarbonmethod.com/password",
    });

    if (error) {
      return error;
    } else if (data) {
      return data;
    }
  } catch (error) {
    return error;
  }
}

export async function submitReset(password) {
  const { error, data } = await supabase.auth.updateUser({
    password,
  });

  if (error) {
    return { error };
  } else {
    return { data };
  }
}
