// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./ladder.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".thankyou-wrapper {\n  height: 70vh;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: calc(55% + 0px) 0px;\n  background-repeat: no-repeat;\n  background-size: 800px;\n}\n@media only screen and (max-width: 900px) {\n  .thankyou-wrapper {\n    min-height: 200px;\n    background-position: 80% -50px;\n    background-size: 500px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/Connect/styles.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,mDAAA;EACA,wCAAA;EACA,4BAAA;EACA,sBAAA;AAAF;AAGE;EARF;IASI,iBAAA;IACA,8BAAA;IACA,sBAAA;EAAF;AACF","sourcesContent":["\n.thankyou-wrapper {\n  height: 70vh;\n  background: url(./ladder.svg);\n  background-position: calc(55% + 00px) 0px;\n  background-repeat: no-repeat;\n  background-size: 800px;\n\n  \n  @media only screen and (max-width: 900px) {\n    min-height: 200px;\n    background-position: 80% -50px;\n    background-size: 500px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
