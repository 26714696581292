import * as React from "react";
import { Link } from "react-router-dom";

import Paper from "@mui/material/Paper";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";

import { slugify } from "../../utils/slugify";

import "./styles.scss";

function CardCategory({ card, ...props }) {
  return (
    <ListItem className="cardCategory" {...props}>
      <Link to={`/cardexplorer/${slugify(card.title)}`}>
        <Paper
          sx={{
            borderTop: `5px solid ${card.color}`,
          }}
        >
          <ListItemButton sx={{ pl: 2 }}>
            <ListItemIcon>{card.icon}</ListItemIcon>
            <ListItemText primary={card.title} />
          </ListItemButton>
          <div
            className="overlay"
            style={{ backgroundColor: card.color }}
          ></div>
        </Paper>
      </Link>
    </ListItem>
  );
}

export default CardCategory;
