import * as React from "react";

const SvgComponent = ({color= '#04A12B', isHover, ...props}) => (
    <svg
      width={31}
      height={31}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={15.086}
        cy={15.363}
        r={14.25}
        fill={isHover ? color : '#fff'}
        stroke={color}
        strokeWidth={1.5}
      />
      <path
        d="M21.756 8.849C20.291 7.38 19.19 7.602 19.19 7.602L8.19 18.6l-1.026 4.84 4.84-1.027 11-10.998s.22-1.1-1.248-2.566ZM11.716 21.83l-1.65.355a3.783 3.783 0 0 0-.702-.947c-.35-.35-.65-.543-.948-.701l.355-1.651.478-.476s.899.018 1.91 1.032c1.014 1.014 1.033 1.91 1.033 1.91l-.476.478ZM10.39 15.014l.693-.693-3.147-3.147 2.812-2.812.65.65-.877.876a.49.49 0 1 0 .693.693l.876-.876 1.198 1.197-.876.877a.49.49 0 0 0 0 .693.482.482 0 0 0 .384.136l1.792-1.792-3.21-3.209c-.347-.348-.942-.32-1.324.062l-2.81 2.812c-.383.382-.41.976-.063 1.324l3.209 3.21ZM22.999 19.223l-3.207-3.207-1.836 1.835a.48.48 0 0 0 .134.294.489.489 0 0 0 .692 0l.877-.877 1.198 1.199-.877.876a.49.49 0 1 0 .694.692l.875-.875.695.694-2.812 2.812-3.145-3.146-.693.693 3.207 3.208c.348.348.942.32 1.325-.062l2.81-2.811c.384-.383.41-.977.063-1.325Z"
        fill={isHover ? '#fff' : color}
      />
    </svg>
);

export default SvgComponent;
