import Box from "@mui/material/Box";
import * as React from "react";

import useMediaQueries from "../../services/media-queries";

function Section({ children, variant, ...props }) {
  const [isMobileView] = useMediaQueries();

  const bg = variant == "dark" ? "#F8F8F8" : "#FFFFFF";
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: isMobileView ? "0px 30px" : "80px 100px",
        background: bg,
      }}
      {...props}
    >
      <div className="center-container">{children}</div>
    </Box>
  );
}

export default Section;
