const steps = [
  {
    label: "Introduction to sustainable design",
    description: `Definition of ‘sustainability’, why is the world talking about it now? Exploring the link between economy, ecology, overconsumption and overproduction from a business and human perspective. `,
  },
  {
    label: "Understanding measurement",
    description:
      "Learning how we measure carbon in products and services. Three distinct methods (TBL, ESG and SDGs). Limitations to carbon calculations and how to apply carbon averages.",
  },
  {
    label: "Defining a carbon strategy",
    description: `Learn about the differences in types of gas, and why its important to decide which greenhouse gas to prioritise. Match the gas type with your long or short term strategies.`,
  },
  {
    label: "Circular economy and the GHG Protocol",
    description: `Understanding the three phases of the circular economy (cradle-to-gate, gate-to-gate, gate-to-grave), the three measurment scopes and how these help you to frame your Carbon strategy and help your business to target key impact areas.`,
  },
  {
    label: "Building a sustainable product or service",
    description: `Learn how to reset the priority inside your organisation to prioritise a ‘green agenda’, to grow economically and sustainably. Recognise how design can make systems, services and products that are good for everyone not just the user.`,
  },
  {
    label: "Application of the Carbon Method",
    description: `How to successfully apply the Carbon Method inside your organisation. What steps do you need to take and how to ask the right questions. Recording and reducing your carbon impact.`,
  },
  {
    label: "New sustainable innovations",
    description: `Gain inspiration from new sustainable projects that have taken a unique approach to designing new systems and services for the world that are sustainable and good for the planet.`,
  },
  {
    label: "Reductive digital design ",
    description: `Design with confidence by seeking to implement low-impact solutions that are economical, usable and meets user needs. Understand the wider context of your solutions and their environment impact.`,
  },
  {
    label: "Graduation party  🎉",
    description: `Join a community of sustainable designers and learn from a spectrum of planetary initiatives.`,
  },
];

export default steps;