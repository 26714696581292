import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.91476 0.427734C5.67939 0.427734 6.29454 1.04576 6.29454 1.80752V4.5671C6.29454 5.33173 5.67939 5.94689 4.91476 5.94689H2.15518C1.39342 5.94689 0.775391 5.33173 0.775391 4.5671V1.80752C0.775391 1.04576 1.39342 0.427734 2.15518 0.427734H4.91476ZM27.4513 1.34759C28.4689 1.34759 29.291 2.17144 29.291 3.18731C29.291 4.2049 28.4689 5.02703 27.4513 5.02703H10.8938C9.87624 5.02703 9.05412 4.2049 9.05412 3.18731C9.05412 2.17144 9.87624 1.34759 10.8938 1.34759H27.4513ZM27.4513 10.5462C28.4689 10.5462 29.291 11.3683 29.291 12.3859C29.291 13.4035 28.4689 14.2256 27.4513 14.2256H10.8938C9.87624 14.2256 9.05412 13.4035 9.05412 12.3859C9.05412 11.3683 9.87624 10.5462 10.8938 10.5462H27.4513ZM27.4513 19.7448C28.4689 19.7448 29.291 20.5669 29.291 21.5845C29.291 22.6021 28.4689 23.4242 27.4513 23.4242H10.8938C9.87624 23.4242 9.05412 22.6021 9.05412 21.5845C9.05412 20.5669 9.87624 19.7448 10.8938 19.7448H27.4513ZM0.775391 11.0061C0.775391 10.2415 1.39342 9.62632 2.15518 9.62632H4.91476C5.67939 9.62632 6.29454 10.2415 6.29454 11.0061V13.7657C6.29454 14.5303 5.67939 15.1455 4.91476 15.1455H2.15518C1.39342 15.1455 0.775391 14.5303 0.775391 13.7657V11.0061ZM4.91476 18.8249C5.67939 18.8249 6.29454 19.4401 6.29454 20.2047V22.9643C6.29454 23.7289 5.67939 24.3441 4.91476 24.3441H2.15518C1.39342 24.3441 0.775391 23.7289 0.775391 22.9643V20.2047C0.775391 19.4401 1.39342 18.8249 2.15518 18.8249H4.91476Z"
      fill="#828385"
    />
  </svg>
);

export default SvgComponent;
