import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import HeaderLarge from "../../components/HeaderLarge";
import MainLayout from "../../components/MainLayout";
import Section from "../../components/Section";
import Carousel3D from "../../components/Carousel3D";
import DownloadKit from "../../components/DownloadKit";
import useMediaQueries from "../../services/media-queries";

import HeaderAnalyticsImg from "./assets/HeaderAnalyticsImg.png";
import HeaderCalcImg from "./assets/HeaderCalcImg.png";
import HeaderImgSection2 from "./assets/homepage-section-2.png";
import HeaderImgSection3 from "./assets/homepage-section-3.png";

import "./styles.scss";


function App() {
  const [isMobileView] = useMediaQueries();
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".backdrop"),
      {
        y: 0,
        opacity: 0,
      },
      {
        y: 20,
        ease: "easeIn",
        delay: 0.2,
        duration: 1.4,
        opacity: 1,
      }
    );
    gsap.fromTo(
      element.querySelector(".front"),
      {
        y: 100,
        opacity: 0,
      },
      {
        y: -20,
        opacity: 1,
        ease: "easeIn",
        delay: 0.2,
        duration: 1,
      }
    );
    gsap.fromTo(
      element.querySelector(".section2-wrapper"),
      {
        y: 200,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        ease: "easeIn",
        scrollTrigger: {
          trigger: element.querySelector(".section2-wrapper"),
          start: "-=10% bottom",
          end: "+=500px",
        },
      }
    );
    gsap.fromTo(
      element.querySelector(".section3-animation"),
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        ease: "easeIn",
        duration: 1,
        scrollTrigger: {
          trigger: element.querySelector(".section3-wrapper"),
          start: "+=400px bottom",
          end: "+=300px",
        },
      }
    );
  }, [null]);

  return (
    <div ref={ref} className="layout">
      <MainLayout>
        <HeaderLarge
          leftColumn={
            <>
              <Typography
                variant="h1"
                sx={{
                  marginTop: { xs: "20px", md: "100px" },
                  marginBottom: "40px",
                  fontSize: { xs: "38px", sm: "50px", lg: "61px" },
                }}
              >
                A kit for
                <br />
                designers to
                <br />
                reach net-zero
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ maxWidth: { md: "503px" } }}
              >
                <p>
                  A full end-to-end design framework for designers to use on
                  everyday products and services. The Carbon Method combines
                  user and planetary pain points so designers can remove them
                  from products and services.
                </p>
                <p>
                  Putting data into the hands of designers to bridge the gap
                  between user centred and sustainable design practices.
                </p>
              </Typography>
              {!isMobileView && (
                <Link to="/method">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ marginTop: "40px", padding: '0 100px' }}
                  >
                    Learn more
                  </Button>
                </Link>
              )}
            </>
          }
          rightColumn={
            <div>
              <div className="headerSlideShow">
                <img src={HeaderAnalyticsImg} className="backdrop" />
                <img src={HeaderCalcImg} className="front" />
              </div>
              {isMobileView && (
                <Box
                  sx={{
                    margin: { xs: "160px auto 0px", md: "140px auto 0px" },
                    textAlign: "center",
                  }}
                >
                  <Link to="/method">
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ padding: '0 100px' }}
                    >
                    Learn more
                    </Button>
                  </Link>
                </Box>
              )}
            </div>
          }
        />
        <Section>
          <Typography
            variant="h1"
            align="center"
            sx={{
              maxWidth: "700px",
              margin: { xs: "40px", md: "0 auto 20px" },
            }}
          >
            Libraries of carbon data for designers
          </Typography>
          <Typography variant="subtitle1" align="center">
            Bringing carbon measurement into the hands of designers.
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} md={12} sx={{ margin: "80px 0" }} align="center">
              <Carousel3D />
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="right">
              <Link to="/cardlanding">
                <Button variant="contained" size="large">
                  Find out more
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="left">
              <Link to="/cardexplorer/digital">
                <Button variant="outlined" size="large">
                  Explore all cards
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Section>
        <Section variant="dark">
          <Typography
            variant="h1"
            align="center"
            sx={{
              maxWidth: "900px",
              margin: { xs: "40px auto 20px", md: "0 auto 20px" },
              padding: { xs: "40px 0 0", md: "0" },
            }}
          >
            A supplementary method to design sustainable services
          </Typography>
          <Typography variant="subtitle1" align="center">
            A third generation design method that supplements design practices.
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} sx={{ marginTop: "40px" }} align="center">
              <img src={HeaderImgSection2} className="section2-wrapper" style={{ maxWidth: '1100px'}}/>
            </Grid>
            <Grid item xs={12} md={3} align="center">
              <Link to="/method">
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    margin: { xs: "10px 0 40px", md: "0" },
                  }}
                >
                  See the&nbsp;<strong>Carbon Method</strong>
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Section>
        <Section
          sx={{
            padding: { xs: "50px 25px 40px", md: "150px 0px 120px 100px" },
          }}
          className="section3-wrapper"
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Grid item xs={12} md={5}>
              <Grid item xs={12} align="left">
                <Typography
                  variant="h1"
                  sx={{
                    maxWidth: "550px",
                    margin: { xs: "0 0 30px", md: "0 0 50px" },
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Sustainable design
                  <br />
                  training &amp; certification
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ maxWidth: { md: "503px" } }}
                >
                  Learn about sustainable design, the reason why the design
                  community needs to evolve and why climate change is only a
                  design decision away. Get tools, methods and processes that
                  will help you bring the sustainable narrative into your
                  organisation and learn how to bridge the gap between
                  sustainable design and business.
                </Typography>
              </Grid>
              {!isMobileView && (
                <Grid item xs={12}>
                  <HashLink to="/certification">
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ marginTop: "60px" }}
                    >
                      Register your interest
                    </Button>
                  </HashLink>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid
              item
              xs={12}
              md={6}
              align="right"
              className="section3-animation"
            >
              <Box
                sx={{
                  maxHeight: {
                    xs: "240px",
                    sm: "320px",
                    md: "600px",
                  },
                  marginRight: { xs: "-25px", md: "-180px" },
                }}
                src={HeaderImgSection3}
                component="img"
              />
            </Grid>
            {isMobileView && (
              <Grid item xs={12}>
                <HashLink to="/certification#register">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ marginTop: "60px" }}
                  >
                    Register your interest
                  </Button>
                </HashLink>
              </Grid>
            )}
          </Grid>
        </Section>
        <DownloadKit />
      </MainLayout>
    </div>
  );
}

export default App;
