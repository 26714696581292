import * as React from "react";

import useMediaQueries from "../../services/media-queries";
import Footer from "../Footer";
import Navigation from "../Navigation";
import Header from "./Header";

import "./styles.scss";

function WithTabsLayout({ children, headerContent }) {
  const [isMobileView] = useMediaQueries();

  return (
    <div className="main-layout">
      <Navigation />
      <Header
        bgColor={isMobileView ? "#FFFFFF" : "#CFEEF5"}
        headerContent={{
          subtitle: "Article | 5 min read",
          title: "Data collection process",
          content:
            "Learn how data is collect, how carbon is calculated and assumptions made to build this library.",
        }}
      />
      {children}
      <Footer />
    </div>
  );
}

export default WithTabsLayout;
