import * as React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Section from "../../components/Section";
import WithTabsLayout from "../../components/WithTabsLayout";
import SlideUp from "../../components/SlideUp";

import EvaluateImg1 from "../../assets/Measure/evaluate-1.png";
import EvaluateImg2 from "../../assets/Measure/evaluate-2.png";
import EvaluateImg3 from "../../assets/Measure/evaluate-3.png";
import useMediaQueries from "../../services/media-queries";

import { QA_behavioural, QA_data } from "./data";
import QA from "../../components/QA";

import "./styles.scss";

function App() {
  const [isMobileView] = useMediaQueries();

  return (
    <WithTabsLayout
      bgColor="#5A8BCB"
      headerContent={{
        subtitle: "Measure",
        title: "Uncovering materials in your product or service",
        content:
          "The carbon method allows designers to quantify emissions, identify eco-opportunities and modify the environmental impact of their product/service.",
      }}
    >
      <Section className="evaluate-section evaluate-xs-section">
        <Grid container>
          <Grid item xs={12} md={5}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Setting up a ‘eco-layer’
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            marginBottom: "0px",
          }}
        >
          <Grid item xs={12} md={5}>
            <Typography variant="body2" className="evaluate-paragraph">
              <p style={{ marginTop: "0px" }}>
                Based on evidence collected from the user research and surveys,
                the design team will have enough information to create an
                ‘as-is’ service blueprint. This blueprint should capture the
                actors in the service, the key phases, risks and opportunities
                in its entirety. Usually additional research is required during
                the generation of a service blueprint as gaps present
                themselves.
              </p>
              <p>
                The carbon method proposes to add an additional layer to the
                service blueprint, we call this the ‘eco-layer’ which captures
                the materials consumed by your product or service at different
                stages.
              </p>
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={12} md={6}>
            <SlideUp>
              <img src={EvaluateImg1} className="evaluate-img" />
            </SlideUp>
          </Grid>
        </Grid>
      </Section>
      <Section sx={{ padding: 0 }} className="evaluate-xs-section">
        <Grid container sx={{ padding: 0 }}>
          {!isMobileView && (
            <Grid item md={6}>
              <SlideUp>
                <img src={EvaluateImg2} className="evaluate-img" />
              </SlideUp>
            </Grid>
          )}
          <Grid item xs={1} />
          <Grid item xs={12} md={4}>
            <Typography
              variant={isMobileView ? "h4xs" : "h4"}
              sx={{ paddingTop: "30px" }}
            >
              Map materials to the service
            </Typography>
            <Typography variant="body2" className="evaluate-paragraph">
              Once the as-is service blueprint is complete and the carbon
              producing materials are identified in the service, the design team
              can calculate the total carbon produced from the existing service.
              This will give the team a new dimension to determine where the
              areas for improvements are and the eco-impact of decisions are.
            </Typography>
          </Grid>
        </Grid>
      </Section>
      {isMobileView && (
        <SlideUp>
          <img src={EvaluateImg2} className="evaluate-img" />
        </SlideUp>
      )}
      <Section className="evaluate-xs-section">
        <Grid container>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Calculating your Co2 burden
            </Typography>
            <Typography variant="body2" className="evaluate-paragraph">
              Data is very important at this stage, if your company is
              collecting user data or carbon data then add it here to generate a
              more accurate calculation. Enter your units into the cards to get
              a real-time calculation e.g. number of hours a computer is used
              multiplied by the number of computers.
            </Typography>
          </Grid>
          {!isMobileView && <Grid item xs={2} />}
          <Grid item xs={12} md={5}>
            <SlideUp>
              <img
                src={EvaluateImg3}
                className="evaluate-img"
                style={{ marginTop: "24px" }}
              />
            </SlideUp>
          </Grid>
        </Grid>
      </Section>
      <Section variant="dark">
        <QA behavioural={QA_behavioural} data={QA_data} />
      </Section>
    </WithTabsLayout>
  );
}

export default App;
