import * as React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import TabPanel from "./TabPanel";

import CollapseIcon from "../../assets/icons/Collapse";
import ExpandIcon from "../../assets/icons/Expand";

import useMediaQueries from "../../services/media-queries";

import "./styles.scss";

function WithTabsAccordion({ behavioural, data }) {
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [isMobileView] = useMediaQueries();

  const getPanelIcon = (id) =>
    expanded === id ? <CollapseIcon /> : <ExpandIcon />;

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box className="tabs-container">
        <Tabs value={value} onChange={handleChangeTab} variant="fullWidth">
          <Tab
            disableRipple
            label={isMobileView ? "Behavioural" : "Behavioural questions"}
          />
          <Tab disableRipple label={isMobileView ? "Data" : "Data questions"} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {behavioural.map((item, i) => {
          return (
            <Accordion
              expanded={expanded === `panel${i}`}
              onChange={handleChange(`panel${i}`)}
            >
              <AccordionSummary
                aria-controls={`panel${i}d-content`}
                id={`panel${i}d-header`}
                expandIcon={getPanelIcon(`panel${i}`)}
              >
                <Typography
                  sx={{ fontWeight: expanded === `panel${i}` ? 500 : 400 }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.content}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {data.map((item, i) => {
          return (
            <Accordion
              expanded={expanded === `panel${i + behavioural.length}`}
              onChange={handleChange(`panel${i + behavioural.length}`)}
            >
              <AccordionSummary
                aria-controls={`panel${i + behavioural.length}d-content`}
                id={`panel${i + behavioural.length}d-header`}
                expandIcon={getPanelIcon(`panel${i}`)}
              >
                <Typography
                  sx={{
                    fontWeight:
                      expanded === `panel${i + behavioural.length}` ? 500 : 400,
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.content}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </TabPanel>
    </Box>
  );
}

export default WithTabsAccordion;
