import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import ChevronLeft from "../../icons/ChevronLeft";

import useMediaQueries from "../../services/media-queries";

import "./styles.scss";

function Header({ headerContent, bgColor }) {
  const [isMobileView] = useMediaQueries();

  return (
    <Box
      sx={{
        flexGrow: 1,
        background: bgColor,
        marginTop: "85px",
        padding: {xs: "0 0px", md: '0 100px'},
      }}
    >
      <div className="center-container">
        <Grid
          container
          spacing={2}
          sx={{
            padding: isMobileView ? "0px 30px" : "0",
            marginTop: "0px",
            marginLeft: isMobileView ? "0px" : "-16px",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={4} className="return-btn">
            <Link href="/cardexplorer">
              <Typography variant="h6">
                <ChevronLeft style={{position: 'relative', top: '1px', paddingRight: '10px'}} /> {isMobileView ? "Back" : "Return to card selection"}
              </Typography>
            </Link>
            {/* {!isMobileView && (
              <Button variant="contained" size="large" className="share-button">
                Share:
                <FacebookIcon sx={{ fill: "black", marginLeft: "140px" }} />
                <TwitterIcon sx={{ fill: "black" }} />
                <LinkedInIcon sx={{ fill: "black" }} />
              </Button>
            )} */}
          </Grid>
          <Grid
            container
            sm={8}
            sx={{
              flex: 1,
              textAlign: "left",
              padding: isMobileView ? "40px 0px" : "40px 15px 60px",
            }}
          >
            {!isMobileView && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {headerContent.subtitle}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} className="header-title">
              <Typography variant="h1">{headerContent.title}</Typography>
            </Grid>
            {isMobileView && (
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: isMobileView ? "16px" : "0px" }}
                >
                  {headerContent.subtitle}
                </Typography>
              </Grid>
            )}
            <Grid item sm={8}>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: isMobileView ? "16px" : "18px" }}
              >
                {headerContent.content}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Header;
