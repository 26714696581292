import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.061 11.178c0 5.663-4.576 10.25-10.218 10.25-5.641 0-10.218-4.587-10.218-10.25S6.202.928 11.843.928c5.642 0 10.218 4.587 10.218 10.25Z"
      fill={isHover ? color : "#fff"}
      stroke={color}
      strokeWidth={1.5}
    />
    <g clipPath="url(#a)">
      <path
        d="m13.768 8.54 2.985.456a.67.67 0 0 1 .534.463c.078.25.014.52-.165.705l-2.164 2.196.511 3.149a.696.696 0 0 1-.268.672.647.647 0 0 1-.7.05l-2.666-1.472L9.17 16.23a.65.65 0 0 1-.702-.049.695.695 0 0 1-.266-.672l.511-3.15-2.164-2.195a.707.707 0 0 1-.164-.704.667.667 0 0 1 .534-.465l2.982-.455 1.338-2.843a.66.66 0 0 1 .596-.386c.255 0 .488.15.598.386l1.335 2.843Z"
        fill={isHover ? "#fff" : color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={isHover ? "#fff" : color}
          transform="translate(6.352 5.31)"
          d="M0 0h10.968v11H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
