export const QA_behavioural = [
  {
    title: "How will you define the value you will bring to the user?",
    content:
      "Prioritisation sessions need to be fair and holistic, unless you have the right people in the room this won’t be possible. Aim to bring users of the product, service managers and engineers together to have an open dialogue.",
  },
  {
    title: "How will you represent the planet in a prioritisation session?",
    content:
      "Have one person in the room who is responsible for understanding the carbon data and able to raise planetary concerns and opportunities. Ensure everyone has an opportunity to speak and vote on OKRs.",
  },
];

export const QA_data = [
  {
    title: "How to quickly map the Co2 impact to the prioritisation board?",
    content:
      "First you need to make sure all your OKRs have carbon impact calculations on them. Then you can naturally map vertical axis by showing the lowest impact at the bottom and highest impact at the top. As the vertical axis is a quantifiable metric, it’s not open to interpretation.",
  },
  {
    title: "Highlight OKRs which align with business objectives?",
    content:
      "Consider highlighting OKRs which are aligned to global objectives (or global quantifiable goals). Although they may not be important to the project, they could be strategically important from an overall business perspective.",
  },
];
