import * as React from "react";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";

import Section from "../../components/Section";
import MainLayout from "../../components/MainLayout";
import DownloadKit from "../../components/DownloadKit";
import SlideUp from "../../components/SlideUp";

import RoundUnderstandIcon from "../../icons/RoundUnderstand";
import RoundEvaluateIcon from "../../icons/RoundEvaluate";
import RoundIdentifyIcon from "../../icons/RoundIdentify";
import RoundPrioritiseIcon from "../../icons/RoundPrioritise";
import RoundValidateIcon from "../../icons/RoundValidate";
import RoundDesignIcon from "../../icons/RoundDesign";

import IconLink from "../../components/IconLink";

import useMediaQueries from "../../services/media-queries";

import Section1Img from "./assets/section-1.png";
import Section2Img from "./assets/section-2.png";
import Section3Img from "./assets/section-3.png";

import HeaderZero from "./HeaderZero";
import HeaderZeroMobile from "./HeaderZeroMobile";

import "./styles.scss";

function Method() {
  const [isMobileView] = useMediaQueries();

  return (
    <MainLayout>
      <Section className="method-header">
        <Grid container>
          <Grid
            item
            xs={2}
            sx={{ display: { xs: "block", md: "none", lg: "block" } }}
          />
          <Grid item align="center" xs={8} md={12} lg={8}>
            <Typography variant="h1">
              A net-zero method to design sustainable services
            </Typography>
            <Typography variant="subtitle1" sx={{ margin: { md: "20px 0 0" } }}>
              A three phase process to creating a sustainable service.
            </Typography>
            <Box className="theZero">
              {isMobileView ? <HeaderZeroMobile /> : <HeaderZero />}
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={4} className="sectionAlternate">
          <Grid item xs={12} lg={3}>
            <Typography variant="h2" sx={{ margin: "0px 0 15px" }}>
              Measure
            </Typography>
            <Typography variant="body1">
              See how much carbon your service is using by calculating the
              impact across your entire service.
            </Typography>
            <Typography
              variant="subtitle3"
              sx={{ padding: "25px 0 10px", display: "block" }}
            >
              Stages:
            </Typography>
            <List className="stageList">
              <ListItem>
                <IconLink
                  linkIcon={RoundUnderstandIcon}
                  link="/understand"
                  text="Understand"
                />
              </ListItem>
              <ListItem>
                <IconLink
                  linkIcon={RoundEvaluateIcon}
                  link="/evaluate"
                  text="Evaluate"
                />
              </ListItem>
            </List>
            {isMobileView && (
              <SlideUp>
                <Box
                  src={Section1Img}
                  component="img"
                  sx={{
                    maxWidth: "100%",
                    margin: { xs: "20px 0", md: "0px" },
                  }}
                />
              </SlideUp>
            )}
            <Link to="/understand">
              <Button
                variant="contained"
                size="large"
                sx={{ marginTop: "25px" }}
              >
                Learn about&nbsp;<strong>Measure</strong>
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} lg={9} align="center">
            {!isMobileView && (
              <SlideUp>
                <Box
                  src={Section1Img}
                  component="img"
                  style={{ maxWidth: "100%" }}
                />
              </SlideUp>
            )}
          </Grid>
        </Grid>
      </Section>
      <Section variant="dark">
        <Grid container spacing={4} className="sectionAlternate reverse">
          <Grid item xs={12} lg={8}>
            {!isMobileView && (
              <SlideUp>
                <Box
                  src={Section2Img}
                  style={{ maxWidth: "100%" }}
                  component="img"
                />
              </SlideUp>
            )}
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="h2" sx={{ margin: "0px 0 15px" }}>
              Track
            </Typography>
            <Typography variant="body1">
              Learn simple fixes to cut carbon, select areas with the most
              impact to focus on and prioritise.
            </Typography>
            <Typography
              variant="subtitle3"
              sx={{ padding: "25px 0 10px", display: "block" }}
            >
              Stages:
            </Typography>
            <List className="stageList">
              <ListItem>
                <IconLink
                  linkIcon={RoundIdentifyIcon}
                  link="/identify"
                  text="Identify"
                />
              </ListItem>
              <ListItem>
                <IconLink
                  linkIcon={RoundPrioritiseIcon}
                  link="/prioritise"
                  text="Prioritise"
                />
              </ListItem>
            </List>
            {isMobileView && (
              <SlideUp>
                <Box
                  src={Section2Img}
                  sx={{
                    maxWidth: "100%",
                    margin: { xs: "20px 0", md: "0px" },
                  }}
                  component="img"
                />
              </SlideUp>
            )}
            <Link to="/identify">
              <Button
                variant="contained"
                size="large"
                sx={{ marginTop: "25px" }}
              >
                Learn about&nbsp;<strong>Track</strong>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={4} className="sectionAlternate">
          <Grid item xs={12} lg={3}>
            <Typography variant="h2" sx={{ margin: "0px 0 15px" }}>
              Reduce
            </Typography>
            <Typography variant="body1">
              Bring your business closer to net zero by comparing the As-is vs
              the To-be service. Compare your carbon impact and calulate your
              reduction.
            </Typography>
            <Typography
              variant="subtitle3"
              sx={{ padding: "25px 0 10px", display: "block" }}
            >
              Stages:
            </Typography>
            <List className="stageList">
              <ListItem>
                <IconLink
                  linkIcon={RoundDesignIcon}
                  link="/design"
                  text="Design"
                />
              </ListItem>
              <ListItem>
                <IconLink
                  linkIcon={RoundValidateIcon}
                  link="/validate"
                  text="Validate"
                />
              </ListItem>
            </List>
            {isMobileView && (
              <SlideUp>
                <Box
                  src={Section3Img}
                  style={{ maxWidth: "100%" }}
                  sx={{
                    maxWidth: "100%",
                    margin: { xs: "20px 0", md: "0px" },
                  }}
                  component="img"
                />
              </SlideUp>
            )}
            <Link to="/design">
              <Button
                variant="contained"
                size="large"
                sx={{ marginTop: "25px" }}
              >
                Learn about&nbsp;<strong>Reduce</strong>
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} lg={9} align="center">
            {!isMobileView && (
              <SlideUp>
                <Box
                  src={Section3Img}
                  style={{ maxWidth: "100%" }}
                  sx={{
                    maxWidth: "100%",
                  }}
                  component="img"
                />
              </SlideUp>
            )}
          </Grid>
        </Grid>
      </Section>
      <Section variant="dark">
        <DownloadKit />
      </Section>
    </MainLayout>
  );
}

export default Method;
