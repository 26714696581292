import React, { createContext, useState, useEffect } from "react";

import {
  jsonFromLocalStorage,
  jsonToLocalStorage,
} from "../services/sessionStorage";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUserData] = useState(
    jsonFromLocalStorage("user") || null
  );
  const [cookieAgreement, setCookieAgreement] = useState(
    jsonFromLocalStorage("cookie-agreement") || false
  );

  const updateUserData = (data) => setUserData(data);
  const updateCookieAgreement = (data) => {
    setCookieAgreement(data)
    jsonToLocalStorage("cookie-agreement", data)
  }

  useEffect(() => {
    jsonToLocalStorage('user', user)
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        updateUserData,
        cookieAgreement,
        updateCookieAgreement
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
