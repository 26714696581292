import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import find from "lodash/find";
import orderBy from "lodash/orderBy";

import ReactCountryFlag from "react-country-flag";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { format } from "d3-format";
import getCountryISO2 from "country-iso-3-to-2";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import useMediaQueries from "../../../services/media-queries";

import { CountryContext } from "../../../context/CountryContext";
import { getCardsByLibrary } from "../../../api/card";

import geoData from "../geo-data.json";

import "./styles.scss";

const EXCLUDED_COUNTRIES = [
  "Democratic Republic of Congo",
  "French Southern Territories",
  "French Guiana",
  "West Bank",
];

function Map() {
  const { selectedCountry, countries, updateSelectedCountry } =
    useContext(CountryContext);
  const [country, setCountry] = useState("Most efficient");
  const [countryData, setCountryData] = useState();
  const [cardList, setCardList] = useState(null);
  const [isMobileView] = useMediaQueries();
  const navigate = useNavigate();
  const library = "Energy";

  const carbonFormatter = format(",.2r");

  const isCountryDetailsBoxActive =
    country !== "Most efficient" && country !== "Least efficient";

  function getCarbonGigawatt(carbonfootprint) {
    return carbonFormatter(1000000 * carbonfootprint);
  }

  useEffect(() => {
    if (isCountryDetailsBoxActive && country) {
      setCountryData(find(countries, { Country: country }));
    }
  }, [country]);

  useEffect(() => {
    setCardList(null);
    getCardsByLibrary(library).then((data) => {
      if (data) {
        setCardList(data);
      }
    });
  }, [library, selectedCountry]);

  function getCardDetailsCountrEnergy() {
    const cardId = find(cardList, { Inventory: country }).ID;
    return `/carddetails/${cardId}`;
  }

  const handleChange = ({ target }) => {
    updateSelectedCountry(target.value);
    setCountry(target.value);
  };

  return (
    <Grid container spacing={2}>
      {!isMobileView && <Grid item md={1} />}
      <Grid item xs={12} md={10}>
        <Typography variant="h1" style={{ marginBottom: "40px" }}>
          Energy production efficiency
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ marginBottom: { xs: "20px", md: "78px" } }}
        >
          See the energy effciency of countries in terms of how much
          <br />
          Carbon is created per kWh of energy generated
        </Typography>
        <Grid
          container
          spacing={2}
          onMouseLeave={() => {
            setCountry("Most efficient");
          }}
        >
          <Grid item xs={12} md={4}>
            <FormControl sx={{ width: "100%", marginTop: "15px" }}>
              <Select
                value={country}
                onChange={handleChange}
                IconComponent={ExpandMoreIcon}
                className="select-country-input"
                MenuProps={{
                    style: {zIndex: 10000}
                }}
              >
                <MenuItem key="Most efficient" value="Most efficient">
                  Most efficient
                </MenuItem>
                <MenuItem key="Least efficient" value="Least efficient">
                  Least efficient
                </MenuItem>
                <Divider />
                {countries &&
                  orderBy(countries, ["Country"], "asc").map((country) => (
                    <MenuItem key={country.Country} value={country.Country}>
                      {country.Country}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {(country === "Most efficient" || country === "Least efficient") &&
              countries && (
                <>
                  {countries
                    .sort((a, b) =>
                      country === "Most efficient"
                        ? a.Carbonfootprint - b.Carbonfootprint
                        : b.Carbonfootprint - a.Carbonfootprint
                    )
                    .slice(0, 3)
                    .map((currentCountry, i) => (
                      <Box sx={{ display: "flex", paddingTop: "34px" }} key={i}>
                        <div className="country-list-box">
                          <Grid container>
                            <Grid
                              item
                              xs={4}
                              align="left"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <ReactCountryFlag
                                className="list-flag"
                                countryCode={getCountryISO2(
                                  currentCountry.Countrycode
                                )}
                                svg
                                style={{
                                  width: "90px",
                                  height: "auto",
                                  maxWidth: "80%",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: { xs: "18px", md: "22px" },
                                  lineHeight: "42px",
                                }}
                              >
                                {currentCountry.Country}{" "}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "14px", lineHeight: "26px" }}
                              >
                                {getCarbonGigawatt(
                                  currentCountry.Carbonfootprint
                                )}&nbsp;
                                kgCo2e per Gigawatt-hour
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                    ))}
                  <Box sx={{ display: "flex", paddingTop: "84px" }}>
                    <Button
                      variant="contained"
                      size="large"
                      className="carbon-cards-btn"
                      onClick={() => {
                        navigate("/cardexplorer/energy");
                      }}
                    >
                      Learn more
                    </Button>
                  </Box>
                </>
              )}
            {isCountryDetailsBoxActive && countryData && (
              <>
                <Box className="country-details-box">
                  <ReactCountryFlag
                    countryCode={getCountryISO2(countryData.Countrycode)}
                    svg
                    style={{
                      width: "140px",
                      height: "100px",
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: "24px", marginTop: "10px" }}
                  >
                    {countryData.Country}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      marginTop: "15px",
                    }}
                  >
                    produces
                  </Typography>
                  <Grid container>
                    <Grid item xs={8} align="right">
                      <Typography
                        variant="h1"
                        className="country-details-box-value"
                      >
                        {getCarbonGigawatt(countryData.Carbonfootprint)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      align="left"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#383838",
                          paddingLeft: "10px",
                          lineHeight: "20px",
                        }}
                      >
                        kg/
                        <br />
                        Co2e
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body1"
                    sx={{ color: "#383838", fontWeight: 600, fontSize: "14px" }}
                  >
                    per Gigawatt-hour
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: "40px 0px 20px",
                    backgroundColor: isCountryDetailsBoxActive
                      ? "#F8F8F8"
                      : "#FFFFFF",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className="carbon-cards-btn"
                    onClick={() => {
                      updateSelectedCountry(country);
                      navigate(getCardDetailsCountrEnergy());
                    }}
                  >
                    Learn more
                  </Button>
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: "20px",
                      color: "#808080",
                      fontSize: "14px",
                    }}
                  >
                    1 GW/h = 1,000,000 KW/h
                  </Typography>
                </Box>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={8}>
            <div data-tip="">
              <ComposableMap
                projection={"geoMercator"}
                height={900}
                width={1000}
              >
                <Geographies
                  geography={geoData}
                  className={country === "Most efficient" && "blurredMap"}
                >
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          onMouseEnter={() => {
                            const { name } = geo.properties;
                            if (EXCLUDED_COUNTRIES.indexOf(name) === -1) {
                              setCountry(geo.properties.name);
                            }
                          }}
                          onClick={() => {
                            updateSelectedCountry(country);
                            navigate(getCardDetailsCountrEnergy());
                          }}
                          style={{
                            default: {
                              fill:
                                countryData &&
                                geo.id === countryData.Countrycode
                                  ? "#17A874"
                                  : "#4B4B4B",
                            },
                            hover: {
                              fill: "#17A874",
                            },
                          }}
                        />
                      );
                    })
                  }
                </Geographies>
              </ComposableMap>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Map;
