import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2188 0.684082L9.01367 8.88916L0.808591 0.684081"
      stroke="#222223"
      stroke-width="1.10871"
      stroke-linecap="round"
    />
  </svg>
);

export default SvgComponent;
