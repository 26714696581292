import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandIcon from "../../../assets/icons/Expand";

import useMediaQueries from "../../../services/media-queries";
import { getCardReferences } from "../../../api/references";

import "../styles.scss";

function CardDetails() {
  const [expanded, setExpanded] = React.useState(false);
  const [isMobileView] = useMediaQueries();
  const [referenceData, setReferenceData] = useState({});
  let { id } = useParams();

  useEffect(() => {
    getCardReferences(id).then((data) => {
      setReferenceData(data);
    });
  }, []);

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (referenceData) {
    const {
      Source = "",
      Dataset = "",
      Referencename = "",
      Information = "",
      Link = "",
      Calculation = "",
      Assumptions = "",
    } = referenceData;

    return (
      <Box
        sx={{
          padding: { xs: "0px 30px", md: "0 0px" },
          backgroundColor: { xs: "#F8F8F8", md: "#FFFFFF" },
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} lg={4} />
          <Grid item xs={12} md={5} lg={7} className="references-box">
            {!isMobileView && <p className="references-title">References</p>}
            {isMobileView && <Typography variant="h2">References</Typography>}
            {isMobileView ? (
              <div className="card-details-references">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChangePanel("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ExpandIcon />}
                  >
                    <Typography>{Source}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Referencename && (
                      <>
                        <p className="reference-subtitle">
                          <b>Name</b>
                        </p>
                        <Typography
                          variant="body1"
                          sx={{ lineHeight: "21px", marginTop: "4px" }}
                        >
                          {Referencename}
                        </Typography>
                      </>
                    )}
                    {Information && (
                      <>
                        <p
                          className="reference-subtitle"
                          style={{ marginTop: "20px" }}
                        >
                          <b>Information</b>
                        </p>
                        <Typography
                          variant="body1"
                          sx={{ lineHeight: "21px", marginTop: "4px" }}
                        >
                          {Information}
                        </Typography>
                      </>
                    )}
                    {Link && Link.indexOf("http") > -1 ? (
                      <a
                        href={Link}
                        target="_blank"
                        className="emission-link"
                        style={{ display: "block" }}
                      >
                        See reference material &rsaquo;
                      </a>
                    ) : (
                      Link && (
                        <Typography
                          variant="body1"
                          sx={{ lineHeight: "21px", marginTop: "4px" }}
                        >
                          {Link}
                        </Typography>
                      )
                    )}
                    {Calculation && (
                      <>
                        <p className="reference-subtitle">
                          <b>Calculation</b>
                        </p>
                        <Typography
                          variant="body1"
                          sx={{ lineHeight: "21px", marginTop: "4px" }}
                        >
                          {Calculation}
                        </Typography>
                      </>
                    )}
                    {Assumptions && (
                      <>
                        <p className="reference-subtitle">
                          <b>Assumptions</b>
                        </p>
                        <Typography
                          variant="body1"
                          sx={{ lineHeight: "21px", marginTop: "4px" }}
                        >
                          {Assumptions}
                        </Typography>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <Grid container className="references-container gray-bg">
                <Grid item xs={4} className="reference-title-box">
                  <p className="reference-title">{Source}</p>
                  <p className="reference-subtitle">{Dataset}</p>
                </Grid>
                <Grid item xs={8} className="reference-desc">
                  {Referencename && (
                    <>
                      <p className="reference-subtitle">
                        <b>Name</b>
                      </p>
                      <Typography
                        variant="body1"
                        sx={{ lineHeight: "21px", marginTop: "4px" }}
                      >
                        {Referencename}
                      </Typography>
                    </>
                  )}
                  {Information && (
                    <>
                      <p
                        className="reference-subtitle"
                        style={{ marginTop: "20px" }}
                      >
                        <b>Information</b>
                      </p>
                      <Typography
                        variant="body1"
                        sx={{ lineHeight: "21px", marginTop: "4px" }}
                      >
                        {Information}
                      </Typography>
                    </>
                  )}
                  {Link && Link.indexOf("http") > -1 ? (
                    <a
                      href={Link}
                      target="_blank"
                      className="emission-link"
                      style={{ display: "block" }}
                    >
                      See reference material &rsaquo;
                    </a>
                  ) : (
                    Link && (
                      <Typography
                        variant="body1"
                        sx={{ lineHeight: "21px", marginTop: "4px" }}
                      >
                        {Link}
                      </Typography>
                    )
                  )}
                  {Calculation && (
                    <>
                      <p className="reference-subtitle">
                        <b>Calculation</b>
                      </p>
                      <Typography
                        variant="body1"
                        sx={{ lineHeight: "21px", marginTop: "4px" }}
                      >
                        {Calculation}
                      </Typography>
                    </>
                  )}
                  {Assumptions && (
                    <>
                      <p className="reference-subtitle">
                        <b>Assumptions</b>
                      </p>
                      <Typography
                        variant="body1"
                        sx={{ lineHeight: "21px", marginTop: "4px" }}
                      >
                        {Assumptions}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default CardDetails;
