import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.78 11.643c0 5.662-4.577 10.25-10.218 10.25S1.344 17.305 1.344 11.643c0-5.663 4.577-10.25 10.218-10.25S21.78 5.98 21.78 11.643Z"
      fill={isHover ? color : "#fff"}
      stroke="#04A12B"
      strokeWidth={1.5}
    />
    <path
      d="M8.889 11.824v-1.069l3.438-1.989c.22-.127.5-.05.624.172a.465.465 0 0 1-.17.632L8.89 11.824Zm3.665 0v-1.069l3.438-1.989c.22-.127.5-.05.625.172a.465.465 0 0 1-.17.632l-3.893 2.254Zm-3.207.9c.168 0 .305.14.305.31v.928c0 .17-.137.31-.305.31H8.43a.308.308 0 0 1-.305-.31v-.928c0-.17.137-.31.305-.31h.917Zm2.444 0c.168 0 .305.14.305.31v.928c0 .17-.137.31-.306.31h-.916a.308.308 0 0 1-.305-.31v-.928c0-.17.137-.31.305-.31h.916Zm1.221.31c0-.17.138-.31.306-.31h.916c.168 0 .306.14.306.31v.928c0 .17-.138.31-.306.31h-.916a.308.308 0 0 1-.306-.31v-.928Z"
      fill={isHover ? "#fff" : color}
    />
    <path
      opacity={0.4}
      d="M6.445 7.931c0-.342.274-.619.611-.619h1.222c.338 0 .611.277.611.619v3.893l3.665-2.122v2.122l3.666-2.122v5.342c0 .513-.41.928-.916.928H7.361a.922.922 0 0 1-.917-.928V7.931Zm1.986 4.794a.308.308 0 0 0-.306.31v.927c0 .17.138.31.306.31h.916c.168 0 .306-.14.306-.31v-.928c0-.17-.138-.31-.306-.31h-.916Zm2.443 0a.308.308 0 0 0-.305.31v.927c0 .17.137.31.305.31h.917c.168 0 .305-.14.305-.31v-.928c0-.17-.137-.31-.305-.31h-.917Zm2.139 1.237c0 .17.137.31.305.31h.916c.168 0 .306-.14.306-.31v-.928c0-.17-.138-.31-.306-.31h-.916a.308.308 0 0 0-.305.31v.928Z"
      fill={isHover ? "#fff" : color}
    />
  </svg>
);

export default SvgComponent;
