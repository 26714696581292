import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="15" cy="15" r="15" fill="#F3F5F6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8675 14.2678L14.1389 14.2676H8.95312V15.9962H14.1389L15.8675 16V15.9962H21.0533V14.2676L15.8675 14.2678Z"
      fill="black"
    />
  </svg>
);

export default SvgComponent;
