import supabase from "../services/db_client";
import { v4 as uuidv4 } from "uuid";

async function addUser(userData, callback) {
  const dataToSend = { ...userData, timestamp: new Date() };
  return await supabase
    .from("users")
    .insert(
      { ID: uuidv4(), ...dataToSend},
      {
        returning: "minimal",
      }
    )
    .then((data, error) => {
      callback(data, error)
    });
}

async function readUserPermissionByEmail(email) {
  return await supabase
    .from("users")
    .select()
    .filter("email", "in", `(${email})`)
    .then((data, error) => {
      console.log(data, error)
    });
}

export { addUser, readUserPermissionByEmail };
