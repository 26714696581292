import React from "react";

import amazon from "./icons/amazon.png";
import apple from "./icons/apple.png";
import bbc from "./icons/bbc.png";
import disney from "./icons/disney.png";
import netflix from "./icons/netflix.png";
import yt from "./icons/yt.png";

import teams from "./icons/teams.png";
import zoom from "./icons/zoom.png";
import google from "./icons/google.png";
import hangouts from "./icons/hangouts.png";
import slack from "./icons/slack.png";
import telephone from "./icons/telephone.png";

import playstation from "./icons/playstation.png";
import xbox from "./icons/xbox.png";
import nintendo from "./icons/nintendo.png";
import laptop from "./icons/laptop.png";
import smartphone from "./icons/smartphone.png";
import print from "./icons/print.png";

export const streamingPreselect = [
  {
    name: "Netflix HD",
    icon: netflix,
  },
  {
    name: "Amazon HD",
    icon: amazon,
  },
  {
    name: "Apple TV+ 1080p",
    icon: apple,
  },
  {
    name: "Disney+ HD",
    icon: disney,
  },
  {
    name: "Youtube HD 1080p",
    icon: yt,
  },
  {
    name: "BBC iPlayer HD",
    icon: bbc,
  },
];

export const communicationPreselect = [
  {
    name: "Microsoft teams group video call 720p",
    icon: teams,
  },
  {
    name: "Zoom group video 720p",
    icon: zoom,
  },
  {
    name: "Google Meet group video call 720",
    icon: google,
  },
  {
    name: "Google Hangout group video call 720p",
    icon: hangouts,
  },
  {
    name: "Slack group video call 720p",
    icon: slack,
  },
  {
    name: "Telephone call",
    icon: telephone,
  },
];

export const devicePreselect = [
  {
    name: "Playstation 4",
    icon: playstation,
  },
  {
    name: "Xbox One",
    icon: xbox,
  },
  {
    name: "Nintendo Switch",
    icon: nintendo,
  },
  {
    name: "Laptop",
    icon: laptop,
  },
  {
    name: "Smartphone",
    icon: smartphone,
  },
  {
    name: "Sheet of paper",
    icon: print,
  },
];
