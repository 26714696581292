import React, { useEffect, useRef } from "react";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function SlideUp({ children }) {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".slider"),
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        ease: "easeIn",
        delay: 0,
        duration: 1,
        opacity: 1,
        scrollTrigger: {
          trigger: element.querySelector(".slider"),
          start: "+=100px bottom",
          end: "+=500px",
        },
      }
    );
  }, [null]);

  return (
    <div ref={ref}>
      <div className="slider">{children}</div>
    </div>
  );
}

export default SlideUp;
