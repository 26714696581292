import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.415 13.396H46.84v26.715c0 3.684-2.956 6.68-6.592 6.68H7.287c-3.64 0-6.592-2.996-6.592-6.68V13.398H22.12V.038h3.296v13.357Z"
      fill="#383838"
    />
    <path
      opacity={0.4}
      d="M22.12.04v13.357H.694l5.226-10.59A4.938 4.938 0 0 1 10.343.04H22.12Zm15.068 0a4.954 4.954 0 0 1 4.43 2.768l5.221 10.588H25.415V.04h11.773Z"
      fill="#383838"
    />
  </svg>
)

export default SvgComponent
