import React, { useContext } from "react";
import { isNull } from "lodash";
import {
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { v4 } from "uuid";

import { jsonFromLocalStorage } from "./services/sessionStorage";
import { menuRoutes } from "./routes";
import { hasAccess } from "./api/auth";

import { AuthContext } from "./context/AuthContext";

import SignIn from "./pages/SignIn";

import "./App.css";

function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  const matchedRoutes = matchRoutes([...menuRoutes], location.pathname);
  const { authData } = useContext(AuthContext);

  const userPass = jsonFromLocalStorage("password") || "";
  if (hasAccess()) {
    setTimeout(() => navigate("/login"), 1);
    return null;
  }

  const isAuthed = authData?.user;

  return (
    <Routes>
      {[...menuRoutes].map(
        ({ key, path, component, nestedRoutes, protectedRoute }) => (
          <Route
            key={v4()}
            path={path}
            element={
              protectedRoute ? isAuthed ? component : <SignIn /> : component
            }
          />
        )
      )}
      {isNull(matchedRoutes) && (
        <Route
          key={v4()}
          path={location.pathname}
          element={<Navigate to="/" />}
        />
      )}
    </Routes>
  );
}

export default Router;
