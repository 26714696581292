export const QA_behavioural = [
  {
    title:
      "Where are the main problems users are facing and what is the planetary impact?",
    content:
      "Once the service map is complete, you should be able to quickly see where the main problems exist for users. Determine what the planetary impact is for these and take note to improve these at the design stage.",
  },
  {
    title:
      "Where is the largest carbon impact and how does it affect our users?",
    content:
      "Once the service map is complete, you should be able to quickly see where the main problems exist for the planet. Take note of these to see how to remove the emissions at a the design stage.",
  },
  {
    title:
      "Which technologies and activities have the greatest planetary impact?",
    content:
      "Consider the impact of the back-stage, how much of a planetary impact are you making by operating the service. Consider separating the front-stage calculation from the back-stage calculation so you can focus segment you OKR’s and measurements for improvement.",
  },
  {
    title: "Are the problems we found connected?",
    content:
      "Using the problem statement template aim to capture the entirety of the problem. In doing so you may realise the problems are connected. In these cases combine problems at a higher level to capture them holistically.",
  },
  {
    title: "Set objectives or leverage global standards?",
    content: (
      <>
        Many organisations are making the commitment to their customers and
        shareholders to become net-zero by 2050. Check if your company has made
        a commitment to change and by when. If they do not have a commitment, or
        the commitment is not quantifiable, consider leveraging the global
        carbon standards shown below:
        <ul>
          <li>50% Carbon reduction in operations by 2030</li>
          <li>75% Carbon reduction in operations by 2040</li>
          <li>100% Carbon reduction in operations by 2050</li>
        </ul>
      </>
    ),
  },
];

export const QA_data = [
  {
    title: "Are green OKRs available inside your organisation?",
    content:
      "Check if you organisation already has green OKRs, if so aim to align with these first. Ensure they can be practically applied to your product or service based upon what you have learnt so far.",
  },
  {
    title: "Can you set an objective based on the data you have collected?",
    content:
      "Connect your objective to the data you have. The objective should help the team make better decisions and doesn’t always have to have a financial benefit (although that will help make a better business case). However your objective should be attached to a carbon impact, how much carbon reduction could take place if you meet this objective. Aim to balance the benefit for people, business and planet.",
  },
  {
    title: "Can you set a key result based on data you have collected?",
    content:
      "Key results are an important factor to ensure the team are aligned on meeting the objective. Key results are a simply worded list of results, ideally with metrics. Aim to balance the results for people, business and planet.",
  },
  {
    title: "How will you determine a logical time horizon?",
    content:
      "Normally an objective lasts the duration of a year and the key results are checked per quarter. Aim to set results that you can achieve within a quarter. Longer term results should be clearly stated to set organisation expectations upfront.",
  },
  {
    title: "How will you capture results?",
    content:
      "Determine how you will capture your key results and how you will communicate your progress to achieving your objective. Segment your results into people, business and planetary benefits for holistic feedback into the organisation.",
  },
];
