import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({});
const {
  breakpoints,
  typography: { pxToRem },
} = defaultTheme;

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
  typography: {
    h1: {
      fontSize: "3.8rem",
      fontWeight: "900",
      color: "#000",
      [breakpoints.down("md")]: {
        fontSize: "38px",
      },
    },
    h2: {
      fontSize: "2.6rem",
      fontWeight: "900",
      margin: "2rem 0",
      color: "rgba(0,0,0, 1)",
    },
    h4: {
      fontWeight: 500,
    },
    h4xs: {
      fontSize: "28px",
      lineHeight: "45px",
      fontWeight: 500,
    },
    h6: {
      fontWeight: "600",
      margin: "20px 0",
      color: "rgba(34, 34, 35, 1)",
    },
    h5: {
      fontSize: "18px",
      color: "#222223",
      fontWeight: "800",
    },
    subtitle1: {
      fontSize: "1.15rem",
      color: "#222223",
    },
    subtitle2: {
      fontSize: "35px",
      color: "#222223",
      fontWeight: 400,
    },
    subtitle3: {
      fontSize: "1.15rem",
      color: "#222223",
      fontWeight: 600,
    },
    subtitle4: {
      fontSize: "22px",
      color: "#222223",
      fontWeight: 500,
    },
    subtitle5: {
      fontSize: "16px",
      color: "#383838",
      fontWeight: 700,
    },
    body1: {
      color: "#222223",
      fontSize: "18px",
    },
    body2: {
      color: "#000000",
      fontSize: "18px",
      lineHeight: "29px",
    },
    textAlign: "left",
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          textTransform: "none",
          borderRadius: "100px",
          [defaultTheme.breakpoints.down("sm")]: {
            width: "100%",
          },
          boxShadow: '0px 0px 0px rgba(0,0,0,0)'
        },
        sizeLarge: {
          padding: "0px 60px",
          lineHeight: "3.4rem",
          fontSize: "1.1rem",
          whiteSpace: "nowrap",
        },
        contained: {
          border: "1px solid rgba(0,0,0,0)",
        },
        containedPrimary: {
          root: {
            color: "#FFFFFF",
            background: "#101010",
            boxShadow: '0px 0px 0px rgba(0,0,0,0)',
          },
          "&:hover": {
            border: "1px solid rgba(0,0,0,0)",
            background: "#252F3C",
            color: "#FFFFFF",
            boxShadow: '0px 0px 0px rgba(0,0,0,0)'
          },
          "&:active": {
            border: "1px solid rgba(0,0,0,0)",
            background: "#3D4651",
            color: "#FFFFFF",
            boxShadow: '0px 0px 0px rgba(0,0,0,0)'
          },
          "&:focus": {
            border: "1px solid #00CC33",
            background: "#101010",
            boxShadow: '0px 0px 0px rgba(0,0,0,0)'
          },
          "&:disabled": {
            border: "1px solid rgba(0,0,0,0)",
            background: "#AEB3B8",
            color: "#FFFFFF",
          },
        },
        outlinedPrimary: {
          root: {
            color: "#000000",
            background: "#101010",
          },
          "&:hover": {
            background: "#DBDFE3",
            border: "1px solid #000000",
            color: "#000000",
          },
          "&:active": {
            background: "#C5C9CE",
            border: "1px solid #000000",
            color: "#000000",
          },
          "&:focus": {
            border: "1px solid #00CC33",
            background: "#101010",
            color: "#FFFFFF",
          },
          "&:disabled": {
            background: "#AEB3B8",
            border: "1px solid #000000",
            color: "#000000",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: "white",
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              border: "1px solid #DEDEDE",
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: "10px",
          marginBottom: "20px",
          boxShadow: "1px 1px 14px 0px rgba(0,0,0,0.3)",
          borderRadius: "10px !important",
          "&::before": {
            display: "none",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "#000000",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0px 10px",
          alignSelf: "flex-start",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(2, 132, 163, .5)",
          color: "red",
          "& .MuiLinearProgress-bar2Indeterminate, & .MuiLinearProgress-bar1Indeterminate":
            {
              backgroundColor: "rgba(2, 132, 163, 1)",
            },
        },
      },
    },
  },
});

export default theme;
