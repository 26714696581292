import React, { useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import MainLayout from "../../components/MainLayout";
import Section from "../../components/Section";

import FigmaIcon from "../../assets/icons/Figma";
import FigmaXsIcon from "../../assets/icons/FigmaXs";
import MiroIcon from "../../assets/icons/Miro.png";
import MiroXsIcon from "../../assets/icons/MiroXs.png";
import MuralIcon from "../../assets/icons/Mural.png";
import MuralXsIcon from "../../assets/icons/MuralXs.png";
import SketchIcon from "../../assets/icons/Sketch";
import SketchXsIcon from "../../assets/icons/SketchXs.png";

import useMediaQueries from "../../services/media-queries";
import { UserContext } from "../../context/UserContext";

import "./styles.scss";

function SelectFormat() {
  const [active, handleChangeFormat] = React.useState(null);
  const { user, updateUserData } = useContext(UserContext);
  const [isMobileView] = useMediaQueries();
  const navigate = useNavigate();

  function getActiveClass(format) {
    return format === active ? "format-btn active" : "format-btn";
  }

  useEffect(() => {
    updateUserData({ ...user, transaction: { format: active } });
  }, [active]);

  return (
    <MainLayout>
      <Section variant="dark" style={{ minHeight: "70vh" }}>
        <Grid container>
          <Grid item align="center" xs={12} sx={{ paddingTop: "100px" }}>
            <Typography variant="h1">Download the Carbon Kit</Typography>
            <Typography variant="h6">Select a format</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              flexDirection: { xs: "column", md: "row" },
              paddingLeft: "16px",
            }}
          >
            <Button
              className={getActiveClass("figma")}
              onClick={() => handleChangeFormat("figma")}
            >
              {isMobileView ? <FigmaXsIcon /> : <FigmaIcon />}
              <Typography variant="subtitle1">Figma</Typography>
            </Button>
            <Button
              className={getActiveClass("sketch")}
              onClick={() => handleChangeFormat("sketch")}
            >
              {isMobileView ? <img src={SketchXsIcon} /> : <SketchIcon />}
              <Typography variant="subtitle1">Sketch</Typography>
            </Button>
            <Button
              className={getActiveClass("mural")}
              onClick={() => handleChangeFormat("mural")}
            >
              <img src={isMobileView ? MuralXsIcon : MuralIcon} />
              <Typography variant="subtitle1">Mural</Typography>
            </Button>
          </Grid>
          <Grid item align="center" xs={12} sx={{ margin: "80px 0 0" }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                maxWidth: "620px",
                width: "100%",
                marginBottom: "100px",
              }}
              onClick={() => {
                navigate("/complete");
              }}
            >
              Download the&nbsp;<strong>Carbon Kit</strong>
            </Button>
          </Grid>
        </Grid>
      </Section>
    </MainLayout>
  );
}

export default SelectFormat;
