import * as React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";

import FooterNav from "../../components/FooterNav";
import WithTabsLayout from "../../components/WithTabsLayout";
import Section from "../../components/Section";
import SlideUp from "../../components/SlideUp";

import IdentifyImg1 from "../../assets/Measure/identify-1.png";
import IdentifyImg2 from "../../assets/Measure/identify-2.png";
import IdentifyImg3 from "../../assets/Measure/identify-3.png";
import IdentifyImg4 from "../../assets/Measure/identify-4.png";
import useMediaQueries from "../../services/media-queries";

import { QA_behavioural, QA_data } from "./data";
import QA from "../../components/QA";

import "./styles.scss";
import { Link } from "react-router-dom";

function App() {
  const [isMobileView, isTabletOrLess] = useMediaQueries();

  return (
    <WithTabsLayout
      bgColor="#DB7631"
      headerContent={{
        subtitle: "Track",
        title: "Mapping carbon impact to problem statements",
        content:
          "The carbon method allows designers to quantify emissions, identify eco-opportunities and modify the environmental impact of their product/service.",
      }}
    >
      <Section className="identify-section">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <Typography
              variant={isMobileView ? "h4xs" : "h4"}
              sx={{ maxWidth: { lg: "380px" } }}
            >
              Exploring opportunities to improve
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          <Grid item xs={12} lg={5}>
            <Typography
              variant="body2"
              className="identify-paragraph"
              sx={{ width: { lg: "480px" } }}
            >
              <p style={{ marginTop: "0px" }}>
                Based on the evidence captured in the Understand and Evaluate
                steps it’s important to identify the core problems and issues
                which the majority of users are facing to determine the best
                areas to focus on improvements.
              </p>
              <p>
                These improvements can come from the user’s experience with the
                service, technological improvements, risk mitigation and also
                the reduction of material usage or carbon producing activities.
                By calculating the amount of carbon in each phase of the service
                blueprint you can realise the lasting impact your service is
                having on the environment.
              </p>
            </Typography>
          </Grid>
          {!isMobileView && <Grid item xs={1} />}
          <Grid item xs={12} lg={6} className="identify-img">
            <SlideUp>
              <img src={IdentifyImg1} />
            </SlideUp>
          </Grid>
        </Grid>
      </Section>
      <Section variant="dark" className="identify-section">
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {!isTabletOrLess && (
            <Grid item xs={12} lg={5}>
              <SlideUp>
                <img src={IdentifyImg2} className="identify-img-md" />
              </SlideUp>
            </Grid>
          )}
          {!isMobileView && <Grid item xs={1} />}
          <Grid item xs={12} lg={6}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Problem statement template
            </Typography>
            <Typography
              variant="body2"
              className="identify-paragraph"
              sx={{ maxWidth: { lg: "525px" } }}
            >
              Using the problem statement template to capture the entirety of
              the problem from multiple dimensions. Base your inputs on
              validated user research. Focus on the user group that is impacted
              by the problem and describe what they are having trouble with.
              Then using the carbon cards, quantify the impact of the problem to
              the planet too. Once you have captured the whole problem, then
              describe how you could solve it and include your carbon ambition
              (e.g reduce carbon impact by 50%). Then describe how you know
              you’ve resolved the problem and by which measurement to track your
              success.
            </Typography>
            {isTabletOrLess && (
              <Grid item xs={12} className="identify-img">
                <SlideUp>
                  <img src={IdentifyImg2} className="img-margin" />
                </SlideUp>
              </Grid>
            )}
            <Link to="/download">
              <ButtonBase className="download-button">
                Download template
              </ButtonBase>
            </Link>
          </Grid>
        </Grid>
      </Section>
      <Section className="identify-section">
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} lg={5}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Defining problems
            </Typography>
            <Typography variant="body2" className="identify-paragraph">
              Capture your problems succinctly using the problem statement
              template to clearly articulate the problems in their entirety.
              Create statements which have balanced view of users, environment
              and business issues so when it comes to prioritisation you have
              all the information you need to make decisions.
            </Typography>
          </Grid>
          {!isMobileView && <Grid item xs={1} />}
          <Grid item xs={12} lg={6} className="identify-img">
            <SlideUp>
              <img src={IdentifyImg3} />
            </SlideUp>
          </Grid>
        </Grid>
      </Section>
      <Section variant="dark" className="identify-section">
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {!isMobileView && (
            <>
              <Grid item xs={12} lg={5} sx={{ textAlign: { sm: "center" } }}>
                <SlideUp>
                  <img src={IdentifyImg4} />
                </SlideUp>
              </Grid>
              <Grid item xs={1} />
            </>
          )}
          <Grid item xs={12} lg={5}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              {isMobileView ? "OKR Template" : "Map problems to objectives"}
            </Typography>
            <Typography
              variant="body2"
              className="identify-paragraph"
              sx={{ maxWidth: { lg: "480px" } }}
            >
              Map problem statements to objectives by taking the problem
              statements from the As-is service blueprint and mapping them to
              the organisational OKRs. Usually project level OKRs have no
              eco-OKRs or if so, at an acute scale. If so, check the global
              organisational strategic objectives which may address eco-impact.
              If there are no eco-OKRs consider working at a strategic level
              with key decision makers to address the eco-responsibilities of
              business.
            </Typography>
            {isMobileView && (
              <Grid item xs={12} className="identify-img">
                <SlideUp>
                  <img src={IdentifyImg4} className="img-margin" />
                </SlideUp>
              </Grid>
            )}
            <Link to="/download">
              <ButtonBase className="download-button">
                Download OKR template
              </ButtonBase>
            </Link>
          </Grid>
        </Grid>
      </Section>
      <Section variant="dark">
        <QA behavioural={QA_behavioural} data={QA_data} />
      </Section>
    </WithTabsLayout>
  );
}

export default App;
