export const QA_behavioural = [
  {
    title:
      "What materials or activities do we have inside your product or service?",
    content:
      "Aim to create a list of all the activities that are currently taking place to operate your service and mark those which produce emissions to complete e.g. travelling to offices, cargo transported via air.",
  },
  {
    title: "Which countries does your product or service operate?",
    content:
      "Determine the global footprint of your product or service. Aim to learn how your service operates in each location and what materials and activities are required to operate the service.",
  },
  {
    title: "How dependant are your users on these materials or activities?",
    content:
      "Talk to users about their relationship with the materials and activities to understand their dependence and need for them. Humans are habitual by nature, removing activates or ceremonies can hinder their ability to do their job effectively.",
  },
  {
    title: "Learn about your organisation’s behaviour toward net-zero?",
    content:
      "Talk to service managers to understand how important net-zero products and services are to them and the organisation. Determine the amount of education required to bring importance to carbon emissions measurement.",
  },
  {
    title: "Learn about your user’s behaviour toward net-zero?",
    content:
      "Talk to users about their views on net-zero products and services. Understand their expectations from the product and services they are consuming, and what they are willing to change to bring their carbon emissions lower. ",
  },
];

export const QA_data = [
  {
    title: "Does your organisation have a quantifiable carbon commitment?",
    content: (
      <>
        Many organisations are making the commitment to their customers and
        shareholders to become net-zero by 2050. Check if your company has made
        a commitment to change and by when. If they do not have a commitment, or
        the commitment is not quantifiable, consider leveraging the global
        carbon standards shown below:
        <ul>
          <li>50% Carbon reduction in operations by 2030</li>
          <li>75% Carbon reduction in operations by 2040</li>
          <li>100% Carbon reduction in operations by 2050</li>
        </ul>
      </>
    ),
  },
  {
    title: "How are you sourcing your energy?",
    content:
      "Learn about how energy is sourced for each country your product is consumed and your service operates. As each country will have a different mix of energy. Some country’s energy supplies are inherently more energy efficient whereas other are not.",
  },
  {
    title: "Are there any green energy sources within the organisation?",
    content:
      "Does the organisation have a means to obtain green energy (renewable energy), are you able to leverage this as part of your design.",
  },
  {
    title: "What is your digital footprint?",
    content:
      "Use analytics to determine your digital footprint, this will help form a realistic picture of the carbon impact you already have. This can be determined by looking at the emissions produced by operating a server and running of local computers.",
  },
];