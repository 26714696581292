export const QA_behavioural = [
  {
    title: "What could be better?",
    content:
      "Think freely about ‘how might we’ make improvements to the product and service. How can we find a balanced solution for people, business and planet.",
  },
  {
    title:
      "What behavioural changes do we need to create and how will we support it?",
    content:
      "Determine what behavioural changes are required which have the greatest potential for reducing emissions. Focus on the changes that are needed and make them clear to communicate with the wider team.",
  },
  {
    title: "What is the wider community and context?",
    content:
      "How will you bring new behaviours and working-practices into the day-to-day life of users and service managers.",
  },
  {
    title:
      "Test your solutions, can you gain confidence you’re doing the right thing?",
    content:
      "Build prototypes, check if users understand how to use your product and service first time. Ask questions about ecological behaviours and see how users respond. Iterate your prototype until you have enough confidence you are creating the right solution.",
  },
  {
    title: "Can you create a sustainable way to operate your service?",
    content:
      "Take all your learnings and determine how to support the operation of your service in a sustainable way. Aim to reduce the amount of materials required in the back-stage of your service and aim to be digital first. Also consider activities which can be mitigated to reduce inefficiency.",
  },
];

export const QA_data = [
  {
    title: "What data do you need to capture?",
    content:
      "Always consider what data your solution can give you. How can this new data help you with the creation of the product and service. How can it help the organisation to become more sustainable in the future?",
  },
  {
    title: "How do these solutions map to your OKRs?",
    content:
      "Do these solutions address an objective? Moreover does it provide a key result which ensures you are creating the right solution. Create a quantifiable hypothesis on the type of results you expect to obtain from the solution.",
  },
  {
    title: "How do these solutions map to global emission standards?",
    content: (
      <>
        Do your solutions map to the global emission standards:
        <br />
        <br />
        <ul>
          <li>50% Carbon reduction in operations by 2030</li>
          <li>75% Carbon reduction in operations by 2040</li>
          <li>100% Carbon reduction in operations by 2050</li>
        </ul>
      </>
    ),
  },
];
