import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.077 11.643c0 5.662-4.577 10.25-10.218 10.25S1.64 17.305 1.64 11.643c0-5.663 4.577-10.25 10.218-10.25s10.218 4.587 10.218 10.25Z"
      fill={isHover ? color : "#fff"}
      stroke="#04A12B"
      strokeWidth={1.5}
    />
    <path
      d="M14.445 12.099a1.496 1.496 0 0 0-1.722-.28l-2.167-2.152v-1.25L7.993 6.482l-1.29 1.272 1.936 2.563h1.25l2.152 2.149a1.496 1.496 0 0 0 .28 1.721l2.359 2.361a.754.754 0 0 0 1.062 0l1.064-1.063a.753.753 0 0 0 0-1.063l-2.361-2.323Z"
      fill={isHover ? "#fff" : color}
    />
    <path
      opacity={0.4}
      d="m11.297 12.67-1.193-1.191-3.002 3.002a1.36 1.36 0 1 0 1.924 1.923l2.4-2.4a2.14 2.14 0 0 1-.129-1.334Zm-3.304 3.327a.484.484 0 1 1 0-.968.484.484 0 0 1 0 .968Zm8.945-7.312a.242.242 0 0 0-.406-.112l-1.5 1.502-1.37-.228-.23-1.371 1.502-1.501a.242.242 0 0 0-.114-.407 2.915 2.915 0 0 0-2.756.766 2.9 2.9 0 0 0-.688 2.97l-.083.082 1.43 1.43a1.497 1.497 0 0 1 1.722.281l.152.15a2.86 2.86 0 0 0 1.577-.804c.722-.722.994-1.769.764-2.758Z"
      fill={isHover ? "#fff" : color}
    />
  </svg>
);

export default SvgComponent;
