import * as React from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import MainLayout from "../components/MainLayout";
import Section from "../components/Section";

import MeasureImg1 from "../assets/Measure/understand-1.png";

function Terms() {
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MainLayout>
      <Section>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", marginBottom: "4rem" }}
        >
          <Grid item xs={12} md={5}>
            <Typography variant="h1" sx={{ margin: {md: "0 0 40px", xs: "40px 0 40px"} }}>
              Terms &<br/>conditions
            </Typography>
            <Typography variant="h6">Last updated: 3 October 2022</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body1">
              <p>
                These terms and conditions (hereinafter, the "Terms and
                Conditions") govern the relationship between Bros&Co, whose
                registered office is located and registered in the UK,
                (hereinafter, the "Company") and any person (hereinafter, the
                "User") benefiting from the service offered by the Company under
                the name "Bros&Co" via a web application (hereinafter, the
                "Solution"), as described in particular in Article 2 hereof.
              </p>
            </Typography>
            <Typography variant="h5">
              Article 1. Acceptance of the Terms and Conditions
            </Typography>
            <Typography variant="body1">
              <p>
                1.1 By ticking the box "I accept the terms and conditions", the
                User acknowledges that he/she has read all the terms below and
                expressly accepts these Terms and Conditions without
                reservation. These Terms and Conditions constitute a contract
                between the User and the Company.
              </p>
            </Typography>

            <Typography variant="body1">
              <p>
                1.2. By using the Solution, the User agrees to comply with the
                Terms and Conditions in force without any reservations. The User
                is therefore encouraged, before using the Solution, to consult
                the latest version of the Terms and Conditions available in the
                Solution by clicking on the "Terms and Conditions" link.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                1.3. The Company reserves the right to modify these Terms and
                Conditions at any time. If major changes are made, they will be
                notified to the User. The User may then terminate the Terms and
                Conditions in accordance with Article 9.3.1 hereof. The use of
                the Service after the date of modification of the General Terms
                and Conditions means that the User has accepted them.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                1.4. If the User refuses to comply with all or part of the
                obligations and conditions contained in these Terms and
                Conditions, the User is invited not to use the Solution.
              </p>
            </Typography>
            <Typography variant="h5">Article 2. Presentation</Typography>

            <Typography variant="body1">
              <p>
                2.1. The Company operates the Solution, which enables the User
                or organisation to measure and reduce their carbon footprint and
                to finance climate contribution projects.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                2.2. To use the Solution, the User must have a device enabling
                him/her to access the Internet and create a personal account
                (hereinafter, the "Account ") under the conditions specified in
                Article 3. Access to the Solution is provided under the
                conditions described in these Terms and Conditions. The costs
                associated with connecting to and using the Solution, invoiced
                by the access providers or telephone operators, shall be borne
                exclusively by the User.
              </p>
            </Typography>
            <Typography variant="h5">
              Article 3. Creation of an account
            </Typography>

            <Typography variant="body1">
              <p>
                3.1 Prior and mandatory registration for access to the Solution
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                3.1.1. The User must be a natural person over 18 years of age,
                and have accepted these Terms and Conditions without
                reservation.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                3.1.2. In order to take advantage of the Solution, the User must
                create an account on the Application. The User whose Account has
                been deleted for violation of these Terms and Conditions is
                prohibited from creating a new Account. The Company reserves the
                right to delete any Account created in violation of this
                prohibition or, more generally, of these Terms and Conditions.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>3.2. User Account identifiers</p>
            </Typography>
            <Typography variant="body1">
              <p>
                3.2.1. If the User has chosen to register by entering an
                identifier and a password, these elements enable him/her to
                access the Solution and must remain confidential.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                3.2.2. The User is solely responsible for any use that may be
                made of his/her login and password and/or the account of the
                third party social network used to register on the Solution, and
                is the sole guarantor of their confidentiality, as well as any
                use of his Account. The User undertakes to inform the Company
                immediately of any use or risk of unauthorised use of his
                Account, and of any breach of confidentiality and security of
                his means of identification, using the contact form available in
                the Solution.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                3.2.3. If the Company has legitimate reasons to believe that the
                security of the Solution is violated or that it is being misused
                due to unauthorised use of the User's means of identification,
                it may temporarily suspend the Account in order to preserve the
                integrity of the Solution and the data, and, if appropriate,
                require the modification of these means of identification.
                Insofar as the User's means of identification have been passed
                on to a third party through the User's fault or by the User's
                own doing, the User shall be solely responsible for any use of
                these means of identification and for the use of the Solution
                made as a result. The Company may not under any circumstances be
                held liable for any damage resulting from a failure by the User
                to comply with the obligations stipulated in this article.
              </p>
            </Typography>
            <Typography variant="h5">Article 4. The Service</Typography>

            <Typography variant="body1">
              <p>
                The web application allows the User to measure the carbon within
                their product or service. The proprietary methodology allows the
                User to implement new organisational ways of working and design
                education.
              </p>
            </Typography>
            <Typography variant="h5">Article 5. Contact</Typography>

            <Typography variant="body1">
              <p>
                For any question or information, Users may contact the Company:
                by e-mail at the address: contact@brosand.co.
              </p>
            </Typography>
            <Typography variant="h5">
              Article 6. Intellectual property
            </Typography>

            <Typography variant="body1">
              <p>
                The Solution, the trademarks, logos and any other distinctive
                sign of the Company, as well as any calculations, data,
                methodologies, as well as the contents of the Solution (i.e.,
                any text, video, photograph, data, calculations or any other
                information in any format and of any nature whatsoever appearing
                in the Solution) (hereinafter, the " Elements ") are the
                exclusive property of the Company and/or third parties and are
                likely to be the subject of protection by copyright, trademark
                law, patent law or any other intellectual property right. The
                present Terms and Conditions do not entail any transfer of
                ownership or intellectual property rights over any of these
                Elements to the User. In particular, all data relating to the
                calculation of the carbon footprint (coefficients,
                methodologies, etc) remain the property of the Company. It is
                therefore forbidden to copy, modify, rebrand, reproduce,
                distribute, publish, integrate on any medium whatsoever, adapt,
                transfer or assign, license, sub-license, give as security,
                transmit in any other way the elements of the Solution or to
                carry out reverse engineering or use any other method to attempt
                to access the source codes and/or protocols of the Solution,
                without the express authorisation of the Company or the holder
                of the rights.
              </p>
            </Typography>

            <Typography variant="h5">
              Article 7. Data processing and liberties
            </Typography>

            <Typography variant="body1">
              <p>
                The use of the Solution offered by the Company entails the
                processing of personal data relating to the User by the Company.
                The main characteristics of this processing, as well as the
                rights that Users enjoy in relation to this processing, are
                detailed in the Privacy Policy.
              </p>
            </Typography>
            <Typography variant="h5">Article 8. Liability</Typography>

            <Typography variant="body1">
              <p>
                8.1. Given the technical computer and telecommunications
                constraints inherent in the use of the Solution, the Company may
                not be held liable in the event of damage caused by a
                malfunction in one or more of the stages of access to the
                Solution, due in particular but not exclusively to the
                disadvantages inherent in the use of the Internet network, such
                as a break in service, external intrusion, the presence of
                computer viruses or a malfunction of any kind. If the Company
                has legitimate reasons to believe that the security of the
                Solution is compromised or that misuse of the Solution is due to
                unauthorised use of the User's identification data, the Company
                may temporarily deactivate the User's Account in order to
                preserve the integrity of the Solution and the data in the
                User's Account. The User undertakes to cooperate with the
                Company and in particular to modify his identification data
                directly in the Solution by modifying his login, email address
                and/or password.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                8.2. The User expressly guarantees that he is entitled to use
                the Solution. He undertakes to use the Solution in accordance
                with its purpose and not to carry out any illicit, unfair,
                dishonest or fraudulent action of any kind whatsoever.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                8.3. The Company cannot be held liable in the event that the
                non-performance or poor performance of the Company's obligations
                is attributable either to the behaviour of the User, or to the
                unforeseeable and insurmountable act of a third party to the
                contract, or to a case of force majeure. In addition, the
                Company shall not be held liable for any indirect damage,
                operating loss, loss of profit, damage or expenses arising from
                the present.
              </p>
            </Typography>
            <Typography variant="h5">
              Article 9. Duration of the contract - Termination
            </Typography>

            <Typography variant="body1">
              <p>
                9.1. Duration of the Contract These Terms and Conditions are
                entered into for an indefinite period from the date of their
                first acceptance by the User under the conditions described in
                Article 1.9.2. Termination of relations by the Company.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                9.2.1. The Company may immediately suspend access to the
                Solution in the event of non-compliance with these Terms and
                Conditions by the User. In this case, the Company will notify
                the User of this suspension by e-mail and will give the User
                formal notice to cease this violation. At the end of a period of
                eight (8) days from the date of this notification, which has
                remained without effect, the Company may terminate the
                contractual relationship with the User as of right, without any
                formality and subject to any damages to which it may be entitled
                as compensation for the prejudice resulting for it from this
                situation. This termination may be effected without notice in
                the event of a serious breach by the User of its obligations
                under these General Terms and Conditions, as referred to in
                particular in Article 8.3.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                9.2.2. In general, the Company may deactivate the Account of any
                User and terminate its contract with the User by electronic
                notification, subject to giving one (1) month notice.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>9.3. Termination of the relationship by the User</p>
            </Typography>
            <Typography variant="body1">
              <p>
                9.3.1. The User may terminate these General Terms and Conditions
                at any time by following the procedure provided for this purpose
                in the Solution by emailing contact@brosand.co.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                9.3.2. In the event of the User's death, and upon production of
                the relevant supporting documents, the Account will be
                deactivated. Its content may only be transferred to the
                beneficiaries of the User's rights by court order or if, during
                the User's lifetime, the User has submitted instructions to the
                Company to this effect, in accordance with the Privacy Policy.
              </p>
            </Typography>
            <Typography variant="h5">Article 10. Force majeure</Typography>
            <Typography variant="body1">
              <p>
                10.1. In the event of the occurrence of a case of force majeure,
                the performance of the obligations incumbent on the party to the
                present Terms and Conditions who is the victim of this event
                will be suspended for the duration of the event, as of the date
                of receipt of the notification by registered letter with
                acknowledgement of receipt. Force majeure is understood to mean
                the cases defined by UK law and the case law of the UK courts.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                10.2. The party invoking the case of force majeure must, as soon
                as possible, inform the other party by e-mail, giving full
                details of the case of force majeure invoked and evaluating its
                duration.
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                10.3. If a case of force majeure, having been notified in
                accordance with Article 10.2 above, continues for a period of
                more than thirty (30) days, each of the parties may then, as of
                right, terminate these Terms and Conditions by sending the other
                party, at any time, written notification to this effect
              </p>
            </Typography>
            <Typography variant="body1">
              <p>
                10.4. In such circumstances, these Terms and Conditions shall be
                terminated by operation of law between the parties concerned,
                without notice or compensation on either side.
              </p>
            </Typography>
            <Typography variant="h5">Article 11. Disputes</Typography>

            <Typography variant="body1">
              <p>
                The Terms and Conditions are subject to UK law, without
                prejudice to the applicable conflict of law rules.
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Section>
    </MainLayout>
  );
}

export default Terms;
