import * as React from "react"

const SvgComponent = ({color= '#04A12B', isHover, ...props}) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.749 11.178c0 5.663-4.577 10.25-10.218 10.25-5.642 0-10.219-4.587-10.219-10.25S5.89.928 11.531.928c5.641 0 10.218 4.587 10.218 10.25Z"
      fill={isHover ? color : '#fff'}
      stroke={isHover ? color : color}
      strokeWidth={1.5}
    />
    <path
      d="M6.047 6.219c0-.217.175-.393.392-.393h1.41c.216 0 .392.176.392.393v.187a.393.393 0 0 1-.392.392h-1.41a.393.393 0 0 1-.392-.392v-.187Zm0 1.707c0-.219.175-.393.392-.393h1.41c.216 0 .392.174.392.393V9.34a.393.393 0 0 1-.392.393h-1.41a.393.393 0 0 1-.392-.393V7.926Zm0 5.865c0-.216.175-.393.392-.393h1.41c.216 0 .392.177.392.393v1.415a.393.393 0 0 1-.392.393h-1.41a.393.393 0 0 1-.392-.393V13.79ZM8.973 6.22c0-.217.173-.393.391-.393h1.41c.216 0 .392.176.392.393v.187a.393.393 0 0 1-.392.392h-1.41a.392.392 0 0 1-.391-.392v-.187Zm0 1.707a.39.39 0 0 1 .391-.393h1.41c.216 0 .392.174.392.393V9.34a.393.393 0 0 1-.392.393h-1.41a.392.392 0 0 1-.391-.393V7.926Zm0 5.865c0-.216.173-.393.391-.393h1.41c.216 0 .392.177.392.393v1.415a.393.393 0 0 1-.392.393h-1.41a.392.392 0 0 1-.391-.393V13.79Zm4.725-7.965c.218 0 .391.176.391.393v.187a.392.392 0 0 1-.391.392h-1.41a.393.393 0 0 1-.392-.392v-.187c0-.217.176-.393.391-.393h1.41Zm0 1.707a.39.39 0 0 1 .391.393V9.34a.392.392 0 0 1-.391.393h-1.41a.393.393 0 0 1-.392-.393V7.926c0-.219.176-.393.391-.393h1.41Zm0 5.865c.218 0 .391.177.391.393v1.415a.392.392 0 0 1-.391.393h-1.41a.393.393 0 0 1-.392-.393V13.79c0-.216.176-.393.391-.393h1.41ZM14.82 6.22c0-.217.177-.393.392-.393h1.41c.216 0 .392.176.392.393v.187a.393.393 0 0 1-.392.392h-1.41a.393.393 0 0 1-.392-.392v-.187Zm0 1.707c0-.219.177-.393.392-.393h1.41c.216 0 .392.174.392.393V9.34a.393.393 0 0 1-.392.393h-1.41a.393.393 0 0 1-.392-.393V7.926Zm0 5.865c0-.216.177-.393.392-.393h1.41c.216 0 .392.177.392.393v1.415a.393.393 0 0 1-.392.393h-1.41a.393.393 0 0 1-.392-.393V13.79Z"
      fill={isHover ? '#fff' : color}
    />
    <rect
      x={6.047}
      y={10.467}
      width={10.968}
      height={2.185}
      rx={0.5}
      fill={isHover ? '#fff' : color}
    />
  </svg>
)

export default SvgComponent
