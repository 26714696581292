import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import useMediaQueries from "../../services/media-queries";

import "./styles.scss";

function LinkTab(props) {
  const navigate = useNavigate();

  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        navigate(`/${props.href}`);
      }}
      {...props}
    />
  );
}

function Header({ headerContent, tabs, bgColor }) {
  const [value, setValue] = React.useState(0);
  const { pathname } = useLocation();
  const [isHover, setHover] = useState(null);
  const [isMobileView] = useMediaQueries();

  const handleChange = (event, newValue) => {
    setValue(pathname);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: "0px 0px 10px" }}>
      <div style={{ background: bgColor }}>
        <Grid container xs={12} md={8} className="header-container">
          <div className="center-container">
            <Grid Item xs={12} md={8}>
              <Typography variant="h6" className="header-subtitle">
                {headerContent.subtitle}
              </Typography>
            </Grid>
            <Grid Item xs={12} sm={10} lg={8}>
              <Typography variant="h1" className="headerContent-title">
                {headerContent.title}
              </Typography>
            </Grid>
            {!isMobileView && (
              <Grid Item sm={10} lg={6}>
                <Typography variant="subtitle1" sx={{ color: "#FFFFFF" }}>
                  {headerContent.content}
                </Typography>
              </Grid>
            )}
          </div>
        </Grid>
      </div>
      <Box sx={{ padding: { md: "0 100px", xs: "0 30px" } }}>
        <div className="center-container">
          <Grid container spacing={2}>
            <Grid item xs={12} className="tabsContainer">
              <Tabs
                value={pathname.replace("/", "")}
                onChange={handleChange}
                aria-label="icon position tabs example"
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
              >
                {tabs.map((tab) => (
                  <LinkTab
                    icon={
                      pathname.replace("/", "") == tab.link ? (
                        <tab.icon
                          color="#04A12B"
                          isHover={tab.link === isHover || tab.link === pathname.replace("/", "")}
                        />
                      ) : (
                        <tab.icon
                          color={tab.link === isHover ? "#04A12B" : "black"}
                          isHover={tab.link === isHover}
                        />
                      )
                    }
                    onMouseEnter={() => setHover(tab.link)}
                    onMouseLeave={() => setHover("")}
                    iconPosition="start"
                    label={tab.text}
                    href={tab.link}
                    value={tab.link}
                    style={{ margin: "0 auto" }}
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Box>
  );
}

export default Header;
