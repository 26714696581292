import * as React from "react"

const SvgComponent = (props) => (
  <svg
    viewBox="0 0 66 47"
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M54.652 11.727c1.714 0 3.337.782 4.428 2.139l5.908 7.503c.684.74 1.01 1.68 1.01 2.64v9.423c0 .919-.367 1.754-.959 2.359-1.57-3.37-4.938-5.698-8.836-5.698-4.265 0-7.898 2.786-9.234 6.679H36.05c-1.337-3.893-4.97-6.679-9.234-6.679-3.408 0-6.408 1.774-8.163 4.477-1.755-2.703-4.755-4.477-8.163-4.477-3.898 0-7.264 2.327-8.841 5.698a3.372 3.372 0 0 1-.955-2.358v-1.67c0-1.847 1.462-3.34 3.265-3.34h40.815V14.231c0-1.388 1.092-2.504 2.449-2.504h7.428Zm.633 5.311a.823.823 0 0 0-.633-.302h-4.98v6.678h10.623l-5.01-6.376Z"
      fill="#383838"
    />
    <path
      opacity={0.4}
      d="M36.612.04c2.704 0 4.898 2.242 4.898 5.008v15.027c0 2.766-2.194 5.01-4.898 5.01H5.593c-2.705 0-4.898-2.244-4.898-5.01V5.048C.695 2.282 2.888.04 5.593.04h31.02ZM8.858 4.212c-.902 0-1.632.747-1.632 1.67V19.24c0 .919.73 1.67 1.632 1.67.902 0 1.633-.751 1.633-1.67V5.883c0-.923-.73-1.67-1.633-1.67ZM23.552 19.24c0 .919.734 1.67 1.632 1.67.898 0 1.633-.751 1.633-1.67V5.883c0-.923-.735-1.67-1.633-1.67s-1.633.747-1.633 1.67V19.24Zm-6.53-15.027c-.899 0-1.633.747-1.633 1.67V19.24c0 .919.734 1.67 1.632 1.67.898 0 1.633-.751 1.633-1.67V5.883c0-.923-.735-1.67-1.633-1.67ZM31.713 19.24c0 .919.735 1.67 1.633 1.67s1.633-.751 1.633-1.67V5.883c0-.923-.735-1.67-1.633-1.67s-1.633.747-1.633 1.67V19.24ZM3.96 40.112c0-3.684 2.924-6.68 6.53-6.68 3.603 0 6.531 2.996 6.531 6.68 0 3.683-2.928 6.678-6.53 6.678-3.607 0-6.53-2.995-6.53-6.678Zm16.326 0c0-3.684 2.929-6.68 6.53-6.68 3.603 0 6.53 2.996 6.53 6.68 0 3.683-2.927 6.678-6.53 6.678-3.601 0-6.53-2.995-6.53-6.678Zm29.387 0c0-3.684 2.928-6.68 6.53-6.68 3.602 0 6.53 2.996 6.53 6.68 0 3.683-2.928 6.678-6.53 6.678-3.602 0-6.53-2.995-6.53-6.678Z"
      fill="#383838"
    />
  </svg>
)

export default SvgComponent
