import { format } from "d3-format";
import { specialRoundTo } from "../utils/math";

export function getCarbonValue(cardData, selectedCountryData) {
  return !isNaN(parseFloat(cardData.Carbonperunit))
    ? specialRoundTo(
        parseFloat(cardData.Carbonperunit) * parseFloat(cardData.Unitnumber),
        2
      )
    : specialRoundTo(
        parseFloat(selectedCountryData.Carbonfootprint) *
          parseFloat(selectedCountryData.Energyfive) *
          parseFloat(cardData.NoncountryspecificconsumptionKWH) *
          parseFloat(cardData.Unitnumber),
        2
      );
}


export function carbonFormat(carbonValue, significant = 2) {
  let formattedValue = carbonValue
  const carbonFormatter = format(`.${significant}s`);
  formattedValue = isNaN(carbonValue) ? 0 : carbonValue;
  formattedValue = carbonValue > 1000 || carbonValue < -1000 ? carbonFormatter(carbonValue) : carbonValue

  return formattedValue === 0 ? `0` : formattedValue
}
