import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.48913 0.105469C4.59723 0.105469 0.617188 4.08551 0.617188 8.97741C0.617188 13.8693 4.59723 17.8494 9.48913 17.8494C14.381 17.8494 18.3611 13.8693 18.3611 8.97741C18.3611 4.08551 14.381 0.105469 9.48913 0.105469ZM9.48913 16.9709C5.08148 16.9709 1.4956 13.3849 1.4956 8.97741C1.4956 4.56991 5.08133 0.983883 9.48913 0.983883C13.8969 0.983883 17.4827 4.56991 17.4827 8.97741C17.4827 13.3849 13.8966 16.9709 9.48913 16.9709V16.9709ZM12.0039 7.10047C12.0039 8.59446 11.2991 9.06541 10.7328 9.44403C10.1552 9.83021 9.65664 10.1636 9.65664 11.6871C9.65664 11.9297 9.46006 12.1263 9.21743 12.1263C8.97482 12.1263 8.77823 11.9297 8.77823 11.6871C8.77823 9.69421 9.59145 9.15066 10.2447 8.71381C10.7751 8.3594 11.1255 8.12496 11.1255 7.10047C11.1255 6.19819 10.3916 5.4641 9.48913 5.4641C8.5867 5.4641 7.85276 6.19804 7.85276 7.10047C7.85276 7.34308 7.65617 7.53968 7.41355 7.53968C7.17094 7.53968 6.97434 7.34309 6.97434 7.10047C6.97434 5.71386 8.10248 4.58572 9.48909 4.58572C10.8757 4.58572 12.0038 5.71386 12.0038 7.10047H12.0039ZM9.64961 12.8746V12.9294C9.64961 13.172 9.45303 13.3686 9.2104 13.3686C8.96779 13.3686 8.7712 13.172 8.7712 12.9294V12.8746C8.7712 12.632 8.96778 12.4354 9.2104 12.4354C9.45301 12.4354 9.64961 12.6321 9.64961 12.8746Z"
      fill="#0284A3"
    />
  </svg>
);

export default SvgComponent;
