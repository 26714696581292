import React, { useState } from "react";

import emailValidator from "email-validator";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import Checked from "../../icons/Checked";
import UnChecked from "../../icons/UnChecked";
import MainLayout from "../../components/MainLayout";
import Section from "../../components/Section";
import SocialButtons from "../../components/SocialButtons";

import { addUser } from "../../api/user";

import "./styles.scss";
import { Link } from "react-router-dom";

function Connect({ variant = "initial" }) {
  const [form, setForm] = useState({ agreement: false, newsletter: false });
  const [status, setStatus] = useState("initial");
  const [sent, setSent] = useState(false);

  function handleSignup() {
    addUser(form, (data, error) => {
      if (300 > data.status && data.status > 199) {
        setSent(true);
        setStatus("success");
        window.scrollTo(0, 0);
      } else {
        setSent(true);
        setStatus("error");
      }
    });
  }

  const canContinue =
    !!form?.email &&
    emailValidator.validate(form.email) &&
    !!form?.fullName &&
    !!form?.organization &&
    !!form?.job &&
    !!form?.message &&
    !!form?.agreement;

  return (
    <MainLayout>
      {!sent && (
        <Section>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", marginBottom: "3rem" }}
          >
            <Grid item xs={12} md={5}>
              <Typography
                variant="h1"
                sx={{ margin: { xs: "40px 0 0", md: "80px 0 40px" } }}
              >
                Can’t wait to
                <br />
                hear from you
              </Typography>
              <Typography variant="body1">
                <p>
                  We can’t wait to meet you, and show you how the Carbon Method
                  helps businesses save the planet.
                </p>
                <p>
                  Alternatively you can email:{" "}
                  <a href="mailto:contact@brosand.co">contact@brosand.co</a>
                </p>
              </Typography>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Typography variant="h6" sx={{ marginTop: "100px" }}>
                  Join our social channels
                </Typography>
                <SocialButtons />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: { xs: "none", md: "block" } }}
            ></Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  padding: { xs: "30px", md: "40px" },
                  boxShadow: "0px 0px 15px 3px rgba(36, 41, 45, 0.33)",
                  background: "#F8F8F8",
                }}
              >
                <TextField
                  onChange={(e) =>
                    setForm({ ...form, fullName: e.target.value })
                  }
                  id="outlined-required"
                  label="Full name"
                  sx={{ width: "100%", marginTop: { xs: "0px", md: "0px" } }}
                />
                <TextField
                  error={!!form?.email && !emailValidator.validate(form.email)}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  id="outlined-required"
                  label="Email"
                  sx={{ width: "100%", marginTop: "30px" }}
                />
                <TextField
                  onChange={(e) =>
                    setForm({ ...form, organization: e.target.value })
                  }
                  id="outlined-required"
                  label="Company or organization"
                  sx={{ width: "100%", marginTop: "30px" }}
                />
                <TextField
                  onChange={(e) => setForm({ ...form, job: e.target.value })}
                  id="outlined-required"
                  label="Job title"
                  sx={{ width: "100%", marginTop: "30px" }}
                />
                <TextField
                  onChange={(e) =>
                    setForm({ ...form, message: e.target.value })
                  }
                  id="outlined-required"
                  label="Message"
                  multiline
                  rows={4}
                  sx={{ width: "100%", margin: "30px 0 30px" }}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<UnChecked />}
                        checkedIcon={<Checked />}
                        className="payment-checkbox"
                      />
                    }
                    onChange={(e) =>
                      setForm({ ...form, newsletter: !form.newsletter })
                    }
                    label="Recieve updates about carbon data, certifications and articles."
                    sx={{ margin: 0 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<UnChecked />}
                        checkedIcon={<Checked />}
                        className="payment-checkbox"
                        onChange={(e) =>
                          setForm({ ...form, agreement: !form.agreement })
                        }
                      />
                    }
                    label={
                      <span>
                        I have read and accept the{" "}
                        <Link to="/privacy" target="_blank">
                          Usage Policy
                        </Link>{" "}
                        &amp;{" "}
                        <Link to="/terms" target="_blank">
                          Terms and Conditions
                        </Link>
                      </span>
                    }
                    sx={{ margin: "11px 0px 0px" }}
                  />
                </FormGroup>
                <Button
                  disabled={!canContinue}
                  onClick={() => handleSignup()}
                  variant="contained"
                  size="large"
                  style={{
                    padding: "2px 35px",
                    width: "100%",
                    margin: "30px 0 0",
                  }}
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Section>
      )}
      {sent && status === "error" && (
        <Section className="thankyou-wrapper">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", marginBottom: "3rem" }}
          >
            <Grid item xs={12} md={12} align="center">
              <Typography
                variant="h1"
                sx={{ marginTop: { xs: "40px", md: "0px" } }}
              >
                Error
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: "200px", padding: "0 80px" }}
              >
                <p>Something went wrong. Please go back and try again.</p>
              </Typography>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Typography
                  variant="body1"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <p style={{ paddingTop: "20px" }}>
                    Or email us at:{" "}
                    <a href="mailto:contact@brosand.co">contact@brosand.co</a>
                  </p>
                </Typography>
                <SocialButtons />
              </Box>
            </Grid>
          </Grid>
        </Section>
      )}
      {sent && status === "success" && (
        <Section className="thankyou-wrapper">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", marginBottom: "3rem" }}
          >
            <Grid item xs={12} md={12} align="center">
              <Typography
                variant="h1"
                sx={{ marginTop: { xs: "40px", md: "0px" } }}
              >
                Thank you
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  maxWidth: "500px",
                  margin: { xs: "200px 20px 40px", md: "200px auto 40px" },
                  fontSize: "24px",
                }}
              >
                <p>
                  We have received your message and will aim to get back to you
                  within 48 hours.
                </p>
              </Typography>
              <Box
                sx={{
                  display: { md: "block" },
                  margin: { xs: "250px 0 100px", md: "0px" },
                }}
              >
                <Typography variant="h6" sx={{ display: { md: "block" } }}>
                  <p style={{ paddingTop: "20px" }}>Join our social channels</p>
                </Typography>
                <div
                  style={{
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center'
                  }}
                >
                  <SocialButtons />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Section>
      )}
    </MainLayout>
  );
}

export default Connect;
