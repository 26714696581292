import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./styles.scss";

function Pagination({ filteredCardList, pageSize }) {
  let { library, page = 0 } = useParams();

  const numberOfPages = Math.ceil(filteredCardList.length / pageSize);
  const isNextPageBtnDisabled = parseInt(page) + 1 === numberOfPages;
  const isPrevPageBtnDisabled = parseInt(page) === 0;

  return (
    <Box
      align="right"
      sx={{ margin: "0px 0 40px" }}
      className="pagination-container"
    >
      <Link
        to={`/cardexplorer/${library}/${
          isPrevPageBtnDisabled ? parseInt(page) : parseInt(page) - 1
        }`}
      >
        <Button
          variant="outlined"
          className="disabled-arrow-btn"
          disabled={isPrevPageBtnDisabled}
        >
          <ChevronLeftIcon />
        </Button>
      </Link>
      {new Array(Math.ceil(filteredCardList.length / pageSize))
        .fill(1)
        .map((pg, i) => (
          <Link to={`/cardexplorer/${library}/${i}`}>
            <Button variant="outlined" disabled={i == page}>
              {i + 1}
            </Button>
          </Link>
        ))}
      <Link
        to={`/cardexplorer/${library}/${
          isNextPageBtnDisabled ? parseInt(page) : parseInt(page) + 1
        }`}
      >
        <Button
          variant="outlined"
          className="disabled-arrow-btn"
          disabled={isNextPageBtnDisabled}
        >
          <ChevronRightIcon />
        </Button>
      </Link>
    </Box>
  );
}

export default Pagination;
