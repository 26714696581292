import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import LinkedInIcon from "../../assets/icons/LinkedIn.png";
import TwitterIcon from "../../assets/icons/Twitter.png";
import MainLayout from "../../components/MainLayout";
import Section from "../../components/Section";
import SocialButtons from "../../components/SocialButtons";

import { getDownloadAsset } from "../../api/downloads";
import { UserContext } from "../../context/UserContext";

import "./styles.scss";

function DownloadComplete() {
  const { journey } = useParams();
  const { user } = useContext(UserContext);
  const [timer, setTimer] = useState(3);
  const [downloadUrl, setDownloadUrl] = useState(null);
  let timerInterval;

  useEffect(() => {
    getDownloadAsset({
      format: user.transaction.format,
    }).then((data) => {
      setDownloadUrl(data);
    });
  }, [user]);

  useEffect(() => {
    timerInterval = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    return () => clearInterval(timerInterval);
  });

  useEffect(() => {
    if (timer < 1 && timer > -2) {
      download(downloadUrl.url);
      clearInterval(timerInterval);
    }
  }, [timer]);

  function download(url) {
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    a.target = '_blank'
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <MainLayout>
      <Section variant="dark" style={{ minHeight: "100vh" }}>
        <div className="confirm-dialog">
          {downloadUrl ? (
            <>
              <p className="dialog-title">
                {timer > 0 ? (
                  <>Your download will be ready in {timer}.</>
                ) : (
                  <>
                    Your{" "}
                    <a href={downloadUrl.url} download target="_blank">
                      download
                    </a>{" "}
                    is ready 🎉
                  </>
                )}
              </p>
              <p className="dialog-subtitle">
                <a href={downloadUrl.url} download target="_blank">
                  click here
                </a>{" "}
                if your download does not start automatically in a few seconds.
              </p>
            </>
          ) : (
            <LinearProgress />
          )}
          <Box sx={{ padding: "100px 0px 40px" }}>
            <SocialButtons />
          </Box>
          <p className="dialog-subtitle">
            Tell your network you are using the Carbon Method
          </p>
        </div>
      </Section>
    </MainLayout>
  );
}

export default DownloadComplete;
