import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Paper from "@mui/material/Paper";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import { slugify } from "../../utils/slugify";
import CardCategories from "../CardCategories";

import "./styles.scss";

function Carousel3D() {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    const itemCount = element.querySelectorAll(".carouselItem").length;
    for (var i = 0; i < itemCount + 1; i++) {
      gsap.fromTo(
        element.querySelectorAll(`.carouselItem:nth-child(${i})`),
        {
          position: "relative",
          left: `${-195 * (i - 1)}px`,
          top: `${2 * (i - 1)}px`,
          rotationX: -30,
          rotationY: 40,
          rotationZ: -20,
          width: "200px",
          height: "260px",
          duration: 4,
        },
        {
          position: "relative",
          left: `${-100 * (i - 1)}px`,
          top: `${2 * (i - 1)}px`,
          rotationX: -30,
          rotationY: 40,
          rotationZ: -20,
          ease: "none",
          width: "200px",
          height: "260px",
          scrollTrigger: {
            trigger: element.querySelector(".layout"),
            start: "400px top",
            end: "+=100px",
          },
        }
      );
    }
  }, [null]);

  return (
    <div ref={ref} className="carouselLayout">
      <div className="carouselWrapper">
        {CardCategories.map((card) => (
          <div className="carouselItem">
            <Link to={`/cardexplorer/${slugify(card.title)}`}>
              <Paper sx={{ background: card.color }}>
                <ListItemButton sx={{ pl: 2 }}>
                  <ListItemIcon>{card.icon}</ListItemIcon>
                  <ListItemText primary={card.title} />
                </ListItemButton>
              </Paper>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel3D;
