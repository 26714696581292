import React, { createContext, useState, useEffect } from "react";
import { signIn, getSession, signOut, signUp } from "../api/auth";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [authData, setAuthData] = useState();
  const [session, setSession] = useState(null);
  const [signUpStatus, setSignupStatus] = useState(null);
  const updateAuthData = (data) => setAuthData(data);

  useEffect(() => {
    async function refreshSession() {
      const { data } = await getSession();
      setAuthData(data.session);
    }

    refreshSession();
  }, []);

  async function handleSignUp(signUpData) {
    const { data, error } = signUp(signUpData);
    if (!error) {
      setSignupStatus("SUCCESS");
    }
  }

  async function handleAuth(signInData) {
    const authData = await signIn(signInData);
    setAuthData(authData);
  }

  async function handleSignOut() {
    const result = signOut();
    setAuthData(null);
  }

  return (
    <AuthContext.Provider
      value={{
        authData,
        updateAuthData,
        handleAuth,
        handleSignOut,
        handleSignUp,
        session,
        signUpStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
