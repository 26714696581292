import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.749 11.94c0 5.662-4.577 10.25-10.218 10.25-5.642 0-10.219-4.588-10.219-10.25 0-5.664 4.577-10.25 10.219-10.25 5.641 0 10.218 4.586 10.218 10.25Z"
      fill={isHover ? color : "#fff"}
      stroke={color}
      strokeWidth={1.5}
    />
    <g clipPath="url(#a)">
      <path
        d="M17.007 15.697c0 .759-.615 1.375-1.37 1.375H7.41a1.374 1.374 0 0 1-1.37-1.375v-4.812c0-.759.613-1.375 1.37-1.375h8.226c.756 0 1.371.616 1.371 1.375v4.812Zm-1.542-7.906c.285 0 .514.231.514.516 0 .285-.23.515-.514.515H7.58a.515.515 0 0 1 0-1.031h7.884Zm-1.028-1.719c.285 0 .514.231.514.516 0 .285-.23.516-.514.516H8.61a.514.514 0 0 1-.514-.516c0-.285.229-.516.514-.516h5.827Z"
        fill={isHover ? "#fff" : color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(6.04 6.072)"
          d="M0 0h10.968v11H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
