import supabase from "../services/db_client";

async function getCountryEnergyData(country) {
  return await supabase
    .from("country data")
    .select()
    .filter("Country", "in", `(${country})`)
    .then((item) => {
      if (item.data && item.data.length) {
        return item.data[0]
      }
    });
}

async function getAllCountries() {
  return await supabase
    .from("country data")
    .select("Country, Energyfive, Countrycode, Carbonfootprint, Unit")
    .then((item) => {
      if (item.data && item.data.length) {
        return item.data
      }
    });
}

export { getCountryEnergyData, getAllCountries };
