import * as React from "react";

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.78 11.88c0 5.664-4.577 10.25-10.218 10.25S1.344 17.545 1.344 11.88c0-5.662 4.577-10.25 10.218-10.25S21.78 6.219 21.78 11.88Z"
      fill={isHover ? color : "#fff"}
      stroke="#04A12B"
      strokeWidth={1.5}
    />
    <path
      d="M11.92 9.694h4.678v5.832a1.45 1.45 0 0 1-1.44 1.458H7.964a1.45 1.45 0 0 1-1.44-1.458V9.694h4.678V6.777h.72v2.917Z"
      fill={isHover ? "#fff" : color}
    />
    <path
      opacity={0.4}
      d="M11.2 6.777v2.917H6.524l1.141-2.312c.183-.37.557-.605.966-.605h2.57Zm3.29 0c.41 0 .783.234.968.605l1.14 2.312H11.92V6.777h2.57Z"
      fill={isHover ? "#fff" : color}
    />
  </svg>
);

export default SvgComponent;
