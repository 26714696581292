import React from "react";
import find from "lodash/find";
import links from "../../const/links";

function SocialLinks({ children, platform }) {
  const {link} = find(links, { 'platform': platform });

  return (
    <a href={link} target="_blank">
      {children}
    </a>
  );
}

export default SocialLinks;
