import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { format } from "d3-format";

import { roundTo, specialRoundTo } from "../../utils/math";

export default function DataTable({ filteredCardList, selectedCountryData }) {
  const navigate = useNavigate();
  const unitFormatter = format(",.2r");
  function handleRowClick(params) {
    navigate(`/carddetails/${params.row.ID}`);
  }

  const columns = [
    {
      field: "Intro",
      headerName: "Name",
      flex: 3,
      renderCell: (params) => {
        return (
          <a href="#">
            {params.value} {params.row.Inventory}
          </a>
        );
      },
    },
    {
      field: "Unit",
      headerName: "Unit",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {unitFormatter(params.row.Unitnumber)} {params.value}
          </div>
        );
      },
    },
    {
      field: "NoncountryspecificconsumptionKWH",
      headerName: "Power consumed",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {roundTo(selectedCountryData.Energyfive * params.value, 2)}{" "}
            <Typography variant="caption">KW/h</Typography>
          </div>
        );
      },
    },
    {
      field: "Carbonperunit",
      headerName: "Carbon footprint",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.Carbonperunit !== "N/A"
              ? specialRoundTo(
                  parseFloat(params.row.Carbonperunit) *
                    parseFloat(params.row.Unitnumber),
                  4
                )
              : specialRoundTo(
                  parseFloat(selectedCountryData.Carbonfootprint) *
                    parseFloat(selectedCountryData.Energyfive) *
                    parseFloat(params.row.NoncountryspecificconsumptionKWH) *
                    parseFloat(params.row.Unitnumber),
                  4
                )}
            <Typography variant="caption">Kg</Typography>
          </div>
        );
      },
    },
  ];

  return (
    filteredCardList && (
      <div style={{ height: 749, width: "100%" }}>
        <DataGrid
          onRowClick={handleRowClick}
          getRowId={() => uuidv4()}
          rows={filteredCardList}
          columns={columns}
          pageSize={12}
          rowsPerPageOptions={[5]}
          isRowSelectable={false}
          disableColumnMenu
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
        />
      </div>
    )
  );
}
