export const QA_behavioural = [
  {
    title: "How will you capture the behavioural impact you’ve made?",
    content:
      "When assessing the as-is service against the to-be service, aim to capture the behavioural change you are making to the user of the product and also the operation of the service. Work with change managers to support the implementation of the new operation and determine educational gaps upfront.",
  },
  {
    title: "How will you calculate the carbon impact you have made?",
    content:
      "Compare the carbon impact of your current service vs your to-be service. Ideally the new service will have less carbon impact however in the situation where you have to work with carbon inside your service, work with the business to take the responsibility for their emissions. Use the ‘Reducers library’ to find ways to reduce impact through off-setting and consider leveraging BAU budget to maintain off-setting initiatives.",
  },
  {
    title: "Share calculations within the organisation.",
    content:
      "Share your carbon calculations within the organisation and encourage others to take the responsibility to reduce emissions from their products and services.",
  },
];

export const QA_data = [
  {
    title: "Calculate the carbon difference and assess your alignment.",
    content: (
      <>
        Take the carbon calculation from the as-is service and minus the to-be
        carbon value to determine the remaining carbon inside the service.
        Assess your alignment to the original OKRs and global emission
        standards:
        <br />
        <br />
        <ul>
          <li>
            If you have 50% carbon reduction in operations, you will have
            reached the global 2030 milestone.
          </li>
          <li>
            If you have 75% carbon reduction in operations, you will have
            reached the global 2040 milestone.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Determine you biggest area of impact",
    content:
      "Create a view of your biggest areas of carbon impact. What changed to make this impact, can these learnings be shared inside the organisation and help other initiatives?",
  },
];
