import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Button, List, ListItem } from "@mui/material";

import { useWindowSize } from "../../hooks/WindowResize";
import Checkmark from "./Checkmark";
import Fan from "./Fan";

import useMediaQueries from "../../services/media-queries";
import { AuthContext } from "../../context/AuthContext";

import "./styles.scss";
import { useEffect } from "react";

function Paywall() {
  const { authData } = useContext(AuthContext);
  const [isMobileView] = useMediaQueries();
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 900) {
      window.scrollTo(0, 0);
    }
  }, [size.width]);

  return !authData?.user ? (
    <>
      <Helmet>
        <body className="paywall-body"></body>
      </Helmet>
      <div className="overlay-wrapper">
        <div className="overlay-blur">
          <Grid
            container
            sx={{
              background: "white",
              padding: "45px",
              boxShadow: "0px 5px 15px rgba(36, 41, 45, 0.2)",
              maxWidth: "1300px",
              margin: "60px auto 0",
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column-reverse", md: "row" },
                }}
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                      fontWeight: "500",
                    }}
                  >
                    Book a demo to get full access
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: "50px",
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    What you get
                  </Typography>
                  <List className="offer-list">
                    <ListItem>
                      <div>
                        <Checkmark />
                      </div>
                      <Typography
                        variant="subtitle1"
                        sx={{ lineHeight: "130%", marginBottom: "10px" }}
                      >
                        Access to the Carbon Method process and education
                        material on how to implement it.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <div>
                        <Checkmark />
                      </div>
                      <Typography
                        variant="subtitle1"
                        sx={{ lineHeight: "130%", marginBottom: "10px" }}
                      >
                        Access to seven libraries of standardised carbon data.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <div>
                        <Checkmark />
                      </div>
                      <Typography
                        variant="subtitle1"
                        sx={{ lineHeight: "130%", marginBottom: "10px" }}
                      >
                        Ability to request bespoke carbon calculations.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <div>
                        <Checkmark />
                      </div>
                      <Typography
                        variant="subtitle1"
                        sx={{ lineHeight: "130%", marginBottom: "10px" }}
                      >
                        Access to our future roadmap
                      </Typography>
                    </ListItem>
                  </List>
                  {isMobileView && (
                    <Link to="/connect">
                      <Button
                        size="large"
                        variant="contained"
                        sx={{ marginTop: "30px" }}
                      >
                        Book a free demo
                      </Button>
                    </Link>
                  )}
                </Grid>
                <Grid item xs={12} md={6} align="center">
                  <Box sx={{marginTop: '60px'}}>
                  <Fan />
                  </Box>
                  {!isMobileView && (
                    <Link to="/connect">
                      <Button
                        size="large"
                        variant="contained"
                        sx={{ margin: "40px auto 0" }}
                      >
                        Book a free demo
                      </Button>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  ) : null;
}

export default Paywall;
