import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={8}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.701 14.328 1.778 7.763 6.7 1.2"
      stroke="#222223"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
)

export default SvgComponent
