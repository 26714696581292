import * as React from "react"

const SvgComponent = ({ color = "#04A12B", isHover, ...props }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.061 11.94c0 5.662-4.576 10.25-10.218 10.25-5.641 0-10.218-4.588-10.218-10.25 0-5.664 4.577-10.25 10.218-10.25 5.642 0 10.218 4.586 10.218 10.25Z"
      fill={isHover ? color : "#fff"}
      stroke={color}
      strokeWidth={1.5}
    />
    <g clipPath="url(#a)">
      <path
        d="M11.052 9.608c0 .65-.526 1.179-1.175 1.179h-2.35a1.177 1.177 0 0 1-1.175-1.179V7.251c0-.651.526-1.179 1.175-1.179h2.35c.649 0 1.175.528 1.175 1.179v2.357Zm0 6.286c0 .65-.526 1.178-1.175 1.178h-2.35a1.177 1.177 0 0 1-1.175-1.178v-2.357c0-.651.526-1.179 1.175-1.179h2.35c.649 0 1.175.528 1.175 1.179v2.357ZM12.62 7.25c0-.651.527-1.179 1.175-1.179h2.35c.65 0 1.176.528 1.176 1.179v2.357c0 .65-.527 1.179-1.175 1.179h-2.35a1.177 1.177 0 0 1-1.176-1.179V7.251Zm4.7 8.643c0 .65-.526 1.178-1.174 1.178h-2.35a1.177 1.177 0 0 1-1.176-1.178v-2.357c0-.651.527-1.179 1.175-1.179h2.35c.65 0 1.176.528 1.176 1.179v2.357Z"
        fill={isHover ? "#fff" : color}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill={isHover ? "#fff" : color}
          transform="translate(6.352 6.072)"
          d="M0 0h10.968v11H0z"
        />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
