import * as React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Section from "../../components/Section";
import WithTabsLayout from "../../components/WithTabsLayout";
import SlideUp from "../../components/SlideUp";

import PrioritiseImg1 from "../../assets/Measure/prioritise-1.png";
import PrioritiseImg2 from "../../assets/Measure/prioritise-2.png";
import useMediaQueries from "../../services/media-queries";

import { QA_behavioural, QA_data } from "./data";
import QA from "../../components/QA";

import "./styles.scss";

function App() {
  const [isMobileView] = useMediaQueries();

  return (
    <WithTabsLayout
      bgColor="#DB7631"
      headerContent={{
        subtitle: "Track",
        title: "Reframing organisational priorities",
        content:
          "The carbon method allows designers to quantify emissions, identify eco-opportunities and modify the environmental impact of their product/service.",
      }}
    >
      <Section className="prioritise-section">
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", marginBottom: "4rem" }}
        >
          <Grid item xs={12} md={5}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Prioritise impact areas
            </Typography>
            <Typography
              variant="body2"
              className="prioritise-paragraph"
              sx={{ maxWidth: { md: "550px" } }}
            >
              <p style={{ marginTop: "0px" }}>
                In the existing design thinking framework we measure the
                user value against the achievability of delivering features.
                However in the eco-model we are aiming to make decisions on user
                benefit and carbon impact. The prioritisation matrix shows how
                we can map OKRs with our carbon measurements which are
                contextualised to the most meaningful issues to fix.
              </p>
              <p style={{ marginBottom: "0px" }}>
                This is a collaborative workshop based approach to prioritising
                issues which the user faces and the ecology surrounding its
                products and services.
              </p>
            </Typography>
          </Grid>
          {!isMobileView && <Grid item xs={1} />}
          <Grid item xs={12} md={6}>
            <SlideUp>
              <img src={PrioritiseImg1} className="prioritise-img" />
            </SlideUp>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!isMobileView && (
            <>
              <Grid item md={5}>
                <SlideUp>
                  <img src={PrioritiseImg2} className="prioritise-img" />
                </SlideUp>
              </Grid>
              <Grid item xs={1} />
            </>
          )}
          <Grid item xs={12} md={5}>
            <Typography variant={isMobileView ? "h4xs" : "h4"}>
              Prepare to focus on meaningful work which balances value to the
              user and planet.
            </Typography>
            <Typography variant="body2" className="prioritise-paragraph">
              Prepare to focus on meaningful work which balances value to the
              user and planet. Ensure stakeholders understand their work has a
              far reaching impact which will usually outlast their careers and
              the decisions the team make have the potential to improve the way
              user is done along with removing carbon and energy burden from the
              planet.
            </Typography>
          </Grid>
          {isMobileView && (
            <Grid item xs={12}>
              <SlideUp>
                <img src={PrioritiseImg2} className="prioritise-img" />
              </SlideUp>
            </Grid>
          )}
        </Grid>
      </Section>
      <Section variant="dark" className="section-padding">
        <QA behavioural={QA_behavioural} data={QA_data} />
      </Section>
    </WithTabsLayout>
  );
}

export default App;
