import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_2867_52318"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="54"
      height="54"
      style={{
        maskType: "alpha",
      }}
    >
      <rect
        x="0.675781"
        y="0.3125"
        width="53.1119"
        height="53.1119"
        rx="7.81057"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_2867_52318)">
      <g filter="url(#filter0_d_2867_52318)">
        <path
          d="M46.0454 0.3125H8.41804C4.14211 0.3125 0.675781 3.77882 0.675781 8.05475V45.6821C0.675781 49.958 4.14211 53.4244 8.41804 53.4244H46.0454C50.3213 53.4244 53.7876 49.958 53.7876 45.6821V8.05475C53.7876 3.77882 50.3213 0.3125 46.0454 0.3125Z"
          fill="#222223"
        />
        <path
          d="M20.6099 46.7275C21.4792 46.7275 22.34 46.5563 23.1431 46.2236C23.9462 45.891 24.676 45.4034 25.2906 44.7887C25.9053 44.174 26.3929 43.4443 26.7256 42.6411C27.0583 41.838 27.2295 40.9772 27.2295 40.1079V33.4883H20.6099C19.7406 33.4883 18.8798 33.6595 18.0766 33.9922C17.2735 34.3248 16.5438 34.8124 15.9291 35.4271C15.3144 36.0418 14.8268 36.7716 14.4941 37.5747C14.1615 38.3778 13.9902 39.2386 13.9902 40.1079C13.9902 40.9772 14.1615 41.838 14.4941 42.6411C14.8268 43.4443 15.3144 44.174 15.9291 44.7887C16.5438 45.4034 17.2735 45.891 18.0766 46.2236C18.8798 46.5563 19.7406 46.7275 20.6099 46.7275V46.7275Z"
          fill="#17A874"
        />
        <path
          d="M13.9902 26.8696C13.9902 25.114 14.6877 23.4303 15.9291 22.1888C17.1705 20.9474 18.8542 20.25 20.6099 20.25H27.2295V33.4893H20.6099C18.8542 33.4893 17.1705 32.7918 15.9291 31.5504C14.6877 30.309 13.9902 28.6253 13.9902 26.8696V26.8696Z"
          fill="#2443A1"
        />
        <path
          d="M13.9902 13.6294C13.9902 11.8738 14.6877 10.19 15.9291 8.94861C17.1705 7.70719 18.8542 7.00977 20.6099 7.00977H27.2295V20.249H20.6099C18.8542 20.249 17.1705 19.5516 15.9291 18.3102C14.6877 17.0688 13.9902 15.385 13.9902 13.6294V13.6294Z"
          fill="#F24E1E"
        />
        <path
          d="M27.2324 7.04688H33.852C35.6077 7.04688 37.2914 7.7443 38.5328 8.98572C39.7743 10.2271 40.4717 11.9109 40.4717 13.6665C40.4717 15.4221 39.7743 17.1059 38.5328 18.3473C37.2914 19.5887 35.6077 20.2861 33.852 20.2861H27.2324V7.04688Z"
          fill="#F24E1E"
        />
        <path
          d="M40.4717 26.8696C40.4717 28.1789 40.0834 29.4587 39.3561 30.5473C38.6287 31.6359 37.5949 32.4843 36.3853 32.9854C35.1757 33.4864 33.8447 33.6175 32.5606 33.3621C31.2765 33.1066 30.097 32.4762 29.1713 31.5504C28.2455 30.6246 27.615 29.4451 27.3596 28.1611C27.1042 26.877 27.2353 25.546 27.7363 24.3364C28.2373 23.1268 29.0858 22.093 30.1744 21.3656C31.263 20.6382 32.5428 20.25 33.8521 20.25C35.6077 20.25 37.2914 20.9474 38.5328 22.1888C39.7743 23.4303 40.4717 25.114 40.4717 26.8696Z"
          fill="#0C9BEC"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_2867_52318"
        x="-14.9454"
        y="-13.7465"
        width="84.3536"
        height="84.3536"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.56211" />
        <feGaussianBlur stdDeviation="7.81057" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2867_52318"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2867_52318"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgComponent;
