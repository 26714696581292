import * as React from "react";
import { Box } from "@mui/system";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import MainLayout from "../../components/MainLayout";
import { readUserByEmail } from "../../api/user";
import { passHash } from "../../const/auth";

import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

function SignUp() {
  const { authData, handleSignUp, handleSignOut, session, signUpStatus } =
    React.useContext(AuthContext);
  const [userData, setUserData] = React.useState({});


  return (
    <MainLayout>
      <div className="center-container">
        <Grid container sx={{ margin: "300px 0 300px" }}>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            <Typography variant="h1">Sign Up</Typography>
            {signUpStatus !== "SUCCESS" ? (
              <>
                <Box>
                  <TextField
                    label="Email"
                    type="email"
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                  />
                </Box>
                <Box sx={{ margin: "20px 0", width: "100%" }}>
                  <TextField
                    label="Password"
                    sx={{ width: "100%" }}
                    onChange={(e) =>
                      setUserData({ ...userData, password: e.target.value })
                    }
                  />
                </Box>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: "100%" }}
                  onClick={() => {
                    handleSignUp(userData);
                  }}
                >
                  Sign Up
                </Button>
              </>
            ) : (
              <Box>
                <Typography variant="h6">
                  Success! Check and verify your e-mail address and head to our{" "}
                  <Link to="/signin">Sign in</Link> page.
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>
      </div>
    </MainLayout>
  );
}

export default SignUp;
