import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import DigitalIcon from "../../icons/Digital";
import EllipseWhiteIcon from "../../assets/icons/Ellipse-white";
import EllipseBlueIcon from "../../assets/icons/Ellipse-blue";

import "./styles.scss";

function CardBox(props) {
  const {
    titleActivity,
    title,
    value,
    hours,
    energyConsumed,
    onChange,
    onHeaderClick,
    onValueClick,
    onTitleClick,
    onHoursClick,
    onFooterClick,
  } = props;

  return (
    <Box sx={{ padding: "0px", position: "relative", height: "340px" }}>
      <Box className="digital-box">
        <div className="digital-box-header">
          <DigitalIcon style={{ fill: "white", width: "12px" }} />
          <p>Digital</p>
          <IconButton className="xs-icon-btn pulse" onClick={onHeaderClick}>
            <EllipseWhiteIcon />
          </IconButton>
        </div>
        <div className="digital-box-content">
          <p style={{ fontSize: "15px", marginBottom: "4px" }}>
            {titleActivity}
          </p>
          <p style={{ fontSize: "24px", marginBottom: "35px" }}>
            {title}
            <IconButton className="xs-icon-btn pulse-blue" onClick={onTitleClick}>
              <EllipseBlueIcon />
            </IconButton>
          </p>
          <Box sx={{ display: "flex", alignItems: "flex-end", flex: 1 }}>
            <p
              style={{ fontSize: "62px", fontWeight: 900, lineHeight: "52px" }}
            >
              {value}
            </p>
            <p style={{ fontSize: "16px", textAlign: 'left', paddingLeft: '15px' }}>kg/<br/>Co2e</p>
            <IconButton className="xs-icon-btn pulse-blue" onClick={onValueClick}>
              <EllipseBlueIcon />
            </IconButton>
          </Box>
          <div className="input-container">
            <p style={{ fontSize: "16px" }}>per</p>
            <TextField
              className="unit-input"
              variant="outlined"
              sx={{ margin: "0px 12px" }}
              onChange={(e) => onChange(e)}
              defaultValue={40}
              value={hours}
              InputProps={{
                  inputProps: { 
                      max: 9999, min: 0 
                  }
              }}
            />
            <p style={{ fontSize: "16px" }}>hours</p>{" "}
            <IconButton className="xs-icon-btn pulse-blue" onClick={onHoursClick}>
              <EllipseBlueIcon />
            </IconButton>
          </div>
        </div>
        <Box className="digital-box-footer">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <p style={{ fontWeight: 500 }}>UK</p>
              <p style={{ fontSize: "14px" }}>Energy mix</p>
            </div>
            <IconButton className="xs-icon-btn pulse-blue" onClick={onFooterClick}>
              <EllipseBlueIcon />
            </IconButton>
          </div>
          <div>
            <p style={{textAlign: 'right'}}>
              <span style={{ fontWeight: 500, fontSize: "16px" }}>
                {energyConsumed}
              </span>{" "}
              <span style={{ fontWeight: 500, fontSize: "10px" }}>kW/h</span>
            </p>
            <p style={{ fontSize: "14px" }}>Energy consumed</p>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default CardBox;
