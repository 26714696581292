import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import "./styles.scss";

function HeaderLarge({
  children,
  leftColumn,
  rightColumn,
  rightColumnStyles,
  leftColumnStyles = {},
  ...props
}) {
  return (
    <Box {...props} className="headerWrapper">
      <div className="center-container">
        <Grid container>
          <Grid
            item
            xs={12}
            md={5}
            sx={{ flexGrow: 1, textAlign: "left" }}
            style={leftColumnStyles}
          >
            {leftColumn}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            className="colRight"
            style={rightColumnStyles}
          >
            {rightColumn}
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default HeaderLarge;
