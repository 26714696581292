import supabase from "../services/db_client";

async function getCardByID(ID) {
  return await supabase
    .from("cards")
    .select()
    .filter("ID", "in", `(${ID})`)
    .then((item) => {
      if (item.data && item.data.length) {
        return item.data[0];
      }
    });
}

async function getCardsByLibrary(library) {
  return await supabase
    .from("cards")
    .select()
    .filter("Library", "in", `(${library})`)
    .then((item) => {
      if (item.data && item.data.length) {
        return item.data;
      }
    });
}

async function getCardsBySubcategoryAndLibrary(library, subcategory) {
  return await supabase
    .from("cards")
    .select()
    .filter("Library", "in", `(${library})`)
    .filter("Subcategory", "in", `(${subcategory})`)
    .then((item) => {
      if (item.data && item.data.length) {
        return item.data;
      }
    });
}

export { getCardByID, getCardsByLibrary, getCardsBySubcategoryAndLibrary };
