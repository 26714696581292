import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={28}
    height={45}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={3}
      width={28}
      height={42}
    >
      <rect
        x={0.758}
        y={4.145}
        width={26.607}
        height={39.837}
        rx={13.303}
        fill="red"
        stroke="#F8F8F8"
        strokeWidth={0.439}
      />
    </mask>
    <g mask="url(#a)">
      <path
        d="m23.282 22.183-4.518-2.677c0 .417-.007 1.363 0 2.526.005.92-.004 1.956 0 3.038.003.811.004 1.66.004 2.434 0 .894 0 1.726-.019 2.41-.032 1.178-.139 1.961-.2 2.42-.031.231-.123.696-.222 1.092-.097.388-.206.732-.206.732l-.07.204a4.648 4.648 0 0 1-.206.498c-.195.41-.389.7-.389.7s-.195.301-.42.549a3.905 3.905 0 0 1-.525.463s-.307.205-.537.313c-.23.11-.619.219-.619.219s-.328.083-.591.112c-.263.03-.59.039-.59.039l-2.746 3.486 2.746 3.487s1.001 0 2.038-.12c1.036-.12 1.974-.365 1.974-.365s.945-.23 1.941-.682c.996-.451 1.779-.99 1.779-.99s.747-.486 1.448-1.187c.7-.7 1.23-1.402 1.23-1.402s.52-.691.947-1.432c.427-.742.765-1.556.765-1.556s.27-.656.514-1.407c.243-.752.401-1.472.401-1.472s.138-.58.278-1.44c.14-.859.178-1.449.203-1.66.052-.432.12-2.212.12-3.667 0 0 .004-6.152-.011-7.133a19.22 19.22 0 0 0-.009-.342c-1.042.653-3.963 2.458-4.51 2.81Z"
        fill="#0C3"
      />
      <path
        d="m23.185 22.183-4.518-2.677c0 .416-.007 1.363 0 2.526.005.92-.004 1.956 0 3.038.003.811.004 1.66.004 2.434 0 .894 0 1.726-.019 2.409-.031 1.179-.139 1.962-.2 2.42-.03.232-.123.697-.222 1.093-.096.388-.206.732-.206.732l-.069.204a4.669 4.669 0 0 1-.207.497c-.195.41-.388.7-.388.7s-.196.302-.421.55a3.903 3.903 0 0 1-.525.463s-.307.205-.537.313c-.23.109-.619.219-.619.219s-.327.083-.59.112c-.264.03-.59.038-.59.038l-2.746 3.487 2.745 3.486s1.002 0 2.038-.12c1.036-.12 1.974-.365 1.974-.365s.945-.23 1.942-.681c.995-.452 1.778-.991 1.778-.991s.747-.486 1.448-1.186c.7-.7 1.231-1.402 1.231-1.402s.52-.691.947-1.433c.426-.741.764-1.556.764-1.556s.27-.655.514-1.407c.243-.752.401-1.471.401-1.471s.138-.58.278-1.44c.14-.859.178-1.45.203-1.66.052-.433.12-2.213.12-3.668 0 0 .004-6.151-.01-7.132-.002-.104-.005-.219-.01-.342-1.042.653-3.963 2.458-4.51 2.81Z"
        fill="#0C3"
      />
      <path
        d="M10.113 40.542c.847-2.15 2.84-3.107 3.016-3.185.177-.078.976-.102.976-.102l-2.746 3.486 2.746 3.486s-1.283-.125-1.908-.41-1.644-2.208-2.084-3.275Z"
        fill="#141715"
      />
      <path
        d="m4.936 16.759 4.518 2.747c0 .417.007 1.363 0 2.526-.005.92.002 1.985-.002 3.067-.003.811-.002 1.631-.002 2.405 0 .894 0 1.726.02 2.41.03 1.178.138 1.961.2 2.42a12.173 12.173 0 0 0 .496 2.028 6.239 6.239 0 0 0 .595 1.198s.196.301.422.549c.225.248.524.463.524.463s.307.205.537.313c.23.11.62.219.62.219s.327.083.59.112c.263.03.59.038.59.038l-2.746 3.487 2.746 3.486s-1.001 0-2.038-.12c-1.036-.12-1.974-.364-1.974-.364s-.945-.23-1.941-.682c-.996-.452-1.779-.991-1.779-.991s-.747-.486-1.447-1.186c-.701-.7-1.232-1.402-1.232-1.402s-.52-.691-.946-1.433c-.427-.741-.765-1.555-.765-1.555s-.27-.656-.513-1.408c-.244-.751-.402-1.471-.402-1.471s-.138-.58-.278-1.44c-.14-.859-.178-1.45-.203-1.66-.052-.433-.157-2.311-.12-3.667 0-3.291-.004-6.152.011-7.133l.005-.217c1.117-.685 2.726-1.676 4.514-2.74Z"
        fill="#04A12B"
      />
      <path
        d="M4.994 15.955c.67 0 3.965-.254 3.853.362-.214 1.178.667 3.306.667 3.306l-4.52-2.747L.48 19.615s.223-1.118.384-2.537c.161-1.418 3.458-1.123 4.129-1.123Z"
        fill="#58675C"
      />
      <path
        d="M13.893 4.229c3.48 0 6.221.953 8.145 2.357 4.531 3.308 5.24 8.908 5.24 8.908s.16.811.283 1.941c.123 1.13.146 2.054.146 2.054l-4.51 2.811-4.517-2.679s-.036-1.703-.154-2.716c-.117-1.014-.27-2.375-.975-3.65a4.376 4.376 0 0 0-1.099-1.313c-.574-.422-1.443-.748-2.35-.748-.906 0-1.613.236-2.184.6-.782.499-1.342 1.39-1.65 2.196-.216.568-.379 1.2-.49 1.817-.215 1.178-.263 3.816-.263 3.816l-4.52-2.747-4.513 2.739s.057-1.226.16-2.27c.105-1.044.282-1.853.282-1.853S2.218 4.229 13.894 4.229Z"
        fill="#0BF446"
      />
      <path
        d="M9.376 15.694a4.678 4.678 0 1 1 9.356 0v17.03a4.678 4.678 0 1 1-9.356 0v-17.03Z"
        fill="#fff"
      />
      <g filter="url(#b)">
        <path
          d="M10.04 40.617c.847-2.15 2.84-3.107 3.016-3.185.176-.077.975-.102.975-.102l-2.746 3.486 2.746 3.487s-1.282-.125-1.908-.41c-.625-.285-1.643-2.208-2.084-3.276Z"
          fill="#141715"
        />
      </g>
      <path
        d="m5.006 16.791 4.519 2.748c0 .416.007 1.363 0 2.525-.006.921.001 1.985-.003 3.067-.003.811-.001 1.632-.001 2.405 0 .894 0 1.726.018 2.41.032 1.178.14 1.962.2 2.42a12.165 12.165 0 0 0 .498 2.028c.043.127.11.297.206.498.196.41.389.7.389.7s.195.302.42.55a3.9 3.9 0 0 0 .525.462s.308.205.538.314c.23.108.618.218.618.218s.328.083.591.113c.264.03.59.038.59.038l-2.718 3.48 2.718 3.471s-1.001.022-2.037-.098c-1.037-.12-1.974-.365-1.974-.365s-.946-.23-1.942-.682c-.996-.451-1.779-.99-1.779-.99s-.746-.486-1.447-1.187c-.7-.7-1.231-1.401-1.231-1.401s-.52-.692-.947-1.433c-.427-.742-.765-1.556-.765-1.556s-.27-.655-.513-1.407a17.976 17.976 0 0 1-.402-1.472S.94 33.067.8 32.208c-.14-.86-.178-1.45-.204-1.66-.052-.433-.156-2.312-.12-3.668 0-3.29-.004-6.151.012-7.132 0-.069.002-.141.004-.218 1.117-.685 2.727-1.675 4.514-2.739Z"
        fill="#04A12B"
      />
      <g filter="url(#c)">
        <path
          d="M5.052 15.798c.67 0 3.965-.254 3.853.362-.215 1.178.667 3.306.667 3.306l-4.52-2.747-4.513 2.739s.223-1.118.384-2.537c.161-1.418 3.458-1.123 4.129-1.123Z"
          fill="#58675C"
        />
      </g>
      <path
        d="M13.95 4.072c3.48 0 6.222.954 8.145 2.358 4.531 3.308 5.241 8.908 5.241 8.908s.16.811.282 1.94a27.25 27.25 0 0 1 .146 2.055l-4.51 2.81-4.517-2.678s-.036-1.703-.153-2.716c-.117-1.014-.271-2.375-.975-3.65a4.374 4.374 0 0 0-1.1-1.313c-.574-.422-1.443-.748-2.35-.748-.906 0-1.613.236-2.184.6-.782.498-1.341 1.39-1.65 2.196-.216.568-.378 1.2-.49 1.816-.215 1.178-.489 3.772-.489 3.772l-4.349-2.595L.54 19.458s.057-1.226.161-2.27c.104-1.044.282-1.852.282-1.852S2.275 4.072 13.95 4.072Z"
        fill="#0BF446"
      />
      <g filter="url(#d)">
        <path
          d="m23.212 22.064-4.525-2.648c0 .411-.007.945 0 2.095 1.518 1.5 5.666 3.095 9.133-.328-.001-.7-.004-1.24-.01-1.569a17.942 17.942 0 0 0-.008-.339c-1.044.646-4.043 2.44-4.59 2.789Z"
          fill="#0C3"
        />
      </g>
    </g>
    <rect
      x={9.305}
      y={10.945}
      width={9.941}
      height={26.46}
      rx={4.97}
      fill="#fff"
    />
    <defs>
      <filter
        id="b"
        x={10.039}
        y={36.689}
        width={5.274}
        height={8.254}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={0.641} />
        <feGaussianBlur stdDeviation={0.32} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2052_39911"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2052_39911"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        x={-0.102}
        y={15.746}
        width={10.313}
        height={5.002}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.641} />
        <feGaussianBlur stdDeviation={0.32} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2052_39911"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2052_39911"
          result="shape"
        />
      </filter>
      <filter
        id="d"
        x={18.039}
        y={17.994}
        width={10.422}
        height={5.229}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={-0.641} />
        <feGaussianBlur stdDeviation={0.32} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2052_39911"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2052_39911"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default SvgComponent
