import * as React from "react";
import findIndex from "lodash/findIndex";
import { useNavigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import LeftChevron from "../../icons/leftChevron";
import RightChevron from "../../icons/rightChevron";
import { Typography } from "@mui/material";

import navItems from "./navItems";

import "./styles.scss";

function WithTabsAccordion() {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const locationIndex = findIndex(navItems, { link: pathname });
  let prevIndex, nextIndex;

  if (locationIndex > 0) {
    prevIndex = locationIndex - 1;
  } else {
    prevIndex = navItems.length - 1;
  }

  if (locationIndex === navItems.length - 1) {
    nextIndex = 0;
  } else {
    nextIndex = locationIndex + 1;
  }

  return (
    <Box sx={{ width: "100%", background: '#ecebe8', paddingTop: '16px' }}>
      <div className="center-container">
        <Grid container spacing={2} className="footerNav">
          <Grid
            item
            xs={6}
            className="footerNav-step"
            onClick={() => {
              navigate(navItems[prevIndex].link);
              window.scrollTo(0, 0);
            }}
          >
            <div className="chevron">
              <LeftChevron />
            </div>
            <div>
              <Typography variant="subtitle1">Past step</Typography>
              <Typography variant="h3">{navItems[prevIndex].name}</Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            className="footerNav-step"
            onClick={() => {
              navigate(navItems[nextIndex].link);
              window.scrollTo(0, 0);
            }}
          >
            <div>
              <Typography variant="subtitle1">Next step</Typography>
              <Typography variant="h3">{navItems[nextIndex].name}</Typography>
            </div>
            <div className="chevron">
              <RightChevron />
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default WithTabsAccordion;
