import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import { hasAccess } from "./api/auth";
import theme from "./const/theme";
import { CountryContextProvider } from "./context/CountryContext";
import { UserContextProvider } from "./context/UserContext";
import { CardContextProvider } from "./context/CardContext";
import { AuthContextProvider } from "./context/AuthContext";

import CookieDisclaimer from './components/CookieDisclaimer'
import Router from "./Router";

import "./App.css";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();

  if (hasAccess()) {
    setTimeout(() => navigate("/login"), 1);
    return null;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <CountryContextProvider>
          <UserContextProvider>
            <CardContextProvider>
              <ScrollToTop />
              <Router />
              <CookieDisclaimer />
            </CardContextProvider>
          </UserContextProvider>
        </CountryContextProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
