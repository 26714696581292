import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.4922" cy="15.9219" r="15" fill="#F3F5F6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3597 10.0039H14.6311V15.1897H9.44531V16.9183H14.6311V22.1041H16.3597V16.9183H21.5455V15.1897H16.3597V10.0039Z"
      fill="black"
    />
  </svg>
);

export default SvgComponent;
