import React, { useEffect, useState, useContext, useRef } from "react";
import find from "lodash/find";
import get from "lodash/get";
import { useParams, Link } from "react-router-dom";
import classnames from "classnames";

import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import CardTile from "../../components/CardTile";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import Section from "../../components/Section";
import CardCategories from "../../components/CardCategories";
import EnergyMixDialog from "../../components/EnergyMixOverlay";
import ChevronLeft from "../../icons/ChevronLeft";

import QuestionMark from "../../icons/QuestionMark";
import { slugify } from "../../utils/slugify";
import MobileImg from "./assets/mobile.png";
import { roundTo } from "../../utils/math";
import useMediaQueries from "../../services/media-queries";
import { getCardByID, getCardsByLibrary } from "../../api/card";
import { CountryContext } from "../../context/CountryContext";
import { specialRoundTo } from "../../utils/math";
import {
  checkEnergyMix,
  hasCountriesSelect,
  hasEnergyMixDescription,
  hasGlobalAverageDescription,
} from "../../const/cards";
import { carbonFormat } from "../../services/carbon";

import {
  avoidedEmissionsItems,
  equivalentEmissionsItems,
  offsetEmissionsItems,
} from "./placeholderData";

import getCoefficient from "./data/emissions";
import EmissionCard from "./EmissionCard";
import References from "./References";

import "./styles.scss";

function CardDetails() {
  gsap.registerPlugin(ScrollTrigger);

  const [isMobileView] = useMediaQueries();
  const [volume, setVolume] = useState(0);
  const [defaultVolume, setDefaultVolume] = useState(0);
  const [cardData, setCardData] = useState({});
  const [cardList, setCardList] = useState(null);
  const [cardColor, setCardColor] = useState(null);
  const [isEnergyMixDialogOpen, setEnergyMixDialogOpen] = useState(false);
  const {
    countries,
    selectedCountry,
    selectedCountryData,
    updateSelectedCountry,
  } = useContext(CountryContext);
  let { id = "D1" } = useParams();

  const countryData = find(countries, { Country: selectedCountry });

  const hasEnergyMixCountrySelect = hasCountriesSelect(cardData.Library);
  const hasEMDescription = hasEnergyMixDescription(cardData.Library);
  const hasGADescription = hasGlobalAverageDescription(cardData.Library);

  let unitInputDefaultVal = volume === defaultVolume ? { value: volume } : {};
  const handleClickInfoIcon = () => setEnergyMixDialogOpen(true);

  useEffect(() => {
    getCardByID(id).then((data) => {
      setCardData(data);
      setVolume(parseInt(data.Unitnumber));
      setDefaultVolume(parseInt(data.Unitnumber));
      setCardColor(find(CardCategories, { title: data.Library }).color);
    });
  }, [id]);

  useEffect(() => {
    getCardsByLibrary(cardData.Library).then((fetchedCards) => {
      setCardList(fetchedCards);
    });
  }, [cardData]);

  function countryCoefficient(coefficient, activityVolume) {
    const { NoncountryspecificconsumptionKWH } = cardData;

    const nonCountrySpecificKWH = !isNaN(
      parseFloat(NoncountryspecificconsumptionKWH)
    )
      ? NoncountryspecificconsumptionKWH
      : 1;

    return (
      coefficient *
      activityVolume *
      countryData.Carbonfootprint *
      countryData.Energyfive *
      nonCountrySpecificKWH
    );
  }

  let carbonValue =
    cardData.Carbonperunit !== "N/A"
      ? specialRoundTo(parseFloat(cardData.Carbonperunit) * volume, 4)
      : specialRoundTo(
          parseFloat(selectedCountryData.Carbonfootprint) *
            parseFloat(selectedCountryData.Energyfive) *
            parseFloat(cardData.NoncountryspecificconsumptionKWH) *
            volume,
          4
        );

  let SimilarCards = null;
  SimilarCards = () =>
    cardList
      ? cardList.slice(0, 4).map((item) => {
          return (
            <Grid item xs={12} md={3} sx={{ maxWidth: "320px" }}>
              <Link to={`/carddetails/${item.ID}`}>
                <CardTile
                  cardData={item}
                  selectedCountryData={countryData}
                  noMobileStyle={true}
                />
              </Link>
            </Grid>
          );
        })
      : null;

  const hasEnergyMix = checkEnergyMix(cardData);

  const ref = useRef(null);

  useEffect(() => {
    if (!isMobileView) {
      const element = ref.current;
      gsap.fromTo(
        element.querySelectorAll(".digital-box"),
        {
          position: "static",
          top: "150px",
        },
        {
          position: "fixed",
          top: "150px",
          scrollTrigger: {
            scrub: true,
            trigger: element.querySelector(".digital-box"),
            start: "-110px top",
            end: "-110px",
          },
        }
      );
    }
  }, [null]);

  const isLoading = !cardData.hasOwnProperty("Library");

  return (
    <div className="main-layout" ref={ref}>
      <Navigation />
      <Box
        sx={{
          padding: "85px 0 0px",
          background: {
            md: "rgba(15, 171, 205, .2)",
            xs: "linear-gradient(180deg, rgba(15, 171, 205, .2) 30%, #fff 30%)",
          },
          position: "relative",
          height: { xs: "auto", md: "340px" },
        }}
      >
        <Grid container sx={{ padding: { xs: "0 30px", md: "0 100px" } }}>
          <div className="center-container">
            {isLoading && <LinearProgress color="primary" />}
            <Grid
              container
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: "row",
                },
                height: {
                  xs: "auto",
                  md: "320px",
                },
              }}
            >
              <Grid item xs={12} md={6} lg={3}>
                <Link
                  to={
                    get(cardData, "Library")
                      ? `/cardexplorer/${slugify(get(cardData, "Library"))}`
                      : ""
                  }
                >
                  <Typography variant="h6" className="return-button">
                    <ChevronLeft
                      style={{
                        position: "relative",
                        top: "3px",
                        paddingRight: "8px",
                      }}
                    /> Return to card selection
                  </Typography>
                </Link>
                {hasEnergyMix && (
                  <Box className="select-box">
                    <div className="select-box-title">
                      <p>
                        {hasGADescription ? (
                          "Global average"
                        ) : hasEnergyMixCountrySelect || hasEMDescription ? (
                          "Energy mix"
                        ) : (
                          <CircularProgress
                            thickness={2}
                            className="circular-progress-indicator"
                          />
                        )}
                      </p>

                      {hasEnergyMixCountrySelect && (
                        <div
                          onClick={() => handleClickInfoIcon()}
                          style={{ cursor: "pointer" }}
                        >
                          <QuestionMark />
                        </div>
                      )}
                    </div>
                    {hasEnergyMixCountrySelect ? (
                      <>
                        <FormControl sx={{ width: "100%", marginTop: "15px" }}>
                          <Select
                            value={selectedCountry}
                            defaultValue={selectedCountry}
                            IconComponent={ExpandMoreIcon}
                            className="select-input"
                            onChange={(e) => {
                              updateSelectedCountry(e.target.value);
                            }}
                            inputProps={{
                              MenuProps: {
                                disableScrollLock: true,
                                style: { zIndex: 10000 },
                              },
                            }}
                          >
                            {!!countries &&
                              countries.map((country) => (
                                <MenuItem value={country.Country}>
                                  {country.Country}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <p className="select-box-unit">
                          <span>{roundTo(countryData.Carbonfootprint, 4)}</span>{" "}
                          kgCo2e/kWh
                        </p>
                      </>
                    ) : hasEMDescription ? (
                      <>
                        <p className="select-box-description">
                          This carbon measurement is based on the local country
                          energy mix. Each country has a different mix of gas,
                          coal, oil, nuclear, hydro-carbon, wind and solar.
                        </p>
                        <Link to="/cards" className="select-box-link">
                          See our data collection process <span>&rsaquo;</span>
                        </Link>
                      </>
                    ) : hasGADescription ? (
                      <>
                        <p className="select-box-description">
                          This carbon measurement is based on the combine global
                          energy consumption and global production processes to
                          produce a single Co2e value.
                        </p>
                        <Link to="/cards" className="select-box-link">
                          See our data collection process <span>&rsaquo;</span>
                        </Link>
                      </>
                    ) : (
                      <CircularProgress
                        thickness={2}
                        className="circular-progress-indicator"
                      />
                    )}
                  </Box>
                )}
              </Grid>
              <Grid lg={1} />
              <Grid
                container
                md={6}
                lg={7}
                xs={12}
                sx={{
                  flex: 1,
                  textAlign: "left",
                  padding: isMobileView ? "20px 0" : "40px 0 60px",
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    sx={{
                      padding: {
                        xs: "20px 0 20px",
                        md: "30px 0",
                        lg: "70px 0 30px",
                      },
                    }}
                  >
                    {get(cardData, "Intro")
                      ? `${get(cardData, "Intro")} ${get(
                          cardData,
                          "Inventory"
                        )}`
                      : get(cardData, "Inventory")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid container sx={{ padding: { xs: "0 30px", md: "0 100px" } }}>
          <div className="center-container digital-box-wrapper">
            <Box className="digital-box">
              <div
                className="digital-box-header"
                style={{ background: cardColor }}
              >
                <img src={MobileImg} />
                <p>{get(cardData, "Library")}</p>
              </div>
              <div className="digital-box-content">
                <p style={{ fontSize: "15px", marginBottom: "4px" }}>
                  {get(cardData, "Intro")}
                </p>
                <p style={{ fontSize: "24px", marginBottom: "35px" }}>
                  {get(cardData, "Inventory")}
                </p>
                {cardData.NoncountryspecificconsumptionKWH ? (
                  <Box style={{ display: "flex", alignItems: "flex-end" }}>
                    <p
                      style={{
                        fontSize: "62px",
                        fontWeight: 900,
                        lineHeight: "74px",
                      }}
                    >
                      {carbonFormat(carbonValue, 4)}
                    </p>
                    <p style={{ fontSize: "16px", padding: "0 0 12px 10px" }}>
                      kg/
                      <br />
                      Co2
                    </p>
                  </Box>
                ) : (
                  <CircularProgress
                    thickness={2}
                    className="circular-progress-indicator"
                  />
                )}
                <div className="input-container">
                  <p>per</p>
                  <TextField
                    className="volumeInput"
                    variant="outlined"
                    sx={{
                      margin: "0px 12px",
                      width: `${
                        18 * volume.toString().split("").length + 20
                      }px`,
                      minWidth: "60px",
                    }}
                    onChange={(e) => {
                      if (!isNaN(parseInt(e.target.value, 10))) {
                        setVolume(
                          Math.min(999999, parseInt(+e.target.value, 10))
                        );
                      } else if (parseInt(e.target.value) == 0) {
                        setVolume(0, 10);
                      } else {
                        setVolume(0, 10);
                      }
                    }}
                    {...unitInputDefaultVal}
                  />
                  <p style={{ fontSize: "16px" }}>{cardData.Unit}</p>
                </div>
              </div>
              <Grid container className="digital-box-footer">
                <Grid item xs={6}>
                  <p style={{ fontWeight: 500 }}>{countryData.Countrycode}</p>
                  <p style={{ fontSize: "14px" }}>Energy mix</p>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <p>
                    <span style={{ fontWeight: 500, fontSize: "16px" }}>
                      {roundTo(
                        countryData.Energyfive *
                          cardData.NoncountryspecificconsumptionKWH *
                          volume,
                        3
                      )}
                    </span>{" "}
                    <span style={{ fontWeight: 500, fontSize: "10px" }}>
                      {countryData.Unit}
                    </span>
                  </p>
                  <p style={{ fontSize: "14px" }}>Energy consumed</p>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Grid>
      </Box>
      <Section sx={{ padding: { xs: "0px", md: "30px 0px 70px" } }}>
        <EmissionCard
          items={equivalentEmissionsItems}
          sectionTitle="Equivalent emissions"
          sx={{
            background: "#F8F8F8",
            border: { sx: "0px", md: "1px solid black" },
            borderRadius: { xs: "0px", md: "10px" },
          }}
          values={[
            countryCoefficient(getCoefficient("kmdriven"), volume),
            countryCoefficient(getCoefficient("barrelsconsumed"), volume),
            countryCoefficient(getCoefficient("yearhomeelectricity"), volume),
          ]}
        />
        <EmissionCard
          items={avoidedEmissionsItems}
          sectionTitle="Equivalent reductions"
          sx={{
            border: "0px",
            background: "#DAE3A3",
            borderRadius: { xs: "0px", md: "10px" },
          }}
          values={[
            countryCoefficient(getCoefficient("lightsswitchedtoled"), volume),
            countryCoefficient(getCoefficient("tonneswasterecycled"), volume),
            countryCoefficient(getCoefficient("windturbinesyear"), volume),
          ]}
        />
        <EmissionCard
          items={offsetEmissionsItems}
          sectionTitle="Equivalent offsets"
          sx={{
            border: "0px",
            background: "#F6F3B2",
            borderRadius: { xs: "0px", md: "10px" },
          }}
          values={[
            countryCoefficient(getCoefficient("treegrowntenyears"), volume),
            countryCoefficient(getCoefficient("acresofforestoneyear"), volume),
          ]}
        />
        <References />
      </Section>
      <Section variant={isMobileView || "dark"}>
        <Typography
          variant="h1"
          sx={{
            textAlign: { xs: "left", md: "center" },
            marginTop: { xs: "30px", md: "0" },
          }}
        >
          Similar carbon cards
        </Typography>
        {!isMobileView && (
          <Grid className="similarCardsDesktop">
            <SimilarCards />
          </Grid>
        )}
      </Section>
      {isMobileView && (
        <Box
          sx={{
            padding: "30px 0",
            background: { xs: "#FFFFFF", md: "#F8F8F8" },
          }}
        >
          <Tabs
            aria-label="icon position tabs example"
            variant="scrollable"
            scrollButtons={false}
            className="similarCardsMobile"
            sx={{ padding: "0 40px" }}
          >
            <SimilarCards />
          </Tabs>
        </Box>
      )}
      <Footer />
      <EnergyMixDialog
        open={isEnergyMixDialogOpen}
        setEnergyMixDialogOpen={setEnergyMixDialogOpen}
      />
    </div>
  );
}

export default CardDetails;
