import * as React from "react";

const SvgComponent = (props) => (
  <svg
    viewBox="0 0 29 47"
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.695 42.423a4.461 4.461 0 0 0 4.462 4.462h18.961a4.462 4.462 0 0 0 4.462-4.462V4.501A4.462 4.462 0 0 0 24.118.039H5.157A4.462 4.462 0 0 0 .695 4.501v37.922Zm2.23-37.922A2.231 2.231 0 0 1 5.158 2.27h18.961a2.23 2.23 0 0 1 2.231 2.231v37.923a2.231 2.231 0 0 1-2.23 2.23H5.156a2.23 2.23 0 0 1-2.231-2.23V4.5Zm9.482 35.692a2.232 2.232 0 1 1 4.463 0 2.232 2.232 0 0 1-4.463 0Z"
    />
  </svg>
);

export default SvgComponent;
