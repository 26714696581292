import { format } from "d3-format";
import find from "lodash/find";
import React from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { getCarbonValue, carbonFormat } from "../../services/carbon";

import CardCategories from "../../components/CardCategories";
import { specialRoundTo } from "../../utils/math";

import "./styles.scss";

function CardTile({ cardData, selectedCountryData, noMobileStyle = false }) {
  const navigate = useNavigate();
  const unitFormatter = format(",.2r");

  let carbonValue = getCarbonValue(cardData, selectedCountryData);
  carbonValue = carbonFormat(carbonValue);

  const perKwhValue = specialRoundTo(
    parseFloat(cardData.Unitnumber) *
      parseFloat(selectedCountryData.Energyfive) *
      parseFloat(cardData.NoncountryspecificconsumptionKWH),
    4
  );

  const borderColor = find(CardCategories, { title: cardData.Library }).color;

  return (
    <>
      <Paper
        sx={{
          borderTop: `8px solid ${borderColor}`,
          backgroundColor: {
            xs: !noMobileStyle ? borderColor : "",
            md: "#FFFFFF",
          },
        }}
        style={{ padding: 0 }}
        className={noMobileStyle ? "cardTile" : "cardTile mobile-styling"}
        onClick={() => navigate(`/carddetails/${cardData.ID}`)}
      >
        <Grid container spacing={0} sx={{ height: "100%" }}>
          <Grid item xs={12} sx={{ padding: "14px 14px 0px" }}>
            <Typography variant="body1" className="tileActivityName">
              {cardData.Intro}
            </Typography>
          </Grid>
          <Grid item xs={12} className="tileName" sx={{ padding: "0px 14px" }}>
            <Typography variant="h4">{cardData.Inventory}</Typography>
          </Grid>
          <Grid
            item
            sx={{ margin: "0px 10px 0px 0px", paddingLeft: "14px" }}
            alignSelf="center"
          >
            <Typography variant="h3" className="tileValue">
              {carbonValue || carbonValue === 0 ? (
                <>{carbonValue}</>
              ) : (
                <CircularProgress />
              )}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "14px",
              flex: 1,
            }}
          >
            <Typography
              variant="subtitle1"
              className="tileValueParams"
              sx={{ textAlign: "right" }}
            >
              kgCo2e <br />
              per {`${unitFormatter(cardData.Unitnumber)} ${cardData.Unit}`}
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            sx={{
              borderTop: "1px solid #EEEEEE",
              paddingBottom: "14px",
              paddingTop: "10px",
            }}
          >
            <Grid
              item
              xs={4}
              className="tileValueParams"
              sx={{ paddingLeft: "14px" }}
            >
              {selectedCountryData.Countrycode} <br />
              Energy mix
            </Grid>
            <Grid
              item
              xs={8}
              className="tileValueParams"
              sx={{ paddingRight: "14px", textAlign: "right" }}
            >
              {perKwhValue} kWh per {unitFormatter(cardData.Unitnumber)}{" "}
              {cardData.Unit} <br /> Energy consumed
            </Grid>
          </Grid>
        </Grid>
        <div
          class="card-tile-overlay"
          style={{ backgroundColor: borderColor }}
        ></div>
      </Paper>
    </>
  );
}

export default CardTile;
